import { servicePath } from "constants/defaultValues";
import { log } from "helpers/Utils";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { knowlarityPostApi, request } from "services/requests.services";
import { COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL, COMPLAINT_GET_DOC_FOR_APPROVAL, COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE, COMPLAINT_EXPERT_APPROVE_DRAFT, COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE, COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL, COMPLAINT_APPROVE_ESCALATION_MAIL, ADD_LEGAL_DRAFT_COMMENT } from "./Action";

function* getWaiveOffUsers(action) {
  try {
    const data = yield request("GET", `${servicePath}/insurance/getWaiveOffUsers/${action.state.id}`);
    yield put({ type: "COMPLAINT_GET_WAIVEOFF_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_WAIVEOFF_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* feeOperation(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/feeOperation`, { ...action.state });
    yield put({ type: "COMPLAINT_FEE_OPERATION_SUCCESS", data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_FEE_OPERATION_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* addSecondPayAmt(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/addFinalAmount`, { ...action.state });
    yield put({ type: "COMPLAINT_SECOND_ADD_FINAL_AMOUNT_SUCCESS", data: data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_SECOND_ADD_FINAL_AMOUNT_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* finalPaymentFunc(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/finalFeeOperation`, { ...action.state });
    yield put({ type: "COMPLAINT_FINAL_PAYMENT_SUCCESS", data: data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_FINAL_PAYMENT_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* claimOperation(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/claimOperation`, { ...action.state });
    yield put({ type: "COMPLAINT_CLAIM_OPERATION_SUCCESS", data: data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_CLAIM_OPERATION_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* getCallLog(action) {
  try {
    const data = yield knowlarityPostApi("GET", `account/calllog?start_time=${action.state?.start_date}&end_time=${action.state.end_date}&customer_number=${action.state.customer_number}`);
    yield put({ type: "COMPLAINT_CALL_LOG_SUCCESS", data: data.data?.objects });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_CALL_LOG_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* getServetelCallLog(action) {
  try {
    const data = yield request("GET", `${servicePath}/callrecording/getCallRecording?phoneNumber=${action.state.customer_number}${action.state.start_date ? `&startDate=${action.state.start_date}&endDate=${action.state.end_date}` : ""}`);
    yield put({ type: "COMPLAINT_CALL_LOG_SUCCESS", recordingData: data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_CALL_LOG_FAILED",
      message: "Failed to get Data !"
    });
  }
}

function* addComment(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/addComm`, action.state);
    yield put({ type: "COMPLAINT_ADD_COMMENT_SUCCESS", message: data.message });
    yield put(action.refreshState);
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_ADD_COMMENT_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* updateComment(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/updateCommunication`, { ...action.state });
    yield put({ type: "COMPLAINT_ADD_COMMENT_SUCCESS", message: data.message });
    yield put({ type: "COMPLAINT_GET_COMMENTS", state: { id: action.state?.insurance_id } });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_ADD_COMMENT_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getComments(action) {
  try {
    const data = yield request("GET", `${servicePath}/insurance/${action.state.id}`);
    yield put({ type: "COMPLAINT_GET_COMMENTS_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_COMMENTS_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getUserBasedData(action) {
  log(action);
  try {
    const data = yield request("POST", `${servicePath}/insurance/getUserBasedData`, { ...action.state });
    yield put({ type: "COMPLAINANT_GET_USER_BASED_DATA_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINANT_GET_USER_BASED_DATA_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getUserBucket() {
  try {
    const data = yield request("GET", `${servicePath}/insurance/getStatusBuckets`);
    yield put({ type: "COMPLAINANT_GET_STATUS_BUCKET_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINANT_GET_STATUS_BUCKET_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* updateComplpaintStatus(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/updateManyToMany`, { ...action.state });
    yield put({ type: "COMPLAINANT_UPDATE_COMPLAINT_STATUS_SUCCESS", message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINANT_UPDATE_COMPLAINT_STATUS_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getOptionBasedData(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/optionBasedData`, { ...action.state });
    yield put({ type: "COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* complaintUpdateInfo(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/updateMultipleUserData`, { ...action.state });
    yield put({ type: "COMPLAINANT_UPDATE_INFORMATION_SUCCESS", message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINANT_UPDATE_INFORMATION_FAILED",
      message: `Failed, ${error?.message || error.response?.message}`
    });
  }
}

function* getStates() {
  try {
    const data = yield request("GET", `${servicePath}/ombudsman/state`);
    yield put({ type: "COMPLAINT_STATES_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_STATES_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getAllInsa() {
  try {
    const data = yield request("GET", `${servicePath}/insurance/getAllInsa`);
    yield put({ type: "COMPLAINT_GET_ALL_INSA_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_ALL_INSA_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getPoliciesForEsc(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/getAllForEscalation`, { ...action.state });
    let temp = data.data?.map((res) => {
      return { value: res._id, label: `${res.policyNumber} -- ${res.name}` };
    });
    yield put({ type: "COMPLAINANT_GET_POLICY_FOR_ESCALATION_SUCCESS", data: temp });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINANT_GET_POLICY_FOR_ESCALATION_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* docUploadGetData(action) {
  log(action);
  try {
    const data = yield request("GET", `${servicePath}/insurance/${action.state.id}`);
    yield request("POST", `${servicePath}/insurance/igmsDoc/${data.data[0]?.userId}/${btoa(data.data[0]?.policyNumber)}`, action.state.file);
    yield request("POST", `${servicePath}/insurance/docUpload`, {
      id: action.state.id,
      policy_number: data.data[0]?.policyNumber,
      userId: data.data[0]?.userId
    });
    yield put({ type: "COMPLAINT_DOC_UPLOAD_GET_API_SUCCESS", data: data.data, name: action.state?.name });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_DOC_UPLOAD_GET_API_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* uploadMulUserData(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/updateMultipleUserData`, { ...action.state });

    yield put({ type: "COMPLAINT_UPLOAD_MUL_USER_DATA_SUCCESS", message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_UPLOAD_MUL_USER_DATA_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getLead(action) {
  try {
    const data = yield request("GET", `${servicePath}/lead/${action.state.id}`);
    yield put({ type: "COMPLAINT_GET_LEAD_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_LEAD_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* deleteStatus(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/deleteStatus`, action.state);
    yield put({ type: "COMPLAINT_DELETE_STATUS_SUCCESS", message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_DELETE_STATUS_FAILED",
      message: "Failed to add Comment !"
    });
  }
}

function* getComplaintForDraftAsync(action) {
  try {
    const data = yield request("GET", `${servicePath}/draftMailAuto/getComplaint/${action.state?.id}`);
    yield put({ type: "COMPLAINT_GET_DOC_FOR_APPROVAL_SUCCESS", data: data?.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_DOC_FOR_APPROVAL_FAILED",
      message: "Failed to get complaint data !"
    });
  }
}

function* getDocForDraftApproval(action) {
  try {
    const data = yield request("GET", `${servicePath}/draftMailAuto/getDraft/${action.state?.id}`);
    yield put({ type: "COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_FAILED",
      message: "Failed to get complaint document !"
    });
  }
}

function* saveDraftExpertApproveAsync(action) {
  try {
    yield request("POST", `${servicePath}/draftMailAuto/verifyOtp`, action.state);
    yield put({ type: "COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_SUCCESS", message: "Draft mail updated successfully !" });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_FAILED",
      message: "Failed to save draft !"
    });
  }
}

function* approveDraftMailByExpertAsync(action) {
  try {
    yield request("POST", `${servicePath}/draftMailAuto/expertApproval`, action.state);
    yield put({ type: "COMPLAINT_EXPERT_APPROVE_DRAFT_SUCCESS", message: "Draft mail Approved successfully !" });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_EXPERT_APPROVE_DRAFT_FAILED",
      message: "Failed to approve draft mail !"
    });
  }
}

function* getCompForEscApproveAsyn(action) {
  try {
    const data = yield request("POST", `${servicePath}/draftMailAuto/getEscalationMail`, action.state);
    yield put({ type: "COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_FAILED",
      message: "Failed to approve draft mail !"
    });
  }
}

function* updateEscAppMailAsync(action) {
  try {
    const data = yield request("POST", `${servicePath}/draftMailAuto/updateAddEscalationMail`, action.state);
    yield put({ type: "COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_SUCCESS", message: data.data?.msg });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_FAILED",
      message: "Failed to update draft mail !"
    });
  }
}

function* approveEscalationMailAsync(action) {
  try {
    const data = yield request("POST", `${servicePath}/draftMailAuto/escalationMailApprove`, action.state);
    yield put({ type: "COMPLAINT_APPROVE_ESCALATION_MAIL_SUCCESS", message: data.data?.msg });
  } catch (error) {
    log(error);
    yield put({
      type: "COMPLAINT_APPROVE_ESCALATION_MAIL_FAILED",
      message: "Failed to update draft mail !"
    });
  }
}

function* addLegalDraftCommentAsync(action) {
  try {
    yield request("POST", `${servicePath}/legalQuery/comment`, action.state);
    yield put({ type: "ADD_LEGAL_DRAFT_COMMENT_SUCCESS", message: "Comment added successfully !" });
  } catch (error) {
    log(error);
    yield put({
      type: "ADD_LEGAL_DRAFT_COMMENT_FAILED",
      message: "Failed to add comment !"
    });
  }
}

function* getComplaintForDraft() {
  yield takeLatest(COMPLAINT_GET_DOC_FOR_APPROVAL, getComplaintForDraftAsync);
}

function* getDocForDraftApprove() {
  yield takeLatest(COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL, getDocForDraftApproval);
}

function* saveDraftExpertApprove() {
  yield takeLatest(COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE, saveDraftExpertApproveAsync);
}

function* approveDraftMailByExpert() {
  yield takeLatest(COMPLAINT_EXPERT_APPROVE_DRAFT, approveDraftMailByExpertAsync);
}

function* getCompForEscApprove() {
  yield takeLatest(COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE, getCompForEscApproveAsyn);
}

function* updateEscAppMail() {
  yield takeLatest(COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL, updateEscAppMailAsync);
}

function* approveEscalationMail() {
  yield takeLatest(COMPLAINT_APPROVE_ESCALATION_MAIL, approveEscalationMailAsync);
}

function* addLegalDraftComment() {
  yield takeLatest(ADD_LEGAL_DRAFT_COMMENT, addLegalDraftCommentAsync);
}

export default function* complaint() {
  yield takeEvery("COMPLAINT_GET_WAIVEOFF", getWaiveOffUsers);
  yield takeEvery("COMPLAINT_FEE_OPERATION", feeOperation);
  yield takeEvery("COMPLAINT_SECOND_ADD_FINAL_AMOUNT", addSecondPayAmt);
  yield takeEvery("COMPLAINT_FINAL_PAYMENT", finalPaymentFunc);
  yield takeEvery("COMPLAINT_CLAIM_OPERATION", claimOperation);
  yield takeEvery("COMPLAINT_CALL_LOG", getCallLog);
  yield takeEvery("COMPLAINT_SERVETEL_CALL_LOG", getServetelCallLog);
  yield takeEvery("COMPLAINT_ADD_COMMENT", addComment);
  yield takeEvery("COMPLAINT_UPDATE_COMMENT", updateComment);
  yield takeEvery("COMPLAINT_GET_COMMENTS", getComments);
  yield takeEvery("COMPLAINANT_GET_USER_BASED_DATA", getUserBasedData);
  yield takeEvery("COMPLAINANT_GET_STATUS_BUCKET", getUserBucket);
  yield takeEvery("COMPLAINANT_UPDATE_COMPLAINT_STATUS", updateComplpaintStatus);
  yield takeEvery("COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT", getOptionBasedData);
  yield takeEvery("COMPLAINANT_UPDATE_INFORMATION", complaintUpdateInfo);
  yield takeEvery("COMPLAINT_STATES", getStates);
  yield takeEvery("COMPLAINT_GET_ALL_INSA", getAllInsa);
  yield takeEvery("COMPLAINANT_GET_POLICY_FOR_ESCALATION", getPoliciesForEsc);
  yield takeEvery("COMPLAINT_DOC_UPLOAD_GET_API", docUploadGetData);
  yield takeEvery("COMPLAINT_UPLOAD_MUL_USER_DATA", uploadMulUserData);
  yield takeEvery("COMPLAINT_GET_LEAD", getLead);
  yield takeEvery("COMPLAINT_DELETE_STATUS", deleteStatus);
  yield all([call(getComplaintForDraft), call(getDocForDraftApprove), call(saveDraftExpertApprove), call(approveDraftMailByExpert), call(getCompForEscApprove), call(updateEscAppMail), call(approveEscalationMail), call(addLegalDraftComment)]);
}

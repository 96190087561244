import { servicePath } from "constants/defaultValues";
import { put, takeEvery } from "redux-saga/effects";
import { request } from "services/requests.services";
import { ADD_COMPANY_DETAILS, ADD_COMPANY_DETAILS_FAILURE, ADD_COMPANY_DETAILS_SUCCESS, ADD_NEW_TPA_COMPANY_FAILED, ADD_NEW_TPA_COMPANY_SUCCESS, GET_COMPANY_LIST, GET_COMPANY_LIST_FAILURE, GET_COMPANY_LIST_SUCCESS, GET_SINGLE_COMPANY, GET_SINGLE_COMPANY_FAILURE, GET_SINGLE_COMPANY_SUCCESS, GET_SINGLE_TPA_COMPANY_FAILED, GET_SINGLE_TPA_COMPANY_SUCCESS, GET_TPA_COMPANY_LIST_FAILED, GET_TPA_COMPANY_LIST_SUCCESS, UPDATE_COMPANY_DETAILS, UPDATE_COMPANY_DETAILS_FAILURE, UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_TPA_COMPANY_FAILED, UPDATE_TPA_COMPANY_SUCCESS } from "./Action";

function* getCompanyListData(action) {
  const searchKey = action.state?.searchKey;
  const policyType = action.state?.policyType;
  try {
    const data = yield request("GET", `${servicePath}/insurance_company/getAllCompany/${action.listType}?searchKey=${searchKey}&policyType=${policyType}`);
    yield put({ type: GET_COMPANY_LIST_SUCCESS, data: data.data });
  } catch (error) {
    yield put({
      type: GET_COMPANY_LIST_FAILURE
    });
  }
}

function* getSingleCompanyData(action) {
  try {
    const data = yield request("GET", `${servicePath}/insurance_company/getSingle/${action.id}/${action.listType}`);
    yield put({ type: GET_SINGLE_COMPANY_SUCCESS, data: data.data });
  } catch (error) {
    yield put({
      type: GET_SINGLE_COMPANY_FAILURE
    });
  }
}

function* updateCompanyData(action) {
  try {
    yield request("POST", `${servicePath}/insurance_company/updateSingleCompany`, action.data);
    yield put({ type: GET_COMPANY_LIST, listType: action.listType });
    yield put({ type: UPDATE_COMPANY_DETAILS_SUCCESS, data: action.data });
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_DETAILS_FAILURE
    });
  }
}

function* addCompanyData(action) {
  try {
    yield request("POST", `${servicePath}/insurance_company/addNewCompany`, action.data);
    yield put({ type: GET_COMPANY_LIST, listType: action.listType });
    yield put({ type: ADD_COMPANY_DETAILS_SUCCESS, data: action.data });
  } catch (error) {
    yield put({
      type: ADD_COMPANY_DETAILS_FAILURE
    });
  }
}

function* getTpaCompanies(action) {
  const searchKey = action.state?.searchKey;
  try {
    const data = yield request("GET", `${servicePath}/tpa_company?searchKey=${searchKey ? searchKey : ""}`);
    yield put({
      type: GET_TPA_COMPANY_LIST_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: GET_TPA_COMPANY_LIST_FAILED,
      message: "Failed to fetch TPA companies Data!"
    });
  }
}

function* getSingleTpaCompanies(action) {
  try {
    const { id } = action.data;
    const data = yield request("GET", `${servicePath}/tpa_company/${id}`);
    yield put({
      type: GET_SINGLE_TPA_COMPANY_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: GET_SINGLE_TPA_COMPANY_FAILED,
      message: "Failed to fetch TPA companies Data!"
    });
  }
}

function* addTpaCompany(action) {
  try {
    const data = yield request("POST", `${servicePath}/tpa_company/add`, action.data);
    yield put({
      type: ADD_NEW_TPA_COMPANY_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: ADD_NEW_TPA_COMPANY_FAILED,
      message: "Failed to fetch TPA companies Data!"
    });
  }
}

function* updateTpaCompany(action) {
  try {
    const data = yield request("PUT", `${servicePath}/tpa_company/${action.data.id}`, action.data.payload);
    yield put({
      type: UPDATE_TPA_COMPANY_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: UPDATE_TPA_COMPANY_FAILED,
      message: "Failed to fetch TPA companies Data!"
    });
  }
}

export default function* companySaga() {
  yield takeEvery(GET_COMPANY_LIST, getCompanyListData);
  yield takeEvery(GET_SINGLE_COMPANY, getSingleCompanyData);
  yield takeEvery(UPDATE_COMPANY_DETAILS, updateCompanyData);
  yield takeEvery(ADD_COMPANY_DETAILS, addCompanyData);
  yield takeEvery("GET_TPA_COMPANY_LIST", getTpaCompanies);
  yield takeEvery("GET_SINGLE_TPA_COMPANY", getSingleTpaCompanies);
  yield takeEvery("ADD_NEW_TPA_COMPANY", addTpaCompany);
  yield takeEvery("UPDATE_TPA_COMPANY", updateTpaCompany);
}

export const CRON_JOB_GET_DATA_LIST_START = "CRON_JOB_GET_DATA_LIST_START";
export const CRON_JOB_GET_DATA_LIST_START_SUCCESS = "CRON_JOB_GET_DATA_LIST_START_SUCCESS";
export const CRON_JOB_GET_DATA_LIST_START_FAILED = "CRON_JOB_GET_DATA_LIST_START_FAILED";

export const CRON_JOB_UPDATE_ACTION_START = "CRON_JOB_UPDATE_ACTION_START";
export const CRON_JOB_UPDATE_ACTION_START_SUCCESS = "CRON_JOB_UPDATE_ACTION_START_SUCCESS";
export const CRON_JOB_UPDATE_ACTION_START_FAILED = "CRON_JOB_UPDATE_ACTION_START_FAILED";

export const HANDLE_CRON_EXECUTION = "HANDLE_CRON_EXECUTION";
export const HANDLE_CRON_EXECUTION_SUCCESS = "HANDLE_CRON_EXECUTION_SUCCESS";
export const HANDLE_CRON_EXECUTION_FAILED = "HANDLE_CRON_EXECUTION_FAILED";

export const SEARCH_CRON = "SEARCH_CRON";
export const SEARCH_CRON_SUCCESS = "SEARCH_CRON_SUCCESS";
export const SEARCH_CRON_FAILED = "SEARCH_CRON_FAILED";

export const CREATE_CRON_CONFIG = "CREATE_CRON_CONFIG";
export const CREATE_CRON_CONFIG_SUCCESS = "CREATE_CRON_CONFIG_SUCCESS";
export const CREATE_CRON_CONFIG_FAILED = "CREATE_CRON_CONFIG_FAILED";

export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS";
export const GET_COMPANY_LIST_FAILURE = "GET_COMPANY_LIST_FAILURE";

export const ADD_COMPANY_DETAILS = "ADD_COMPANY_DETAILS";
export const ADD_COMPANY_DETAILS_SUCCESS = "ADD_COMPANY_DETAILS_SUCCESS";
export const ADD_COMPANY_DETAILS_FAILURE = "ADD_COMPANY_DETAILS_FAILURE";

export const UPDATE_COMPANY_DETAILS = "UPDATE_COMPANY_DETAILS";
export const UPDATE_COMPANY_DETAILS_SUCCESS = "UPDATE_COMPANY_DETAILS_SUCCESS";
export const UPDATE_COMPANY_DETAILS_FAILURE = "UPDATE_COMPANY_DETAILS_FAILURE";

export const GET_SINGLE_COMPANY = "GET_SINGLE_COMPANY";
export const GET_SINGLE_COMPANY_SUCCESS = "GET_SINGLE_COMPANY_SUCCESS";
export const GET_SINGLE_COMPANY_FAILURE = "GET_SINGLE_COMPANY_FAILURE";

export const GET_TPA_COMPANY_LIST = "GET_TPA_COMPANY_LIST";
export const GET_TPA_COMPANY_LIST_SUCCESS = "GET_TPA_COMPANY_LIST_SUCCESS";
export const GET_TPA_COMPANY_LIST_FAILED = "GET_TPA_COMPANY_LIST_FAILED";

export const GET_SINGLE_TPA_COMPANY = "GET_SINGLE_TPA_COMPANY";
export const GET_SINGLE_TPA_COMPANY_SUCCESS = "GET_SINGLE_TPA_COMPANY_SUCCESS";
export const GET_SINGLE_TPA_COMPANY_FAILED = "GET_SINGLE_TPA_COMPANY_FAILED";

export const ADD_NEW_TPA_COMPANY = "ADD_NEW_TPA_COMPANY";
export const ADD_NEW_TPA_COMPANY_SUCCESS = "ADD_NEW_TPA_COMPANY_SUCCESS";
export const ADD_NEW_TPA_COMPANY_FAILED = "ADD_NEW_TPA_COMPANY_FAILED";

export const UPDATE_TPA_COMPANY = "UPDATE_TPA_COMPANY";
export const UPDATE_TPA_COMPANY_SUCCESS = "UPDATE_TPA_COMPANY_SUCCESS";
export const UPDATE_TPA_COMPANY_FAILED = "UPDATE_TPA_COMPANY_FAILED";

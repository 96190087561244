import { put, takeEvery } from "redux-saga/effects";
import { request } from "services/requests.services";
import { servicePath } from "constants/defaultValues";
import { ADD_COMPLAINT_TYPE, ADD_COMPLAINT_TYPE_FAILURE, ADD_COMPLAINT_TYPE_SUCCESS, GET_COMPLAINT_TYPE_LIST, GET_COMPLAINT_TYPE_LIST_FAILURE, GET_COMPLAINT_TYPE_LIST_SUCCESS, UPDATE_COMPLAINT_TYPE, UPDATE_COMPLAINT_TYPE_FAILURE, UPDATE_COMPLAINT_TYPE_SUCCESS } from "./Action";

function* getComplaintTypeListData() {
  try {
    const res = yield request("GET", `${servicePath}/complaint_type/all`);
    yield put({ type: GET_COMPLAINT_TYPE_LIST_SUCCESS, data: res.data });
  } catch (err) {
    console.log(err);
    yield put({ type: GET_COMPLAINT_TYPE_LIST_FAILURE });
  }
}

function* addComplaintTypeListData(action) {
  try {
    yield request("POST", `${servicePath}/complaint_type`, action.data);
    yield put({ type: GET_COMPLAINT_TYPE_LIST });
    yield put({ type: ADD_COMPLAINT_TYPE_SUCCESS });
  } catch (err) {
    console.log(err);
    yield put({ type: ADD_COMPLAINT_TYPE_FAILURE });
  }
}

function* updateComplaintTypeListData(action) {
  try {
    yield request("PUT", `${servicePath}/complaint_type/${action.id}`, action.data);

    yield put({ type: GET_COMPLAINT_TYPE_LIST });
    yield put({ type: UPDATE_COMPLAINT_TYPE_SUCCESS });
  } catch (err) {
    console.log(err);
    yield put({ type: UPDATE_COMPLAINT_TYPE_FAILURE });
  }
}

export default function* complaintTypeSaga() {
  yield takeEvery(GET_COMPLAINT_TYPE_LIST, getComplaintTypeListData);
  yield takeEvery(ADD_COMPLAINT_TYPE, addComplaintTypeListData);
  yield takeEvery(UPDATE_COMPLAINT_TYPE, updateComplaintTypeListData);
}

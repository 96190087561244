import { put, takeEvery } from "redux-saga/effects";
import { request } from "services/requests.services";
import { servicePath } from "constants/defaultValues";

function* manualInvoiceUpdate(action) {
  try {
    const res = yield request("PATCH", `${servicePath}/counter/updateManualInvoice`, action.payload);
    if (!res.success) {
      yield put({
        type: "UPDATE_INVOICE_SUBMIT_FORM_FAILURE",
        data: res.data,
        message: res.message
      });
    } else {
      yield put({
        type: "UPDATE_INVOICE_SUBMIT_FORM_SUCCESS",
        data: res.data,
        message: res.message
      });
    }
  } catch (error) {
    yield put({
      type: "UPDATE_INVOICE_SUBMIT_FORM_FAILURE",
      data: null,
      message: error.message
    });
  }
}

export default function* manualInvoiceUpdateSaga() {
  yield takeEvery("UPDATE_INVOICE_SUBMIT_FORM_REQUEST", manualInvoiceUpdate);
}

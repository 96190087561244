import { servicePath } from "constants/defaultValues";
import { log } from "helpers/Utils";
import { call, put, takeEvery } from "redux-saga/effects";
import { request } from "services/requests.services";

function* getUserList(action) {
  try {
    const data = yield request(
      "GET",
      // here changing and adding the query....
      `${servicePath}/user?pageIndex=${action.state.pageIndex}&pageSize=${action.state.pageSize}`
    );
    yield put({ type: "CUSTOMER_GET_USER_LIST_SUCCESS", data: data.data });
  } catch (error) {
    yield put({
      type: "CUSTOMER_GET_USER_LIST_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* getUnBlockedUser(action) {
  try {
    yield request("GET", `${servicePath}/user/unblock/${action.state.id}`);
    yield put({ type: "CUSTOMER_UNBLOCK_DATA_SUCESS" });
    yield call(getUserList, { state: { pageIndex: 0, pageSize: 50 } });
  } catch (error) {
    yield put({
      type: "CUSTOMER_UNBLOCK_DATA_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* getFilterUserList(action) {
  try {
    // log(action);
    const data = yield request("GET", `${servicePath}/user?pageIndex=${action.state.pageIndex}&pageSize=${action.state.pageSize}${action.state.keyword ? `&keyword=${action.state?.keyword}` : ""}${action.state?.selectedSortOrder ? `&selectedSortOrder=${action.state?.selectedSortOrder}` : ""}`);
    yield put({ type: "CUSTOMER_FILTER_DATA_SUCCESS", data: data.data });
  } catch (error) {
    yield put({
      type: "CUSTOMER_FILTER_DATA_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* viewPassword(action) {
  try {
    const data = yield request("GET", `${servicePath}/user/getPwd/${action.state.id}`);
    yield put({ type: "CUSTOMER_VIEW_PASSWORD_SUCCESS", data: data.data });
  } catch (error) {
    yield put({
      type: "CUSTOMER_VIEW_PASSWORD_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* updateServiceRate(action) {
  try {
    const data = yield request("POST", `${servicePath}/user/updateServiceRate`, { ...action.state });
    yield put({
      type: "CUSTOMER_UPDATE_SERVICE_RATE_SUCCESS",
      data: data.message
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_UPDATE_SERVICE_RATE_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* viewGmail(action) {
  try {
    const data = yield request("POST", `${servicePath}/gmail/check-read-email-permission`, { ...action.state });
    log(data);
    yield put({
      type: "CUSTOMER_GMAIL_READ_SUCCESS",
      message: data.message
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_GMAIL_READ_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* issuedDoc(action) {
  try {
    const data = yield request("POST", `${servicePath}/digilocker/admin-user-documents-list`, { ...action.state });
    yield put({
      type: "CUSTOMER_ADMIN_ISSUED_DOC_SUCCESS",
      message: data.data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_ADMIN_ISSUED_DOC_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* selfDoc(action) {
  try {
    const data = yield request("POST", `${servicePath}/digilocker/admin-user-documents-list`, { ...action.state });
    yield put({
      type: "CUSTOMER_ADMIN_SELF_DOC_SUCCESS",
      message: data.data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_ADMIN_SELF_DOC_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* userDoc(action) {
  try {
    const data = yield request("POST", `${servicePath}/digilocker/admin-user-documents`, { ...action.state });
    yield put({
      type: "CUSTOMER_USER_DOC_LIST_SUCCESS",
      message: data.data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_USER_DOC_LIST_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* addComment(action) {
  try {
    const data = yield request("POST", `${servicePath}/user/addReview`, { ...action.state });
    yield put({
      type: "CUSTOMER_ADD_COMMENT_SUCCESS",
      message: data.data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_ADD_COMMENT_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* downloadReport() {
  try {
    const data = yield request("GET", `${servicePath}/analytics/csv/user`);
    yield put({
      type: "CUSTOMER_DOWNLOAD_REPORT_SUCCESS",
      data: data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_DOWNLOAD_REPORT_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* updateCustomer(action) {
  try {
    const data = yield request("PUT", `${servicePath}/user/${action.state?._id}`, { ...action.state });
    yield put({
      type: "CUSTOMER_UPDATE_DATA_SUCCESS",
      data: data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_UPDATE_DATA_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* getCustomerPwd(action) {
  try {
    const data = yield request("GET", `${servicePath}/user/getPwd/${action.state?._id}`);
    yield put({
      type: "CUSTOMER_GET_PASSWORD_RATE_SUCCESS",
      data: data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_GET_PASSWORD_RATE_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* addCommComment(action) {
  try {
    const data = yield request("POST", `${servicePath}/user/addComm`, action.state);
    yield put({
      type: "CUSTOMER_ADD_COMM_COMMENT_SUCCESS",
      data: data
    });
  } catch (error) {
    yield put({
      type: "CUSTOMER_ADD_COMM_COMMENT_FAILED",
      message: "Failed to fetch !"
    });
  }
}

function* botFollowUp(action) {
  try {
    const data = yield request("POST", `${servicePath}/botFollowUp/stopBotTemplateMsg`, action.state);
    yield put({
      type: "CUSTOMER_BOT_FOLLOW_UP_SUCCESS",
      data: data
    });
  } catch (error) {
    log(error);
    yield put({
      type: "CUSTOMER_BOT_FOLLOW_UP_FAILED",
      message: "Failed to fetch !"
    });
  }
}

export default function* customer() {
  yield takeEvery("CUSTOMER_GET_USER_LIST", getUserList);
  yield takeEvery("CUSTOMER_GET_UNBLOCKLIST", getUnBlockedUser);
  // yield takeEvery("CUSTOMER_GET_ISACTIVE",getIsActiveUser);
  yield takeEvery("CUSTOMER_FILTER_DATA", getFilterUserList);
  yield takeEvery("CUSTOMER_VIEW_PASSWORD", viewPassword);
  yield takeEvery("CUSTOMER_UPDATE_SERVICE_RATE", updateServiceRate);
  yield takeEvery("CUSTOMER_GMAIL_READ", viewGmail);
  yield takeEvery("CUSTOMER_ADMIN_ISSUED_DOC", issuedDoc);
  yield takeEvery("CUSTOMER_ADMIN_SELF_DOC", selfDoc);
  yield takeEvery("CUSTOMER_USER_DOC_LIST", userDoc);
  yield takeEvery("CUSTOMER_ADD_COMMENT", addComment);
  yield takeEvery("CUSTOMER_DOWNLOAD_REPORT", downloadReport);
  yield takeEvery("CUSTOMER_UPDATE_DATA", updateCustomer);
  yield takeEvery("CUSTOMER_GET_PASSWORD_RATE", getCustomerPwd);
  yield takeEvery("CUSTOMER_ADD_COMM_COMMENT", addCommComment);
  yield takeEvery("CUSTOMER_BOT_FOLLOW_UP", botFollowUp);
}

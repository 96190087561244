export const GET_TRANSCRIPT_DATA = "GET_TRANSCRIPT_DATA";
export const GET_TRANSCRIPT_DATA_SUCCESS = "GET_TRANSCRIPT_DATA_SUCCESS";
export const GET_TRANSCRIPT_DATA_FAILED = "GET_TRANSCRIPT_DATA_FAILED";

export const BOT_TRANSCRIPT_MAKE_CALL = "BOT_TRANSCRIPT_MAKE_CALL";
export const BOT_TRANSCRIPT_MAKE_CALL_SUCCESS = "BOT_TRANSCRIPT_MAKE_CALL_SUCCESS";
export const BOT_TRANSCRIPT_MAKE_CALL_FAILED = "BOT_TRANSCRIPT_MAKE_CALL_FAILED";

export const BOT_TRANSCRIPT_COMMUNICATION = "BOT_TRANSCRIPT_COMMUNICATION";
export const BOT_TRANSCRIPT_COMMUNICATION_SUCCESS = "BOT_TRANSCRIPT_COMMUNICATION_SUCCESS";
export const BOT_TRANSCRIPT_COMMUNICATION_FAILED = "BOT_TRANSCRIPT_COMMUNICATION_FAILED";

export const BOT_TRANSCRIPT_ADD_COMMENT = "BOT_TRANSCRIPT_ADD_COMMENT";
export const BOT_TRANSCRIPT_ADD_COMMENT_SUCCESS = "BOT_TRANSCRIPT_ADD_COMMENT_SUCCESS";
export const BOT_TRANSCRIPT_ADD_COMMENT_FAILED = "BOT_TRANSCRIPT_ADD_COMMENT_FAILED";

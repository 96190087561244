import { put, takeLatest } from "redux-saga/effects";
import { servicePath, partnerServicePath } from "constants/defaultValues";
import { request } from "services/requests.services";
import { FETCH_APP_VERSION_REQUEST, FETCH_APP_VERSION_SUCCESS, FETCH_APP_VERSION_FAILURE, UPDATE_VERSION_REQUEST, UPDATE_VERSION_SUCCESS, UPDATE_VERSION_FAILURE } from "./versionAction";
function* fetchAppVersion(action) {
  try {
    const app = action.payload;
    let url = "";
    if (app === "Polifyx") {
      url = `${servicePath}/app/get-app-version`;
    } else if (app === "Polifyx Partner") {
      url = `${partnerServicePath}/app/getVersion`;
    }

    const response = yield request("GET", url);
    const data = response.data;

    const versionApiData =
      app === "Polifyx"
        ? data.map((item) => {
            const [currentVersionName, versionCode] = item.version.split("+");
            const [minimumVersionName, minimumVersionCode] = item.minimumVersion.split("+");

            return {
              platform: item.platform,
              version: currentVersionName,
              versionCode: versionCode,
              minimumVersion: minimumVersionName,
              minimumVersionCode: minimumVersionCode,
              forceUpdate: item.forceUpdate
            };
          })
        : data.flatMap((item) => [
            {
              id: item._id,
              currentVersion: item.currentVersion,
              currentVersionName: item.currentVersionName,
              minimumVersion: item.minimumVersion,
              minimumVersionName: item.minimumVersionName,
              platform: item.platform,
              forceUpdate: item.forceUpdate
            }
          ]);
    yield put({ type: FETCH_APP_VERSION_SUCCESS, payload: versionApiData });
  } catch (error) {
    console.error("Error fetching app version:", error.message);
    yield put({ type: FETCH_APP_VERSION_FAILURE, payload: error.message });
  }
}
function* updateVersion(action) {
  try {
    const versionData = action.payload;
    const url = versionData.app.toLowerCase() === "polifyx" ? `${servicePath}/app/update-app-version?platform=${versionData.platform}` : `${partnerServicePath}/app/setVersion`;
    const payload =
      versionData.app === "Polifyx"
        ? {
            ...versionData,
            versionCode: versionData.currentVersion || "",
            version: versionData.currentVersionName || "",
            minimumVersion: versionData.minimumVersionName || "",
            minimumVersionCode: versionData.minimumVersion || "",
            platform: versionData.platform || "",
            forceUpdate: versionData.forceUpdate,
            pin: versionData.pin
          }
        : {
            ...versionData,
            currentVersion: versionData.currentVersion || "",
            currentVersionName: versionData.currentVersionName || "",
            minimumVersion: versionData.minimumVersion || "",
            minimumVersionName: versionData.minimumVersionName || "",
            platform: versionData.platform || "",
            forceUpdate: versionData.forceUpdate,
            pin: versionData.pin
          };
    const response = yield request("POST", url, payload);
    if (response.success) {
      yield put({ type: UPDATE_VERSION_SUCCESS, payload: response });
    } else {
      yield put({ type: UPDATE_VERSION_FAILURE, payload: response.message });
    }
  } catch (error) {
    console.error("Error updating version:", error.message);
    yield put({ type: UPDATE_VERSION_FAILURE, payload: error.message });
  }
}
export function* VersionActions() {
  yield takeLatest(FETCH_APP_VERSION_REQUEST, fetchAppVersion);
  yield takeLatest(UPDATE_VERSION_REQUEST, updateVersion);
}

//Some constants to use in form fields

import moment from "moment";

//dummy options
export const options = [
  { value: "", label: "Select an Option" },
  { value: "Something", label: "Something" },
  { value: "Everything", label: "Everything" }
];

export const cat = [
  { value: "Health Insurance", label: "Health Insurance", id: "3030b68f-82e9-4dfb-b2b8-82b743d0692a" },
  { value: "Life Insurance", label: "Life Insurance", id: "864a375b-5fa7-49c7-9f39-2b1fda4674b5" },
  { value: "Genreal Insurance", label: "Genreal Insurance", id: "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2" }
];
export const legalCourt = [
  { value: "", label: "Select Type" },
  { value: "Supreme Court", label: "Supreme Court" },
  { value: "High Court", label: "High Court" },
  { value: "National Consumer Forum", label: "National Consumer Forum" },
  { value: "State Consumer Forum", label: "State Consumer Forum" },
  { value: "District Consumer Forum", label: "District Consumer Forum." },
  { value: "Commercial court", label: "Commercial court" },
  { value: "Civil court", label: "Civil court" },
  { value: "MSME", label: "MSME" },
  { value: "PLA", label: "PLA" }
];

export const appealFiledBefore = [
  { value: "", label: "Select Type" },
  { value: "National Commission", label: "National Commission" },
  { value: "Supreme Court", label: "Supreme Court" },
  { value: "High Court", label: "High Court" },
  { value: "Civil Court Senior Division", label: "Civil Court Senior Division" },
  { value: "District Judge Court", label: "District Judge Court" }
];

export const natureOfCaseList = [
  { value: "", label: "Select Type" },
  { value: "Consumer Complaint", label: "Consumer Complaint" },
  { value: "Recovery Suit", label: "Recovery Suit" },
  { value: "Writ", label: "Writ" },
  { value: "Employee related matters", label: "Employee related matters" }
];

export const genralInsurance = [
  { value: "", label: "Select an Option" },
  { value: "Something", label: "Something" },
  { value: "Everything", label: "Everything" }
];
export const legalBucket = ["Legal", "LEGAL CONTRACT PENDING", "LEGAL CONTRACT SIGNED", "LEGAL QUERY RAISED", "LEGAL QUERY ANSWERED", "LEGAL DRAFT FOR APPROVAL", "COURT FILING POINTS", "COURT FILING PENDING BUCKET", "CASE FILING DONE", "LEGAL RECOVERY FROM CUSTOMER", "LEGAL NOTICE RECEIVED", "LEGAL RECOVERY FROM COMPANY", "COURT ISSUED SUMMON", "COMPANY REPLY AWAITED", "REJOINDER NEED TO BE FILED", "AFFIDAVIT NEED TO BE FILED", "COMPLAINANT/PLAINTIFF EVIDENCE", "DEFENDANT/RESPONDENT EVIDENCE", "SETTLEMENT PROPOSAL SUBMISSION PENDING", "SETTLEMENT PROPOSAL SUBMITTED", "ARGUMENTS", "FINAL ARGUMENTS", "ORDER AWAITED", "LEGAL DRAFT FOR APPROVAL WITH CLIENT", "COURT FILING PENDING WITH CLIENT", "COMPLAINANT/PLAINTIFF EVIDENCE", "ARGUMENTS", "LEGAL RECOVERY FROM COMPANY", "LEGAL RECOVERY FROM CUSTOMER", "INVOICE RAISED", "ADMISSION", "MSME", "CASE ASSIGNED TO ADVOCATE", "Case assigned to Advocate", "Legal Draft for Approval with  client", "Legal Draft for Approval with expert", "Legal Draft Approved", "MSME Filing Done", "Written Arguments", "Recovery suit filed", "MSME", "MSME filed", "Recovery Suit", "LEGAL NOTICE SENT TO CUSTOMER", "LEGAL NOTICE SENT TO COMPANY"];
export const missellingFraudId = ["f53e05b1-df2f-4012-a9ef-0f31b76ac418", "cc3393c0-1eb5-4634-9fe1-5d3fa25d2b38", "4431dd69-9b95-45be-8a35-a38ae228316e", "4bdce40b-8d06-4a4d-ac77-f060764c629a", "3b343f2b-429c-47f8-843d-b7f8ff90a218"];
export const hideRejectStatus = ["CUSTOMER REJECTED", "WRITTEN OFF", "INSA REJECTED", "Reject", "CUSTOMER WITHDRAW"];
export const hideSectionStatus = ["CUSTOMER REJECTED", "WRITTEN OFF", "INSA REJECTED", "Reject", "Pending", "CUSTOMER WITHDRAW"];
export const escalationMailStatus = ["Accept", "COMPLAINT FORM FILLED", "DRAFT MAIL GENERATED", "EXPERT APPROVED DRAFT MAIL"];

export const GIType = ["Motor Theft", "Travel", "Fidelity", "Marine", "Property", "Fire", "Theft", "Home Insurance", "Motor Accident", "Personal Accident", "Critical Illness", "Workmen              Compensation", "Misseling & Fraud", "Others"];

export const HIType = ["Individual", "Family", "Group", "Bancassurance", "Others"];
export const LIType = ["Term Insurance", "Endowment", "ULIP", "Moneyback", "Annuity/Pension Plan", "Whole Life Policy", "Others"];

export const escalationStatusArray = ["ESCALATION PENDING", "NO RESPONSE RECEIVED", "ESCALATION APPROVED", "COMPANY REQUIREMENT RECEIVED", "COMPANY REQUIREMENT SENT", "ESCALATION MAIL GENERATED", "COMPANY ESCALATED", "IGMS ESCALATED", "COMPANY/IGMS ESCALATED", "Ombudsman Pending", "COMPANY/IGMS"];

export const childStatus = ["Resolved", "Settled", "INVOICE RAISED", "INVOICE PROCESSING"];

export const customStylesMultiSelect = {
  option: (provided) => ({
    ...provided,
    backgroundColor: "white"
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  }
};

export const selectMultipleItems = [
  { value: "", label: "Select Items" },
  { value: "AI", label: "Artificial Intelligence", disabled: true },
  { value: "Machine Learning", label: "ReasonML" },
  { value: "Blockchain", label: "Blockchain" },
  { value: "Data Science", label: "Data Science" }
];

export const choices = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isCustomerIDRegistered = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const draftSentByCustomer = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isAcknowledgementReceived = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isDraftSentByCustomer = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isRequirementMailSent = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isRequirementMailRevert = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isFirstResponseFromCompany = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isFirstReminderMailSentToCompany = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isFirstEscalationSent = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isSecondResponseFromCompany = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isSecondReminderMailSentToCompany = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const calledCustForRewardStatus = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isRequirementReceived = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const isLegalNoticeOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" }
];

export const education = [
  { value: "", label: "Select an Option" },
  { value: "Not Educated", label: "Not Educated" },
  { value: "8th pass", label: "8th pass" },
  { value: "10th pass", label: "10th pass" },
  { value: "12th Pass", label: "12th Pass" },
  { value: "Graduate", label: "Graduate" },
  { value: "Post graduate", label: "Post Graduate" },
  { value: "Diploma holder", label: "Diploma" },
  { value: "Doctrate", label: "Doctrate" }
];

export const occupation = [
  { value: "", label: "Select Occupation" },
  { value: "Small business Owner", label: "Small business Owner" },
  { value: "Self Employed", label: "Self Employed" },
  { value: "Daily Wager", label: "Daily Wager" },
  { value: "Serviceman/Professional", label: "Serviceman/Professional" },
  { value: "Armed Forces", label: "Armed Forces" },
  { value: "Farmer", label: "Farmer" },
  { value: "Shopkeeper", label: "Shopkeeper" },
  { value: "Delivery Boy", label: "Delivery Boy" },
  { value: "Businessman", label: "Businessman" },
  { value: "Housewife", label: "Housewife" },
  { value: "Retired Personnel", label: "Retired Personnel" },
  { value: "Retired from Army", label: "Retired from Army" },
  { value: "Unemployed", label: "Unemployed" },
  { value: "Freelancer", label: "Freelancer" },
  { value: "Government Job", label: "Government Job" },
  { value: "Defence person", label: "Defence person" },
  { value: "Others", label: "Others" }
];

export const policyTypes = [
  { value: "", label: "Select Option" },
  { value: "Individual", label: "Individual" },
  { value: "Family", label: "Family" },
  { value: "Group", label: "Group" },
  { value: "Bancassurance", label: "Bancassurance" },
  { value: "Others", label: "Others" }
];

export const claimRejectionTypes = [
  { value: "", label: "Select Rejection Type" },
  { value: "Pre-existing Disease", label: "Pre-existing Disease" }
];

export const realtionships = [
  { value: "-", label: "Select Relation" },
  { value: "Self", label: "Self" },
  { value: "Mother", label: "Mother" },
  { value: "Father", label: "Father" },
  { value: "Son", label: "Son" },
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
  { value: "Daughter", label: "Daughter" },
  { value: "Husband", label: "Husband" },
  { value: "Wife", label: "Wife" },
  { value: "Grandfather", label: "Grandfather" },
  { value: "Friend", label: "Friend" },
  { value: "Niece", label: "Niece" },
  { value: "Nephew", label: "Nephew" },
  { value: "Uncle", label: "Uncle" },
  { value: "Aunt", label: "Aunt" },
  { value: "Other", label: "Other" }
];

export const firstResponseType = [
  { value: "", label: "Select Response Type" },
  { value: "Rejection", label: "Rejection" },
  { value: "Requirement", label: "Requirement" },
  { value: "No Response", label: "No Response" },
  { value: "Resolution", label: "Resolution" },
  { value: "Mail Id To Be Registered", label: "Mail Id To Be Registered" }
];

export const complaintDelayReason = [
  { value: "", label: " Select Delay Reason" },
  { value: "Customer not responding", label: "Customer not responding" },
  { value: "Customer responding but not doing the mail", label: "Customer responding but not doing the mail" },
  { value: "Customer is not fulfilling the requirements raised by the company", label: "Customer is not fulfilling the requirements raised by the company" },
  { value: "Company/Hospital is not fulfilling the requirements.", label: "Company/Hospital is not fulfilling the requirements." },
  { value: "Company/Hospital is not fulfilling the requirements.", label: "Customer is not fulfilling the requirements raised by the executive" },
  { value: "Not getting the approval from expert end", label: "Not getting the approval from expert end" },
  { value: "Others", label: "Others" }
];

export const secondResponseType = [
  { value: "", label: "Select Response Type" },
  { value: "Rejection", label: "Rejection" },
  { value: "Requirement", label: "Requirement" },
  { value: "No Response", label: "No Response" },
  { value: "Resolution", label: "Resolution" },
  { value: "Mail Id To Be Registered", label: "Mail Id To Be Registered" }
];

export const igmsDoneBy = [
  { value: "", label: "Select Igms Done By" },
  { value: "PAN-No", label: "PAN-No" },
  { value: "Mobile-No", label: "Mobile-No" },
  { value: "LandLine-No", label: "LandLine-No" },
  { value: "Voter-ID-No", label: "Voter-ID-No" },
  { value: "Ration-Card-No", label: "Ration-Card-No" },
  { value: "Passport-No", label: "Passport-No" },
  { value: "DOB", label: "DOB" }
];

export const ombudsmanLocation = [
  { value: "", label: "Select Ombudsman Location" },
  { value: "Ahmedabad", label: "Ahmedabad" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Bhopal", label: "Bhopal" },
  { value: "Bhubaneshwar", label: "Bhubaneshwar" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Chennai", label: "Chennai" },
  { value: "Delhi", label: "Delhi" },
  { value: "Guwahati", label: "Guwahati" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Ernakulam", label: "Ernakulam" },
  { value: "Kolkata", label: "Kolkata" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Noida", label: "Noida" },
  { value: "Patna", label: "Patna" },
  { value: "Pune", label: "Pune" },
  { value: "Guwahti", label: "Guwahti" },
  { value: "Thane", label: "Thane" }
];

export const hearingCommentValues = [
  { value: "", label: "Select Type" },
  { value: "RefundAccepted", label: "RefundAccepted" },
  { value: "SinglePayAccepted", label: "SinglePayAccepted" },
  { value: "SinglePayAdditionalPayment", label: "Single Pay with additional Payment" },
  { value: "SinglePaySomeRefund", label: "Single Pay with Some Refund" },
  { value: "ClaimPayable", label: "ClaimPayable" },
  { value: "ClaimPayableInterest", label: "Claim Payable with Interest" },
  { value: "ClaimRejected", label: "Claim is rejected" },
  { value: "RefundRejected", label: "Refund is Rejected" },
  { value: "DecisionPending", label: "DecisionPending" }
];

export const resolutionType = [
  { value: "", label: "Select Type" },
  { value: "RefundAccepted", label: "RefundAccepted" },
  { value: "SinglePayAccepted", label: "SinglePayAccepted" },
  { value: "SinglePayAdditionalPayment", label: "Single Pay with additional Payment" },
  { value: "SinglePaySomeRefund", label: "Single Pay with Some Refund" },
  { value: "ClaimPayable", label: "Claim Payable" },
  { value: "ClaimPayableInterest", label: "Claim Payable with Interest" },
  { value: "ClaimRejected", label: "Claim is rejected" },
  { value: "RefundRejected", label: "Refund is Rejected" }
];

export const ombudsmanPendingReasonValues = [
  { value: "", label: "Select Type" },
  { value: "mailPendingFromCustomerEnd", label: "Mail Pending from Customer's Side" },
  { value: "escalationPending", label: "Escalation Pending" },
  { value: "documentPending", label: "Document Pending" }
];

export const consumerCourtLocations = [
  { value: "", label: "Select Type" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Pune", label: "Pune" },
  { value: "Chennai", label: "Chennai" },
  { value: "Delhi", label: "Delhi" },
  { value: "Banglore", label: "Banglore" }
];

export const movementOfCase = [
  { value: "", label: "Select Movement" },
  { value: "Ombudsman", label: "Ombudsman" },
  { value: "Legal", label: "Legal" },
  { value: "Others", label: "Others" }
];

export const emailType = [
  { value: "", label: " Select Type" },
  { value: "First Draft Mail", label: "First Draft Mail" },
  { value: "IGMS first entry", label: "IGMS first entry" },
  { value: "Response draft mail", label: "Escalation draft mail" },
  { value: "IGMS escalation entry", label: "IGMS escalation entry" },
  { value: "Others", label: "Others" }
];

export const nonResponsiveCustomerFlow = [
  { value: "", label: "Select Type" },
  { value: "Non-Responsive", label: "Non-Responsive" },
  { value: "Out of Network", label: "Out of Network" },
  { value: "Other", label: "Other" }
];

export const selectReminderType = [
  { value: "", label: "Select Type" },
  { value: "Single", label: "Single" },
  { value: "Multiple", label: "Multiple" }
];

export const sortOrderOptions = [
  { value: "leadId:ASC", label: "Asc Lead Id" },
  { value: "leadId:DESC", label: "Desc LeadId" },
  { value: "firstName:ASC", label: "Asc First Name" },
  { value: "firstName:DESC", label: "Desc First Name" },
  { value: "email:ASC", label: "Asc Email" },
  { value: "email:DESC", label: "Desc Email" },
  { value: "phone:ASC", label: "Asc Phone" },
  { value: "phone:DESC", label: "Desc Phone" },
  { value: "createdAt:ASC", label: "Asc CreatedAt" },
  { value: "createdAt:DESC", label: "Desc CreatedAt" }
];

export let bucketCount = {
  userBucket: [
    "ALL",
    "PENDING",
    "ACCEPTED",
    "ACCEPTED WITH PENDENCY",
    "INCOMPLETE INFORMATION",
    "CONTRACT SIGNED",
    "COMPLAINT FORM FILLED",
    "DRAFT MAIL GENERATED",
    "EXPERT APPROVED DRAFT MAIL",
    "COMPANY",
    "IGMS",
    "ESCALATION MAIL GENERATED",
    "COMPANY ESCALATED",
    "IGMS ESCALATED",
    "COMPANY/IGMS ESCALATED",
    "ESCALATION PENDING",
    "NO RESPONSE RECEIVED",
    "ESCALATION APPROVED",
    "COMPANY REQUIREMENT RECEIVED",
    "COMPANY REQUIREMENT SENT",
    "OMBUDSMAN PENDING",
    "OMBUDSMAN REQUIREMENT PENDING",
    "OMBUDSMAN REQUIREMENT PUSHED",
    "COMPLAINT FORM SENT",
    "OMBUDSMAN WITHOUT LEGAL",
    "OMBUDSMAN REQUIREMENT SENT",
    "FORM 6A RECEIVED",
    "FORM 6A PUSHED",
    "FORM 6A SENT",
    "HEARING DATE RECEIVED",
    "HEARING DONE",
    "AWARD ACCEPTED",
    "AWARD REJECTED",
    "LEGAL",
    "INVOICE RAISED",
    "REMINDER MAIL SENT",
    "REJECTED",
    "COMPANY PAYMENT PENDING",
    "OMBUDSMAN WITH LEGAL",
    "RESOLVED",
    "UNRESOLVED",
    "SETTLED",
    "REIMBURSEMENT",
    "ASSERVICES",
    "WRITTEN OFF",
    "INSA REJECTED",
    "LEGAL CONTRACT PENDING",
    "LEGAL CONTRACT SIGNED",
    "LEGAL QUERY RAISED",
    "LEGAL QUERY ANSWERED",
    "LEGAL DRAFT FOR APPROVAL",
    "COURT FILING POINTS",
    "COURT FILING PENDING BUCKET",
    "CASE FILING DONE",
    "CUSTOMER WITHDRAW",
    "LEGAL RECOVERY FROM CUSTOMER",
    "LEGAL NOTICE RECEIVED",
    "LEGAL NOTICE SENT",
    "LEGAL NOTICE SENT TO CUSTOMER",
    "LEGAL NOTICE SENT TO COMPANY",
    "LEGAL RECOVERY FROM COMPANY",
    "COURT ISSUED SUMMON",
    "COMPANY REPLY AWAITED",
    "REJOINDER NEED TO BE FILED",
    "AFFIDAVIT NEED TO BE FILED",
    "COMPLAINANT/PLAINTIFF EVIDENCE",
    "DEFENDANT/RESPONDENT EVIDENCE",
    "SETTLEMENT PROPOSAL SUBMISSION PENDING",
    "SETTLEMENT PROPOSAL SUBMITTED",
    "ARGUMENTS",
    "FINAL ARGUMENTS",
    "ORDER AWAITED",
    "INVOICE PROCESSING",
    "NR FOLLOW UP"
  ],
  complaintBucket: [
    "ALL",
    "Pending",
    "Accept",
    "ACCEPTED WITH PENDENCY",
    "Information Incomplete",
    "COMPLAINT FORM FILLED",
    "DRAFT MAIL GENERATED",
    "EXPERT APPROVED DRAFT MAIL",
    "Company",
    "IGMS",
    "EXPERT MAIL DRAFTED",
    "ESCALATION PENDING",
    "NO RESPONSE RECEIVED",
    "ESCALATION APPROVED",
    "COMPANY REQUIREMENT RECEIVED",
    "COMPANY REQUIREMENT SENT",
    "ESCALATION MAIL GENERATED",
    "COMPANY ESCALATED",
    "IGMS ESCALATED",
    "COMPANY/IGMS ESCALATED",
    "Ombudsman Pending",
    "OMBUDSMAN REQUIREMENT PENDING",
    "OMBUDSMAN REQUIREMENT PUSHED",
    "COMPLAINT FORM SENT",
    "Ombudsman without Legal",
    "OMBUDSMAN REQUIREMENT SENT",
    "FORM 6A RECEIVED",
    "FORM 6A PUSHED",
    "FORM 6A SENT",
    "HEARING DATE RECEIVED",
    "HEARING DONE",
    "AWARD ACCEPTED",
    "AWARD REJECTED",
    "Legal",
    "INVOICE RAISED",
    "REMINDER MAIL SENT",
    "Reject",
    "COMPANY PAYMENT PENDING",
    "Ombudsman with Legal",
    "Resolved",
    "UnResolved",
    "Settled",
    "REIMBURSEMENT",
    "ASSERVICES",
    "WRITTEN OFF",
    "INSA REJECTED",
    "LEGAL CONTRACT PENDING",
    "LEGAL CONTRACT SIGNED",
    "LEGAL QUERY RAISED",
    "LEGAL QUERY ANSWERED",
    "LEGAL DRAFT FOR APPROVAL",
    "COURT FILING POINTS",
    "COURT FILING PENDING BUCKET",
    "CASE FILING DONE",
    "CUSTOMER WITHDRAW",
    "INVOICE PROCESSING",
    "LEGAL RECOVERY FROM CUSTOMER",
    "LEGAL NOTICE RECEIVED",
    "LEGAL NOTICE SENT",
    "LEGAL NOTICE SENT TO CUSTOMER",
    "LEGAL NOTICE SENT TO COMPANY",
    "LEGAL RECOVERY FROM COMPANY",
    "COURT ISSUED SUMMON",
    "COMPANY REPLY AWAITED",
    "REJOINDER NEED TO BE FILED",
    "AFFIDAVIT NEED TO BE FILED",
    "COMPLAINANT/PLAINTIFF EVIDENCE",
    "DEFENDANT/RESPONDENT EVIDENCE",
    "SETTLEMENT PROPOSAL SUBMISSION PENDING",
    "SETTLEMENT PROPOSAL SUBMITTED",
    "ARGUMENTS",
    "FINAL ARGUMENTS",
    "ORDER AWAITED"
  ]
};

export const status = [
  "ALL",
  "Pending",
  "Accept",
  "ACCEPTED WITH PENDENCY",
  "Information Incomplete",
  "COMPLAINT FORM FILLED",
  "DRAFT MAIL GENERATED",
  "EXPERT APPROVED DRAFT MAIL",
  "Company",
  "IGMS",
  "EXPERT MAIL DRAFTED",
  "ESCALATION PENDING",
  "NO RESPONSE RECEIVED",
  "ESCALATION APPROVED",
  "COMPANY REQUIREMENT RECEIVED",
  "COMPANY REQUIREMENT SENT",
  "ESCALATION MAIL GENERATED",
  "COMPANY ESCALATED",
  "IGMS ESCALATED",
  "COMPANY/IGMS ESCALATED",
  "Ombudsman Pending",
  "OMBUDSMAN REQUIREMENT PENDING",
  "OMBUDSMAN REQUIREMENT PUSHED",
  "COMPLAINT FORM SENT",
  "Ombudsman without Legal",
  "OMBUDSMAN REQUIREMENT SENT",
  "FORM 6A RECEIVED",
  "FORM 6A PUSHED",
  "FORM 6A SENT",
  "HEARING DATE RECEIVED",
  "HEARING DONE",
  "AWARD ACCEPTED",
  "AWARD REJECTED",
  "Legal",
  "INVOICE RAISED",
  "REMINDER MAIL SENT",
  "Reject",
  "COMPANY PAYMENT PENDING",
  "Ombudsman with Legal",
  "Resolved",
  "UnResolved",
  "Settled",
  "REIMBURSEMENT",
  "ASSERVICES",
  "WRITTEN OFF",
  "INSA REJECTED",
  "LEGAL CONTRACT PENDING",
  "LEGAL CONTRACT SIGNED",
  "LEGAL QUERY RAISED",
  "LEGAL QUERY ANSWERED",
  "LEGAL DRAFT FOR APPROVAL",
  "COURT FILING POINTS",
  "COURT FILING PENDING BUCKET",
  "CASE FILING DONE",
  "CUSTOMER WITHDRAW",
  "INVOICE PROCESSING",
  "LEGAL RECOVERY FROM CUSTOMER",
  "LEGAL NOTICE RECEIVED",
  "LEGAL NOTICE SENT",
  "LEGAL RECOVERY FROM COMPANY",
  "COURT ISSUED SUMMON",
  "COMPANY REPLY AWAITED",
  "REJOINDER NEED TO BE FILED",
  "AFFIDAVIT NEED TO BE FILED",
  "COMPLAINANT/PLAINTIFF EVIDENCE",
  "DEFENDANT/RESPONDENT EVIDENCE",
  "SETTLEMENT PROPOSAL SUBMISSION PENDING",
  "SETTLEMENT PROPOSAL SUBMITTED",
  "ARGUMENTS",
  "FINAL ARGUMENTS",
  "ORDER AWAITED",
  "PARTIAL SETTLED",
  "OMBUDSMAN WITH LEGAL SENT",
  "BRIEFING PENDING",
  "BRIEFING COMPLETED",
  "OMBUDSMAN WITH LEGAL PENDING",
  "LEGAL NOTICE SENT TO CUSTOMER",
  "LEGAL NOTICE SENT TO COMPANY"
];

export const omdAutomation = [
  "Ombudsman with Legal",
  "OMBUDSMAN WITH LEGAL PENDING",
  "COMPLAINT FORM SENT",
  "Ombudsman without Legal",
  "Ombudsman Pending",
  "OMBUDSMAN REQUIREMENT PENDING",
  "OMBUDSMAN REQUIREMENT PUSHED",
  "OMBUDSMAN REQUIREMENT SENT",
  "FORM 6A RECEIVED",
  "FORM 6A PUSHED",
  "FORM 6A SENT",
  "HEARING DATE RECEIVED",
  "HEARING DONE",
  "AWARD ACCEPTED",
  "AWARD REJECTED",
  "COMPANY PAYMENT PENDING",
  "OMBUDSMAN WITH LEGAL SENT",
  "BRIEFING PENDING",
  "BRIEFING COMPLETED",
  "Legal",
  "LEGAL CONTRACT PENDING",
  "LEGAL CONTRACT SIGNED",
  "LEGAL QUERY RAISED",
  "LEGAL QUERY ANSWERED",
  "Case assigned to Advocate",
  "LEGAL DRAFT FOR APPROVAL",
  "LEGAL DRAFT FOR APPROVAL WITH CLIENT",
  "COURT FILING POINTS",
  "COURT FILING PENDING BUCKET",
  "COURT FILING PENDING WITH CLIENT",
  "CASE FILING DONE",
  "LEGAL RECOVERY FROM CUSTOMER",
  "LEGAL NOTICE RECEIVED",
  "LEGAL NOTICE SENT",
  "LEGAL RECOVERY FROM COMPANY",
  "COURT ISSUED SUMMON",
  "COMPANY REPLY AWAITED",
  "REJOINDER NEED TO BE FILED",
  "AFFIDAVIT NEED TO BE FILED",
  "COMPLAINANT/PLAINTIFF EVIDENCE",
  "DEFENDANT/RESPONDENT EVIDENCE",
  "SETTLEMENT PROPOSAL SUBMISSION PENDING",
  "SETTLEMENT PROPOSAL SUBMITTED",
  "ARGUMENTS",
  "FINAL ARGUMENTS",
  "ORDER AWAITED",
  "ADMISSION",
  "MSME",
  "MSME filed",
  "Recovery suit",
  "Recovery suit filed",
  "LEGAL NOTICE SENT TO CUSTOMER",
  "LEGAL NOTICE SENT TO COMPANY"
];

export const statesWithDist = [
  {
    name: "Andhra Pradesh",
    districts: ["Select District", "Anantapur", "Chittoor", "East Godavari", "Guntur", "Krishna", "Kurnool", "Prakasam", "Srikakulam", "SriPotti Sri Ramulu Nellore", "Vishakhapatnam", "Vizianagaram", "West Godavari", "Cudappah"]
  },
  {
    name: "Arunachal Pradesh",
    districts: ["Select District", "Anjaw", "Changlang", "Dibang Valley", "East Siang", "East Kameng", "Kurung Kumey", "Lohit", "Longding", "Lower Dibang Valley", "Lower Subansiri", "Papum Pare", "Tawang", "Tirap", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"]
  },
  {
    name: "Assam",
    districts: ["Select District", "Baksa", "Barpeta", "Biswanath", "Bongaigaon", "Cachar", "Charaideo", "Chirang", "Darrang", "Dhemaji", "Dima Hasao", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Hojai", "Jorhat", "Kamrup", "Kamrup Metropolitan", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Majuli", "Morigaon", "Nagaon", "Nalbari", "Sivasagar", "Sonitpur", "Tinsukia", "Udalguri", "South Salmara-Mankachar", "West Karbi Anglong"]
  },
  {
    name: "Bihar",
    districts: ["Select District", "Araria", "Arwal", "Aurangabad", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"]
  },
  { name: "Andaman and Nicobar Island", districts: ["Select District", "Nicobar", "North and Middle Andaman", "South Andaman"] },
  { name: "Chandigarh", districts: ["Select District", "Chandigarh"] },
  { name: "Lakshadweep", districts: ["Select District", "Lakshadweep"] },
  {
    name: "Chhattisgarh",
    districts: ["Select District", "Bastar", "Balod", "Bijapur", "Baloda Bazar", "Balrampur", "Bemetara", "Bilaspur", "Dantewada", "Dhamtari", "Durg", "Jashpur", "Gariaband", "Janjgir-Champa", "Korba", "Koriya", "Kanker", "Kabirdham (formerly Kawardha)", "Kondagaon", "Mahasamund", "Mungeli", "Narayanpur", "Raigarh", "Rajnandgaon", "Raipur", "Surajpur", "Surguja", "Sukma"]
  },
  { name: "Dadra and Nagar Haveli", districts: ["Select District", "Dadra", "Nagar Haveli"] },
  { name: "Daman and Diu", districts: ["Select District", "Daman", "Diu"] },
  { name: "Delhi", districts: ["Select District", "Central Delhi", "North East Delhi", "South East Delhi", "South West Delhi", "North West Delhi", "East Delhi", "North Delhi", "South Delhi", "West Delhi", "New Delhi", "Shahdara"] },
  { name: "Goa", districts: ["Select District", "North Goa", "South Goa"] },
  {
    name: "Gujarat",
    districts: ["Select District", "Ahmedabad", "Amreli", "Anand", "Aravalli", "Banaskantha", "Bharuch", "Bhavnagar", "Botad", "Chhota Udaipur", "Dahod", "Dang", "Devbhoomi Dwarka", "Gandhinagar", "Gir Somnath", "Jamnagar", "Junagadh", "Kutch", "Kheda", "Mahisagar", "Morbi", "Mehsana", "Narmada", "Navsari", "Patan", "Panchmahal", "Porbandar", "Rajkot", "Sabarkantha", "Surendranagar", "Surat", "Tapi", "Vadodara", "Valsad"]
  },
  {
    name: "Haryana",
    districts: ["Select District", "Ambala", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gurugram", "Hissar", "Jhajjar", "Jind", "Karnal", "Kaithal", "Kurukshetra", "Mahendragarh", "Mewat", "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sirsa", "Sonipat", "Yamuna Nagar"]
  },
  { name: "Himachal Pradesh", districts: ["Select District", "Bilaspur", "Chamba", "Hamirpur", "Kangra", "Kinnaur", "Kullu", "Lahaul & Spiti", "Mandi", "Simla", "Sirmaur", "Solan", "Una"] },
  {
    name: "Jammu and Kashmir",
    districts: ["Select District", "Jammu", "Kathua", "Kishtwar", "Ramban", "Reasi", "Udhampur", "Bandipora", "Ganderbal", "Baramulla", "Budgam", "Leh", "Rajouri", "Srinagar", "Anantnag", "Doda", "Samba", "Poonch", "Kulgam", "Kupwara", "Pulwama", "Shopian", "Kargil"]
  },
  {
    name: "Jharkhand",
    districts: ["Select District", "Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridih", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamu", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela Kharsawan", "Simdega", "West Singhbhum"]
  },
  {
    name: "Karnataka",
    districts: ["Select District", "Bagalkot", "Bangalore Rural", "Bangalore Urban", "Belagavi", "Bellari", "Bidar", "Vijayapur", "Chamarajnagar", "Chikkamagaluru", "Chikkaballapur", "Chitradurga", "Davanagere", "Dharwad", "Dakshina Kannada", "Gadag", "Kalaburagi", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysore", "Raichur", "Shivamogga", "Tumakuru", "Udupi", "Uttara Kannada", "Ramanagara", "Yadgir"]
  },
  { name: "Kerala", districts: ["Select District", "Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasaragod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thrissur", "Thiruvananthapuram", "Wayanad"] },
  {
    name: "Madhya Pradesh",
    districts: ["Select District", "Agar Malwa", "Alirajpur", "Anuppur", "Ashoknagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Chhindwara", "Damoh", "Dewas", "Datia", "Dhar", "Dindori", "Gwalior", "Hoshangabad", "Harda", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Niwari", "Panna", "Raipur", "Raisen", "Ratlam", "Rajgarh", "Rewa", "Sagar", "Sidhi", "Shivpuri", "Guna", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha"]
  },
  {
    name: "Maharashtra",
    districts: ["Select District", "Ahmednagar", "Akola", "Amaravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai City", "Mumbai Suburban", "Navi Mumbai", "Nagpur", "Nanded", "Nasik", "Nandurbar", "Osmanabad", "Palghar", "Parbhani", "Pune", "Raigad", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"]
  },
  { name: "Manipur", districts: ["Select District", "Bishnupur", "Churachandpur", "Chandel", "Imphal East", "Senapati", "Tamenglong", "Thoubal", "Ukhrul", "Imphal West"] },
  { name: "Meghalaya", districts: ["Select District", "East Garo Hills", "West Garo Hills", "East Khasi Hills", "West Khasi Hills", "Jaintia Hills", "Ri Bhoi", "South Garo Hills"] },
  { name: "Mizoram", districts: ["Select District", "Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mamit", "Saiha", "Serchhip"] },
  { name: "Nagaland", districts: ["Select District", "Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunheboto", "Noklak"] },
  { name: "Odisha", districts: ["Select District", "Angul", "Boudh (Baudh)", "Balangir", "Bargarh", "Balasore (Baleswar)", "Bhadrak", "Cuttack", "Deogarh (Debagarh)", "Dhenkanal", "Gajapati", "Ganjam", "Jharsuguda", "Jajpur", "Jagatsinghapur", "Khordha", "Keonjhar (Kendujhar)", "Kalahandi", "Kandhamal", "Koraput", "Kendrapara", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nuapada", "Nayagarh", "Puri", "Rayagada", "Sambalpur", "Subarnapur (Sonepur)", "Sundargarh"] },
  { name: "Puducherry", districts: ["Select District", "Karaikal", "Mahe", "Pondicherry", "Yanam"] },
  {
    name: "Punjab",
    districts: ["Select District", "Amritsar", "Barnala", "Bathinda", "Firozpur", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Sri Muktsar Sahib", "Pathankot", "Patiala", "Rupnagar", "Sahibzada Ajit Singh Nagar", "Sangrur", "Shahid Bhagat Singh Nagar", "Tarn Taran"]
  },
  {
    name: "Rajasthan",
    districts: ["Select District", "Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Jaipur", "Dungarpur", "Hanumangarh", "Jaisalmer", "Jalor", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Pratapgarh", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur"]
  },
  { name: "Sikkim", districts: ["Select District", "East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"] },
  {
    name: "Tamil Nadu",
    districts: ["Select District", "Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Kallakurichi", "Nagapattinam", "Nilgiris", "Namakkal", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Tirupur", "Tiruchirapalli", "Theni", "Tirunelveli", "Thanjavur", "Thoothukudi", "Tiruvallur", "Tiruvannamalai", "Vellore", "Villupuram", "Viruthunagar"]
  },
  {
    name: "Telangana",
    districts: ["Select District", "Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagitial", "Jangaon", "Jayashankar Bhupalapally", "Jogulamba Gadwal", "Kamareddy", "Karimnagar", "Khammam", "Kumarambheem Asifabad", "Mahabubabad", "Mahbubnagar", "Mancherial district", "Medak", "Medchal�Malkajgiri", "Mulugu", "Nagarkurnool", "Narayanpet", "Nalgonda", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Sangareddy", "Ranga Reddy", "Siddipet", "Suryapet", "Vikarabad", "Wanaparthy", "Warangal Rural", "Warangal Urban", "Yadadri Bhuvanagiri"]
  },
  { name: "Tripura", districts: ["Select District", "Dhalai", "North Tripura", "South Tripura", "West Tripura"] },
  {
    name: "Uttar Pradesh",
    districts: ["Select District", "Agra", "Allahabad", "Aligarh", "Ambedkar Nagar", "Auraiya", "Azamgarh", "Barabanki", "Budaun", "Bagpat", "Bahraich", "Bijnor", "Ballia", "Banda", "Balrampur", "Bareilly", "Basti", "Bulandshahr", "Chandauli", "Chhatrapati Shahuji Maharaj Nagar", "Chitrakoot", "Deoria", "Etah", "Kanshi Ram Nagar", "Etawah", "Firozabad", "Farrukhabad", "Fatehpur", "Faizabad", "Gautam Buddh Nagar", "Gonda", "Ghazipur", "Gorakhpur", "Ghaziabad", "Hamirpur", "Hardoi", "Mahamaya Nagar", "Jhansi", "Jalaun", "Jyotiba Phule Nagar", "Jaunpur district", "Ramabai Nagar (Kanpur Dehat)", "Kannauj", "Kanpur", "Kaushambi", "Kushinagar", "Lalitpur", "Lakhimpur Kheri", "Lucknow", "Mau", "Meerut", "Maharajganj", "Mahoba", "Mirzapur", "Moradabad", "Mainpuri", "Mathura", "Muzaffarnagar", "Panchsheel Nagar district (Hapur)", "Pilibhit", "Shamli", "Pratapgarh", "Rampur", "Raebareli", "Saharanpur", "Sitapur", "Shahjahanpur", "Sant Kabir Nagar", "Siddharthnagar", "Sonbhadra", "Sant Ravidas Nagar", "Sultanpur", "Shravasti", "Unnao", "Varanasi"]
  },
  { name: "Uttarakhand", districts: ["Select District", "Almora", "Bageshwar", "Chamoli", "Champawat", "Dehra Dun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"] },
  {
    name: "West Bengal",
    districts: ["Select District", "Alipurduar", "Bankura", "Jhargram", "Birbhum", "Cooch Behar", "Dakshin Dinajpur", "Darjeeling", "Hooghly", "Howrah", "Jalpaiguri", "Kalimpong", "Kolkata", "Maldah", "Murshidabad", "Nadia", "North 24 Parganas", "Paschim Medinipur", "Paschim Bardhaman", "Purba Bardhaman", "Purba Medinipur", "Purulia", "South 24 Parganas", "Uttar Dinajpur"]
  }
];

//Whatsappbot source
export const botSrcKeys = ["yellowmessenger", "Whatsapp Bot", "Web Bot", "Facebook Bot", "Instagram Bot"];

//Life Insurance complaint type ids
export const COMP_TYPE_LIFE_INS_MISSELLING = "f53e05b1-df2f-4012-a9ef-0f31b76ac418";
export const COMP_TYPE_LIFE_INS_DEATH_CLAIM_DELAY = "9ac53f4c-c946-4034-9195-34cbaa97dd9e";
export const COMP_TYPE_LIFE_INS_DEATH_CLAIM_REJECT = "933645b0-6306-41a2-a0e0-14a07e6e7023";

//Health Insurance complaint type ids
export const COMP_TYPE_HEALTH_INS_CLAIM_DELAY = "035fee5e-0c35-49e8-9ffb-7bf3e2fd2d18";
export const COMP_TYPE_HEALTH_INS_CLAIM_REJECT = "7b0f97f9-ca77-4ef1-bdc9-fec05a7fbb56";
export const COMP_TYPE_HEALTH_INS_CLAIM_SHORTSETTLE = "0e9aefb5-22e0-4d89-aa78-513ad9cc701f";

// Insurance type ids
export const INS_TYPE_HEALTH = "3030b68f-82e9-4dfb-b2b8-82b743d0692a";
export const INS_TYPE_LIFE = "864a375b-5fa7-49c7-9f39-2b1fda4674b5";
export const INS_TYPE_GENERAL = "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2";

//Policy types
export const POL_TYPE_HEALTH = ["Individual", "Family", "Group", "Bancassurance", "Others"];
export const POL_TYPE_LIFE = ["Term Insurance", "Endowment", "ULIP", "Moneyback", "Annuity/Pension Plan", "Whole Life Policy", "Others"];
export const POL_TYPE_GENERAL = ["Motor Theft", "Travel", "Fidelity", "Marine", "Property", "Fire", "Theft", "Home Insurance", "Motor Accident", "Personal Accident", "Critical Illness", "Workmen Compensation", "Misseling & Fraud", "Others"];

//filtration disable status
export const FILTRATION_DISABLE_STATUSES = ["THIRD_PARTY_ACCEPTED", "EXPERT", "ACCEPTED", "REGISTERED"];
export const FILTRATION_RELATIONS = ["Mother", "Father", "Son", "Brother", "Sister", "Daughter", "Husband", "Wife", "Grandfather", "Grandmother", "Niece", "Nephew", "Uncle", "Aunt", "Mother-in-law", "Father-in-law", "Others"];
export const EDIT_FILTRATION_DISABLE_STATUSES = ["DOC UPLOAD PENDING", "DOC VERIFICATION PENDING", "EXPERT"];

//Hi filtration bucket
export const HI_FILTRATION_BUCKET = [
  { name: "Claim is delayed", _id: "035fee5e-0c35-49e8-9ffb-7bf3e2fd2d18" },
  { name: "Claim is rejected", _id: "7b0f97f9-ca77-4ef1-bdc9-fec05a7fbb56" },
  { name: "Claim Is Shortsettled", _id: "0e9aefb5-22e0-4d89-aa78-513ad9cc701f" }
];

export const checkHolidayDates = (date) => {
  let holidayList = ["2021-10-02", "2021-11-04", "2021-11-19", "2021-12-25"];
  let newDate = new Date(date);
  let check = false;
  if (newDate.getDay() == 0 || holidayList.includes(date)) {
    check = true;
  }
  return check;
};

export const incomeValue = [
  // { label : "No Income", value :"No Income"},
  { label: "Up to 50,000", value: "Up to 50,000" },
  { label: "50,001-1,00,000", value: "50,000-1,00,000" },
  { label: "1,00,001-2,00,000", value: "1,00,001-2,00,000" },
  { label: "2,00,001-3,00,000", value: "2,00,001-3,00,000" },
  { label: "3,00,001-4,00,000", value: "3,00,001-4,00,000" },
  { label: "4,00,001-5,00,000", value: "4,00,001-5,00,000" },
  { label: "Above 5 lacs", value: "Above 5 lacs" }
];

export const typeOfProcessHCR = [
  { label: "Yes, my claim got rejected", value: "Yes, my claim got rejected" },
  { label: "No, it was fully settled", value: "No, it was fully settled" },
  { label: "No, it was partially settled", value: "No, it was partially settled" },
  { label: "I did not apply for Cashless", value: "I did not apply for Cashless" },
  { label: "I did not know about Cashless", value: "I did not know about Cashless" },
  { label: "There was no option for Cashless", value: "There was no option for Cashless" }
];

export const typeOfReimbursementValue = [
  { label: "Hospitalisation Expenses", value: "Hospitalisation Expenses" },
  { label: "Pre-Hospitalisation Expenses", value: "Pre-Hospitalisation Expenses" },
  { label: "Post Hospitalisation Expenses", value: "Post Hospitalisation Expenses" },
  { label: "Pre & Post Hospitalisation Expenses", value: "Pre & Post Hospitalisation Expenses" },
  { label: "Day Care Treatment Expense", value: "Day Care Treatment Expense" }
];

export const typeOfReimbursementValueArr = ["Hospitalisation Expenses", "Pre-Hospitalisation Expenses", "Post Hospitalisation Expenses", "Pre & Post Hospitalisation Expenses", "Day Care Treatment Expense"];

export const typeOfAdmission = [
  {
    id: 0,
    value: "Emergency",
    selected: false,
    label: "Emergency"
  },
  {
    id: 1,
    value: "Planned",
    label: "Planned",
    selected: false
  },
  {
    id: 2,
    value: "Day Care",
    label: "Day Care",
    selected: false
  },
  {
    id: 3,
    value: "Maternity",
    label: "Maternity",
    selected: false
  }
];

export const roomCategory = [
  {
    id: 0,
    value: "Day Care",
    label: "Day Care",
    selected: false
  },
  {
    id: 1,
    value: "Single Occupancy",
    label: "Single Occupancy",
    selected: false
  },
  {
    id: 2,
    value: "Twin sharing",
    label: "Twin sharing",
    selected: false
  },
  {
    id: 3,
    value: "3 or more beds per room",
    label: "3 or more beds per room",
    selected: false
  }
];

export const hospitalisationDueTo = [
  {
    id: 0,
    value: "Injury",
    label: "Injury",
    selected: false
  },
  {
    id: 1,
    value: "Illness",
    label: "Illness",
    selected: false
  },
  {
    id: 2,
    value: "Maternity",
    label: "Maternity",
    selected: false
  }
];

export const statusOfDischarge = [
  {
    id: 0,
    value: "Discharge to home",
    label: "Discharge to home",
    selected: false
  },
  {
    id: 1,
    value: "Deceased",
    label: "Deceased",
    selected: false
  },
  {
    id: 2,
    value: "Discharge to another",
    label: "Discharge to another",
    selected: false
  }
];

export const mockData = [
  {
    label: "Signed Claim Form",
    value: "Signed Claim Form"
  },
  {
    label: "Hospital Discharge Summary",
    value: "Hospital Discharge Summary"
  },
  {
    label: "Hospital Main Bill",
    value: "Hospital Main Bill"
  },
  {
    label: "Bill Payment Receipts",
    value: "Bill Payment Receipts"
  },
  {
    label: "Cancelled Cheque",
    value: "Cancelled Cheque"
  },
  {
    label: "Copy of photo ID",
    value: "Copy of photo ID"
  },
  {
    label: "Pharmacy Bills",
    value: "Pharmacy Bills"
  },
  {
    label: "Hospital Bill break-up",
    value: "Hospital Bill break-up"
  },
  {
    label: "Indoor Case papers",
    value: "Indoor Case papers"
  },
  {
    label: `Doctor's Prescriptions`,
    value: `Doctor's Prescriptions`
  },
  {
    label: "Doctor’s request for investigation",
    value: "Doctor’s request for investigation"
  },
  {
    label: "Investigation Reports (Including CT/MRI/USG/HPE)",
    value: "Investigation Reports (Including CT/MRI/USG/HPE)"
  },
  {
    label: "Copy of Claim Intimation Letter",
    value: "Copy of Claim Intimation Letter"
  },
  {
    label: "MLC (Medico Legal Case) Report",
    value: "MLC (Medico Legal Case) Report"
  },
  {
    label: "Police FIR",
    value: "Police FIR"
  },
  {
    label: "Operation Theatre Notes",
    value: "Operation Theatre Notes"
  },
  {
    label: "ECG",
    value: "ECG"
  },
  {
    label: "Others",
    value: "Others"
  }
];

export const relationshipArr = [
  {
    value: "Self"
  },
  {
    value: "Spouse"
  },
  {
    value: "Child"
  },
  {
    value: "Father"
  },
  {
    value: "Mother"
  },
  {
    value: "Others"
  }
];

export const hcrOccupation = [
  // {label : "Small business Owner", value :"Small business Owner"},
  { label: "Service", value: "Service" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Homemaker", value: "Homemaker" },
  { label: "Retired", value: "Retired" },
  { label: "Student", value: "Student" },
  // {label : "Serviceman/Professional", value:"Serviceman/Professional"},
  // {label:"Armed Forces", value:"Armed Forces"},
  // {label :"Farmer", value :"Farmer"},
  // {label : "Shopkeeper", value :"Shopkeeper"},
  // {label : "Businessman", value :"Businessman"},
  // {label : "Housewife", value :"Housewife"},
  // {label : "Retired Personnel", value : "Retired Personnel"},
  // {label : "Retired from Army", value : "Retired from Army"},
  // {label :"Unemployed", value : "Unemployed"},
  // {label : "Freelancer", value :"Freelancer"},
  // {label : "Government Job", value : "Government Job"},
  // {label : "Daily Wager", value :"Daily Wager"},
  { label: "Others", value: "Others" }
];

export const cashBenefitOptions = [
  {
    value: "Hospital Daily Cash"
  },
  {
    value: "Surgical Cash"
  },
  {
    value: "Critical Illness Benefit"
  },
  {
    value: "Convalescence"
  },
  {
    value: "Pre/Post hospitalization Lump sum benefit"
  },
  {
    value: "Others"
  }
];

export const HCRSubmittedViaOptions = [
  {
    label: "Customer Courier",
    value: "Customer Courier"
  },
  {
    label: "InSa Submitted",
    value: "InSa Submitted"
  },
  {
    label: "Self – drop",
    value: "Self – drop"
  },
  {
    label: "Online Submission",
    value: "Online Submission"
  }
];

export const HCRConstants = {
  tableHeadings: ["Lead ID", "Lead Source", "Med", "Name", "Mobile No.", "Email", "Status", "Insurance Type", "Insurance Company", "Claim Amount", "Assign to", "Reason for Reimbursement", "Type of Reimbursement", "Polifyx Form Submission Date"],
  tableDataKeys: ["leadId", "src", "med", "name", "phone", "email", "status", "insuranceTypeId.name", "insuranceCompanyId.name", "claimAmountRange", "assignTo", "reimbursementReason", "typeOfReimbursment", "polifyxDate"],
  addIVRFormValues: {
    name: "",
    phone: "",
    email: "",
    insuranceTypeId: "",
    claimAmountRange: "",
    src: "IVR",
    insuranceCompanyId: ""
  },
  statusChangeFormValues: {
    status: "",
    additionalData: {
      dateOfMovement: "",
      formSubmissionDate: "",
      submittedVia: "",
      dateOfSettlement: "",
      dateOfRejection: "",
      dateOfLastCommWithInsCompany: "",
      interestedInRegisterGrievanceWithInsCompany: "",
      followUpDate: "",
      didTheJourneyOfHCRWithUs: "",
      complaintTypeId: "",
      description: "",
      incorrectHcrLeadReason: "",
      currentDate: moment().format("YYYY-MM-DD")
    }
  }
};

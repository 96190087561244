export const GET_ACTIVE_USER = "GET_ACTIVE_USER";
export const GET_ACTIVE_USER_SUCCESS = "GET_ACTIVE_USER_SUCCESS";
export const GET_ACTIVE_USER_FAILED = "GET_ACTIVE_USER_FAILED";

export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILED = "GET_STATES_FAILED";

export const ALL_LEAD_BUCKET = "ALL_LEAD_BUCKET";
export const ALL_LEAD_BUCKET_SUCCESS = "ALL_LEAD_BUCKET_SUCCESS";
export const TODAY_LEAD_BUCKET = "TODAY_LEAD_BUCKET";
export const TODAY_LEAD_BUCKET_SUCCESS = "TODAY_LEAD_BUCKET_SUCCESS";
export const TODAY_LEAD_BUCKET_FAILED = "TODAY_LEAD_BUCKET_FAILED";
export const MONTHLY_LEAD_BUCKET = "MONTHLY_LEAD_BUCKET";
export const MONTHLY_LEAD_BUCKET_SUCCESS = "MONTHLY_LEAD_BUCKET_SUCCESS";
export const MONTHLY_LEAD_BUCKET_FAILED = "MONTHLY_LEAD_BUCKET_FAILED";
export const LEAD_EXPERT_BUCKET = "LEAD_EXPERT_BUCKET";
export const LEAD_EXPERT_BUCKET_SUCCESS = "LEAD_EXPERT_BUCKET_SUCCESS";
export const LEAD_EXPERT_BUCKET_FAILED = "LEAD_EXPERT_BUCKET_FAILED";
export const LEAD_BUCKET_COUNT = "LEAD_BUCKET_COUNT";
export const LEAD_BUCKET_COUNT_SUCCESS = "LEAD_BUCKET_COUNT_SUCCESS";
export const LEAD_BUCKET_COUNT_FAILED = "LEAD_BUCKET_COUNT_FAILED";

export const COMPLAINT_DASHBOARD = "COMPLAINT_DASHBOARD";
export const COMPLAINT_DASHBOARD_SUCCESS = "COMPLAINT_DASHBOARD_SUCCESS";
export const COMPLAINT_DASHBOARD_FAILED = "COMPLAINT_DASHBOARD_FAILED";
export const B2C_REGISTRATION = "B2C_REGISTRATION";
export const B2C_REGISTRATION_SUCCESS = "B2C_REGISTRATION_SUCCESS";
export const B2C_REGISTRATION_FAILED = "B2C_REGISTRATION_FAILED";
export const B2C_REGISTRATION_CASES = "B2C_REGISTRATION_CASES";
export const B2C_REGISTRATION_CASES_SUCCESS = "B2C_REGISTRATION_CASES_SUCCESS";
export const B2C_REGISTRATION_CASES_FAILED = "B2C_REGISTRATION_CASES_FAILED";
export const PARTNER_REGISTRATION = "PARTNER_REGISTRATION";
export const PARTNER_REGISTRATION_SUCCESS = "PARTNER_REGISTRATION_SUCCESS";
export const PARTNER_REGISTRATION_FAILED = "PARTNER_REGISTRATION_FAILED";

export const B2C_RESOLUTION = "B2C_RESOLUTION";
export const B2C_RESOLUTION_SUCCESS = "B2C_RESOLUTION_SUCCESS";
export const B2C_RESOLUTION_FAILED = "B2C_RESOLUTION_FAILED";
export const PARTNER_RESOLUTION = "PARTNER_RESOLUTION";
export const PARTNER_RESOLUTION_SUCCESS = "PARTNER_RESOLUTION_SUCCESS";
export const PARTNER_RESOLUTION_FAILED = "PARTNER_RESOLUTION_FAILED";
export const B2C_SATTLED = "B2C_SATTLED";
export const B2C_SATTLED_SUCCESS = "B2C_SATTLED_SUCCESS";
export const B2C_SATTLED_FAILED = "B2C_SATTLED_FAILED";
export const PARTNER_SATTLED_CASES = "PARTNER_SATTLED_CASES";
export const PARTNER_SATTLED_CASES_SUCCESS = "PARTNER_SATTLED_CASES_SUCCESS";
export const PARTNER_SATTLED_CASES_FAILED = "PARTNER_SATTLED_CASES_FAILED";
export const B2C_INVOICE_RAISED = "B2C_INVOICE_RAISED";
export const B2C_INVOICE_RAISED_SUCCESS = "B2C_INVOICE_RAISED_SUCCESS";
export const B2C_INVOICE_RAISED_FAILED = "B2C_INVOICE_RAISED_FAILED";
export const PARTNER_INVOICE = "PARTNER_INVOICE";
export const PARTNER_INVOICE_SUCCESS = "PARTNER_INVOICE_SUCCESS";
export const PARTNER_INVOICE_FAILED = "PARTNER_INVOICE_FAILED";

export const LEGAL_PARTNER_DASHBOARD = "LEGAL_PARTNER_DASHBOARD";
export const LEGAL_PARTNER_DASHBOARD_SUCCESS = "LEGAL_PARTNER_DASHBOARD_SUCCESS";
export const LEGAL_PARTNER_DASHBOARD_FAILED = "LEGAL_PARTNER_DASHBOARD_FAILED";

export const OMBUDSMAN_BRIEFING = "OMBUDSMAN_BRIEFING";
export const OMBUDSMAN_BRIEFING_SUCCESS = "OMBUDSMAN_BRIEFING_SUCCESS";
export const OMBUDSMAN_BRIEFING_FAILED = "OMBUDSMAN_BRIEFING_FAILED";

export const B2C_OMBUDSMAN_COUNT = "B2C_OMBUDSMAN_COUNT";
export const B2C_OMBUDSMAN_COUNT_SUCCESS = "B2C_OMBUDSMAN_COUNT_SUCCESS";
export const B2C_OMBUDSMAN_COUNT_FAILED = "B2C_OMBUDSMAN_COUNT_FAILED";
export const PARTNER_OMBUDSMAN_COUNT = "PARTNER_OMBUDSMAN_COUNT";
export const PARTNER_OMBUDSMAN_COUNT_SUCCESS = "PARTNER_OMBUDSMAN_COUNT_SUCCESS";
export const PARTNER_OMBUDSMAN_COUNT_FAILED = "PARTNER_OMBUDSMAN_COUNT_FAILED";
export const NEW_OMBUDSMAN_COUNT_B2C = "NEW_OMBUDSMAN_COUNT_B2C";
export const NEW_OMBUDSMAN_COUNT_B2C_SUCCESS = "NEW_OMBUDSMAN_COUNT_B2C_SUCCESS";
export const NEW_OMBUDSMAN_COUNT_B2C_FAILED = "NEW_OMBUDSMAN_COUNT_B2C_FAILED";
export const NEW_OMBUDSMAN_COUNT_PARTNER = "NEW_OMBUDSMAN_COUNT_PARTNER";
export const NEW_OMBUDSMAN_COUNT_PARTNER_SUCCESS = "NEW_OMBUDSMAN_COUNT_PARTNER_SUCCESS";
export const NEW_OMBUDSMAN_COUNT_PARTNER_FAILED = "NEW_OMBUDSMAN_COUNT_PARTNER_FAILED";
export const OMBUDSMAN_RESEND_CASES_B2C = "OMBUDSMAN_RESEND_CASES_B2C";
export const OMBUDSMAN_RESEND_CASES_B2C_SUCCESS = "OMBUDSMAN_RESEND_CASES_B2C_SUCCESS";
export const OMBUDSMAN_RESEND_CASES_B2C_FAILED = "OMBUDSMAN_RESEND_CASES_B2C_FAILED";
export const OMBUDSMAN_RESEND_CASES_PARTNER = "OMBUDSMAN_RESEND_CASES_PARTNER";
export const OMBUDSMAN_RESEND_CASES_PARTNER_SUCCESS = "OMBUDSMAN_RESEND_CASES_PARTNER_SUCCESS";
export const OMBUDSMAN_RESEND_CASES_PARTNER_FAILED = "OMBUDSMAN_RESEND_CASES_PARTNER_FAILED";

export const B2C_MAILING_COUNT = "B2C_MAILING_COUNT";
export const B2C_MAILING_COUNT_SUCCESS = "B2C_MAILING_COUNT_SUCCESS";
export const B2C_MAILING_COUNT_FAILED = "B2C_MAILING_COUNT_FAILED";
export const PARTNER_MAILING_COUNT = "PARTNER_MAILING_COUNT";
export const PARTNER_MAILING_COUNT_SUCCESS = "PARTNER_MAILING_COUNT_SUCCESS";
export const PARTNER_MAILING_COUNT_FAILED = "PARTNER_MAILING_COUNT_FAILED";

export const DASHBOARD_GET_EXPERTS_LEADS = "DASHBOARD_GET_EXPERTS_LEADS";
export const DASHBOARD_GET_EXPERTS_LEADS_SUCCESS = "DASHBOARD_GET_EXPERTS_LEADS_SUCCESS";
export const DASHBOARD_GET_EXPERTS_LEADS_FAILED = "DASHBOARD_GET_EXPERTS_LEADS_FAILED";

export const DASHBOARD_GET_EXPERT_PARTNER_LEADS = "DASHBOARD_GET_EXPERT_PARTNER_LEADS";
export const DASHBOARD_GET_EXPERT_PARTNER_LEADS_SUCCESS = "DASHBOARD_GET_EXPERT_PARTNER_LEADS_SUCCESS";
export const DASHBOARD_GET_EXPERT_PARTNER_LEADS_FAILED = "DASHBOARD_GET_EXPERT_PARTNER_LEADS_FAILED";

export const PARTNER_LEAD_BUCKET_COUNT = "PARTNER_LEAD_BUCKET_COUNT";
export const PARTNER_LEAD_BUCKET_COUNT_SUCCESS = "PARTNER_LEAD_BUCKET_COUNT_SUCCESS";
export const PARTNER_LEAD_BUCKET_COUNT_FAILED = "PARTNER_LEAD_BUCKET_COUNT_FAILED";

export const DASHBOARD_EXECUTIVE_PRODUCTIVITY = "DASHBOARD_EXECUTIVE_PRODUCTIVITY";
export const DASHBOARD_EXECUTIVE_PRODUCTIVITY_SUCCESS = "DASHBOARD_EXECUTIVE_PRODUCTIVITY_SUCCESS";
export const DASHBOARD_EXECUTIVE_PRODUCTIVITY_FAILED = "DASHBOARD_EXECUTIVE_PRODUCTIVITY_FAILED";

export const INVOICE_PROCESSING_START = "INVOICE_PROCESSING_START";
export const INVOICE_PROCESSING_SUCCESS = "INVOICE_PROCESSING_SUCCESS";
export const INVOICE_PROCESSING_FAILED = "INVOICE_PROCESSING_FAILED";

export const GET_HCR_ALL_STATUS_DATA = "GET_HCR_ALL_STATUS_DATA";
export const GET_HCR_ALL_STATUS_DATA_SUCCESS = "GET_HCR_ALL_STATUS_DATA_SUCCESS";
export const GET_HCR_ALL_STATUS_DATA_FAILED = "GET_HCR_ALL_STATUS_DATA_FAILED";

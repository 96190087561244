import axios from "./axios.services";
import { partnertoken, servicePath } from "constants/defaultValues";
import { KNOWLARITY_CONFIGS, log, toast } from "helpers/Utils";
import { baseURLs } from "./apisURLs.services";

const authorizedUser = () => {
  let user = null;
  try {
    user = localStorage.getItem("insa_accessor") != null ? JSON.parse(localStorage.getItem("insa_accessor")) : null;
    const insa_agentToken = localStorage.getItem("insa_agentToken") != null ? JSON.parse(localStorage.getItem("insa_agentToken")) : null;
    user = { ...user, insa_agentToken };
  } catch (error) {
    log(">>>>: src/helpers/Utils.js  : insa_accessor -> error", error);
    user = null;
  }
  return user;
};

const docUploadUserAuth = () => {
  let user = null;
  try {
    user = localStorage.getItem("doc_upload_accessor") != null ? JSON.parse(localStorage.getItem("doc_upload_accessor")) : null;
  } catch (error) {
    user = null;
  }
  return user;
};

export const getHeaders = (isMultiPart = false) => {
  let user = authorizedUser();

  const headers = {
    Authorization: `${user?.data?.token || user?.token}`,
    "Content-Type": isMultiPart ? "multipart/form-data" : "application/json",
    usertype: user.data?.userType == "admin" ? "admin" : "executive"
  };
  return headers;
};

async function request(method, url, data) {
  return axios
    .request({
      method,
      url,
      data,
      headers: getHeaders()
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response?.data?.message === "Please login again.") {
        toast("", "You have been logged out, Please login again !", "error");
      }
      return err.response.data;
    });
}

async function hcrRequest(method, url, data) {
  let user = authorizedUser();

  const headers = {
    Authorization: `${user?.data?.token || user?.token}`,
    "Content-Type": "application/json",
    usertype: user.data?.userType == "admin" ? "admin" : "executive"
  };

  return await axios
    .request({
      method,
      url,
      data,
      headers: headers
    })
    .then((res) => res.data);
}

async function partnerRequest(method, url, data) {
  return await axios.request({
    method,
    url,
    data,
    headers: {
      Authorization: `Bearer ${partnertoken}`
    }
  });
}

// for regular serive request
const regularRequest = async (method, apiEndpoint, data) => {
  return await request(method, baseURLs.regular + apiEndpoint, data);
};

// for logout requests
async function logoutRequest(method, url, data) {
  let user = authorizedUser();
  return await axios
    .request({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${user?.token || user?.insa_agentToken}`,
        usertype: user?.data?.userType === "admin" ? "admin" : "executive"
      }
    })
    .then((res) => res.data);
}

// for requests with bearer token
async function bearerRequest(method, url, data) {
  let user = authorizedUser();
  return await axios
    .request({
      method,
      url,
      data,
      headers: {
        Authorization: `Bearer ${user.token || user?.data?.token || user?.insa_agentToken}`,
        usertype: user?.data?.userType === "admin" ? "admin" : "executive"
      }
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response?.data?.message === "Error while verifying token") {
        toast("", "You have been logged out, Please login again !", "error");
      }
    });
}

async function knowlarityPostApi(method, url, data) {
  return await axios.request({
    method,
    url: `${KNOWLARITY_CONFIGS.baseUrl}/${KNOWLARITY_CONFIGS.channel}/v1/${url}`,
    data,
    headers: {
      Authorization: `${KNOWLARITY_CONFIGS.Authorization}`,
      "x-api-key": KNOWLARITY_CONFIGS["x-api-key"]
    }
  });
}

async function servetelAPI(method, url, data) {
  let user = authorizedUser();
  return await axios.request({
    method,
    url: `${servicePath}/servetel/click-to-call`,
    data,
    headers: {
      Authorization: `${user?.data?.token || user?.token}`
    }
  });
}

async function docUploadRequest(method, url, data, headers = {}) {
  let user = docUploadUserAuth();

  return await axios
    .request({
      method,
      url,
      data,
      // timeout: 1000 * 30,
      headers: {
        Authorization: user?.token || "",
        ...headers
      }
    })
    .then((res) => res.data);
}

export { regularRequest, bearerRequest, request, knowlarityPostApi, partnerRequest, servetelAPI, docUploadRequest, hcrRequest, logoutRequest };

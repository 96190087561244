import { toast } from "helpers/Utils";
import { ADD_DROPDOWN_LIST, ADD_DROPDOWN_LIST_FAILURE, ADD_DROPDOWN_LIST_SUCCESS, GET_DROPDOWN_LIST, GET_DROPDOWN_LIST_FAILURE, GET_DROPDOWN_LIST_SUCCESS, UPDATE_DROPDOWN_LIST, UPDATE_DROPDOWN_LIST_FAILURE, UPDATE_DROPDOWN_LIST_SUCCESS } from "./Action";

const initialState = {
  dropdownList: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DROPDOWN_LIST:
      return { ...state, loading: true };
    case GET_DROPDOWN_LIST_SUCCESS:
      return { ...state, dropdownList: action.data, loading: false };
    case GET_DROPDOWN_LIST_FAILURE:
      toast("Failed fetch list !", "", "error");
      return { ...state, dropdownList: [], loading: false };
    case ADD_DROPDOWN_LIST:
      return { ...state, loading: true };
    case ADD_DROPDOWN_LIST_SUCCESS:
      toast("Dropdown added successfully !", "", "success");
      return { ...state, loading: false };
    case ADD_DROPDOWN_LIST_FAILURE:
      toast("Failed to add !", "", "error");
      return { ...state, loading: false };
    case UPDATE_DROPDOWN_LIST:
      return { ...state, loading: true };
    case UPDATE_DROPDOWN_LIST_SUCCESS:
      toast("Dropdown updated successfully !", "", "success");
      return { ...state, loading: false };
    case UPDATE_DROPDOWN_LIST_FAILURE:
      toast("Failed to update !", "", "error");
      return { ...state, loading: false };

    default:
      return state;
  }
}

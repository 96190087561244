import { NotificationManager } from "components/common/react-notifications";
import {
  CUSTOMER_ADD_COMMENT,
  CUSTOMER_ADD_COMMENT_FAILED,
  CUSTOMER_ADD_COMMENT_SUCCESS,
  CUSTOMER_ADD_COMM_COMMENT,
  CUSTOMER_ADD_COMM_COMMENT_FAILED,
  CUSTOMER_ADD_COMM_COMMENT_SUCCESS,
  CUSTOMER_ADMIN_ISSUED_DOC,
  CUSTOMER_ADMIN_ISSUED_DOC_FAILED,
  CUSTOMER_ADMIN_ISSUED_DOC_SUCCESS,
  CUSTOMER_ADMIN_SELF_DOC,
  CUSTOMER_ADMIN_SELF_DOC_FAILED,
  CUSTOMER_ADMIN_SELF_DOC_SUCCESS,
  CUSTOMER_BOT_FOLLOW_UP,
  CUSTOMER_BOT_FOLLOW_UP_FAILED,
  CUSTOMER_BOT_FOLLOW_UP_SUCCESS,
  CUSTOMER_DOWNLOAD_REPORT,
  CUSTOMER_DOWNLOAD_REPORT_FAILED,
  CUSTOMER_DOWNLOAD_REPORT_SUCCESS,
  CUSTOMER_FILTER_DATA,
  CUSTOMER_FILTER_DATA_FAILED,
  CUSTOMER_FILTER_DATA_SUCCESS,
  CUSTOMER_GET_PASSWORD_RATE,
  CUSTOMER_GET_PASSWORD_RATE_FAILED,
  CUSTOMER_GET_PASSWORD_RATE_SUCCESS,
  CUSTOMER_GET_USER_LIST,
  CUSTOMER_GET_USER_LIST_FAILED,
  CUSTOMER_GET_USER_LIST_SUCCESS,
  CUSTOMER_GMAIL_READ,
  CUSTOMER_GMAIL_READ_FAILED,
  CUSTOMER_GMAIL_READ_SUCCESS,
  CUSTOMER_UNBLOCK_DATA_FAILED,
  CUSTOMER_UNBLOCK_DATA_SUCESS,
  CUSTOMER_UPDATE_DATA,
  CUSTOMER_UPDATE_DATA_FAILED,
  CUSTOMER_UPDATE_DATA_SUCCESS,
  CUSTOMER_UPDATE_SERVICE_RATE,
  CUSTOMER_UPDATE_SERVICE_RATE_FAILED,
  CUSTOMER_UPDATE_SERVICE_RATE_SUCCESS,
  CUSTOMER_USER_DOC_LIST,
  CUSTOMER_USER_DOC_LIST_FAILED,
  CUSTOMER_USER_DOC_LIST_SUCCESS,
  CUSTOMER_VIEW_PASSWORD,
  CUSTOMER_VIEW_PASSWORD_FAILED,
  CUSTOMER_VIEW_PASSWORD_SUCCESS
} from "./Action";

const initialState = {
  userList: {},
  message: "",
  viewPassword: {},
  viewMailMessage: "",
  issuerDoc: [],
  selfDoc: [],
  userDoc: [],
  loading: "",
  reportData: [],
  customerPwd: {}
};



export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_GET_USER_LIST:
      return { ...state, loading: true };

    case CUSTOMER_GET_USER_LIST_SUCCESS:
      return { ...state, userList: action.data, loading: false };

    case CUSTOMER_GET_USER_LIST_FAILED:
      return { ...state, loading: false };

    case CUSTOMER_FILTER_DATA:
      return { ...state, loading: true };

    case CUSTOMER_FILTER_DATA_SUCCESS:
      return { ...state, userList: action.data, loading: false };

    case CUSTOMER_FILTER_DATA_FAILED:
      return { ...state, message: action.message, loading: false };

    case CUSTOMER_VIEW_PASSWORD:
      return { ...state };

    case CUSTOMER_VIEW_PASSWORD_SUCCESS:
      return { ...state, viewPassword: action.data };

    case CUSTOMER_VIEW_PASSWORD_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_UPDATE_SERVICE_RATE:
      return { ...state };

    case CUSTOMER_UPDATE_SERVICE_RATE_SUCCESS:
      return { ...state, message: action.message };

    case CUSTOMER_UPDATE_SERVICE_RATE_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_GMAIL_READ:
      return { ...state };

    case CUSTOMER_GMAIL_READ_SUCCESS:
      return { ...state, viewMailMessage: action.message };

    case CUSTOMER_GMAIL_READ_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_ADMIN_ISSUED_DOC:
      return { ...state };

    case CUSTOMER_ADMIN_ISSUED_DOC_SUCCESS:
      return { ...state, issuerDoc: action.data };

    case CUSTOMER_ADMIN_ISSUED_DOC_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_ADMIN_SELF_DOC:
      return { ...state };

    case CUSTOMER_ADMIN_SELF_DOC_SUCCESS:
      return { ...state, selfDoc: action.data };

    case CUSTOMER_ADMIN_SELF_DOC_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_USER_DOC_LIST:
      return { ...state };

    case CUSTOMER_USER_DOC_LIST_SUCCESS:
      return { ...state, userDoc: action.data };

    case CUSTOMER_USER_DOC_LIST_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_ADD_COMMENT:
      return { ...state };

    case CUSTOMER_ADD_COMMENT_SUCCESS:
      // toast("Comment Added Successfully")
      return { ...state, message: action.message };

    case CUSTOMER_ADD_COMMENT_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_DOWNLOAD_REPORT:
      return { ...state };

    case CUSTOMER_DOWNLOAD_REPORT_SUCCESS:
      if (action.data?.success) {
        NotificationManager.success("Report Sent Success !", action.data?.message, 3000, null, null, "filled");
      }
      return { ...state, reportData: action.data };

    case CUSTOMER_DOWNLOAD_REPORT_FAILED:
      return { ...state, message: action.message };

    case CUSTOMER_UPDATE_DATA:
      return { ...state };

    case CUSTOMER_UPDATE_DATA_SUCCESS:
      if (action.data?.success) {
        NotificationManager.success("Data Updated !", action.data?.message, 3000, null, null, "filled");
      } else {
        NotificationManager.success(" Something went wrong !", action.data?.message, 3000, null, null, "filled");
      }
      return { ...state };

    case CUSTOMER_UPDATE_DATA_FAILED:
      if (!action.data?.success) {
        NotificationManager.error("Something went wrong !", "Please try again !", 3000, null, null, "filled");
      }
      return { ...state };

    case CUSTOMER_UNBLOCK_DATA_SUCESS:
      NotificationManager.success("Unblocked User And ACtivated User !", action.data?.message, 3000, null, null, "filled");

      return { ...state, loading: true };

    case CUSTOMER_UNBLOCK_DATA_FAILED:
      NotificationManager.error("Something went wrong !", "Please try again !", 3000, null, null, "filled");
      return { ...state, loading: false };
    // @.........................................

    case CUSTOMER_GET_PASSWORD_RATE:
      return { ...state };

    case CUSTOMER_GET_PASSWORD_RATE_SUCCESS:
      return { ...state, customerPwd: action.data?.data };

    case CUSTOMER_GET_PASSWORD_RATE_FAILED:
      return { ...state };

    case CUSTOMER_ADD_COMM_COMMENT:
      return { ...state };

    case CUSTOMER_ADD_COMM_COMMENT_SUCCESS:
      if (action.data.success) {
        NotificationManager.success("Comment Added !", action.data?.message, 3000, null, null, "filled");
      } else {
        NotificationManager.success("Something went wrong !", action.data?.message, 3000, null, null, "filled");
      }
      return { ...state };

    case CUSTOMER_ADD_COMM_COMMENT_FAILED:
      if (!action.data?.success) {
        NotificationManager.error("Something went wrong !", "Please try again !", 3000, null, null, "filled");
      }
      return { ...state };

    case CUSTOMER_BOT_FOLLOW_UP:
      return { ...state };

    case CUSTOMER_BOT_FOLLOW_UP_SUCCESS:
      if (action.data.success) {
        NotificationManager.success("Success !", action.data?.message, 3000, null, null, "filled");
      } else {
        NotificationManager.success("Something went wrong !", action.data?.message, 3000, null, null, "filled");
      }
      return { ...state };

    case CUSTOMER_BOT_FOLLOW_UP_FAILED:
      NotificationManager.error("Something went wrong !", "Please try again !", 3000, null, null, "filled");
      return { ...state };

    default:
      return { ...state };
  }
};

export const FETCH_APP_VERSION_REQUEST = "FETCH_APP_VERSION_REQUEST";
export const FETCH_APP_VERSION_SUCCESS = "FETCH_APP_VERSION_SUCCESS";
export const FETCH_APP_VERSION_FAILURE = "FETCH_APP_VERSION_FAILURE";
export const UPDATE_VERSION_REQUEST = "UPDATE_VERSION_REQUEST";
export const UPDATE_VERSION_SUCCESS = "UPDATE_VERSION_SUCCESS";
export const UPDATE_VERSION_FAILURE = "UPDATE_VERSION_FAILURE";
export const fetchAppVersionRequest = (app) => ({
  type: FETCH_APP_VERSION_REQUEST,
  payload: app
});
export const updateVersionRequest = (versionData) => ({
  type: UPDATE_VERSION_REQUEST,
  payload: versionData
});

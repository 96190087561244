const env = process.env.REACT_APP_ENV || "local";

let regularApi, agentApi;

switch (env) {
  case "dev":
    regularApi = "https://api.dev.insurancesamadhan.com/";
    agentApi = "https://api.dev.insurancesamadhan.com/agentsApi/";
    break;
  case "stage":
    regularApi = "https://api.stage.insurancesamadhan.com/";
    agentApi = "https://api.stage.insurancesamadhan.com/agentsApi/";
    break;
  case "prod":
    regularApi = "https://api.insurancesamadhan.com/";
    agentApi = "https://api.insurancesamadhan.com/agentsApi/";
    break;
  default:
    regularApi = "http://localhost:3999/";
    agentApi = "http://localhost:3999/agentsApi/";
}

export const baseURLs = {
  regular: regularApi,
  agent: agentApi
};

export const apisURLs = {
  adminLogin: baseURLs.regular + "admin/login",
  agentLogin: baseURLs.agent + "login",
  createToken: baseURLs.agent + "createToken",
  getComplaintById: baseURLs.regular + "insurance/details/",
  state: baseURLs.regular + "ombudsman/state",
  getAllInsa: baseURLs.regular + "insurance/getAllInsa",
  policyType: baseURLs.regular + "policy_type",
  assignLegalExpert: baseURLs.agent + "assignLegalExpert",
  assignOMD: baseURLs.agent + "assignOMD",
  getCurrentInvoiceCount: baseURLs.regular + "insurance/getCurrentInvoiceCount",
  findByUserId: baseURLs.regular + "insurance/findByUserId",
  omdRemindMail: baseURLs.regular + "omdRemindMail/",
  userAdmin: baseURLs.regular + "insurance/userAdmin",
  findLegalByComplaintId: baseURLs.regular + "insurance/findLegalByComplaintId",
  getLegalUserData: baseURLs.regular + "getLegalUserData/",
  getCompanyNoticeData: baseURLs.regular + "companyNotice/getCompanyNoticeData",
  getAllForEscalation: baseURLs.regular + "insurance/getAllForEscalation",
  docs: baseURLs.regular + "docs/",
  lead: baseURLs.regular + "lead/",
  // checkRefreshToken : baseURLs.regular + 'gmail/checkRefreshToken/',
  getComplaintTypeList: baseURLs.regular + "complaint_type?policyTypeId=",
  getInsuranceCompanyList: baseURLs.regular + "insurance_company/getByPolicyId?policyTypeId=",
  userBasedData: baseURLs.regular + "insurance/getUserBasedData",
  firstDraftData: baseURLs.regular + "insurance/firstDraftData",
  searchEmailPolicy: baseURLs.agent + "searchEmailPolicy",
  complaintReport: baseURLs.regular + "analytics/csv/insurance",
  customerReport: baseURLs.regular + "analytics/csv/customerReport",
  setStatusPOST: baseURLs.regular + "insurance/setStatus",
  getUserBasedDataPOST: baseURLs.regular + "insurance/getUserBasedData",
  assignCompanyPOST: baseURLs.regular + "insurance/assignIGMS",
  assignOmbudsmanPOST: baseURLs.regular + "insurance/assignOMD",
  getDraftMail: baseURLs.regular + "draftMailAuto/getDraftMail/",
  getCompIds: baseURLs.regular + "insurance/compIds",
  getHtmlPage: baseURLs.regular + "insurance/getHtmlPage",
  getAllUserPolicy: baseURLs.regular + "insurance/getAllUserPolicy",
  sentMailToUser: baseURLs.regular + "draftMailAuto/sendEmailToCustomerComplaint",
  getLeadDoc: baseURLs.regular + "lead/",
  generateZip: baseURLs.regular + "insurance/allDocDownload",
  generateLegalDocZip: baseURLs.regular + "insurance/allLegalDocs",
  downloadZip: baseURLs.regular + `insurance/details/`,
  nonResponsiveFlow: baseURLs.regular + "nonResponsiveFlow/getNRData",
  updateNonResFlow: baseURLs.regular + "nonResponsiveFlow/addUpdateData",
  inactiveFlow: baseURLs.regular + "nonResponsiveFlow/activeInactiveFollow",
  uploadComCSV: baseURLs.regular + "lead/uploadComplaintCsv",
  addComUser: baseURLs.regular + "lead/addComplaintUser",
  reqData: baseURLs.regular + "reqDataFromCustomer/addReqData",
  getCancelData: baseURLs.regular + "reqDataFromCustomer/getCancelUserData/",
  cancelReqData: baseURLs.regular + "reqDataFromCustomer/cancelReqData",
  getTemplate: baseURLs.regular + "omdRemindMail/getTemplate/",
  getTemplateMul: baseURLs.regular + "omdRemindMailMultiple/getTemplate/",
  updateTemplate: baseURLs.regular + "omdRemindMail/updateTemplate",
  updateMulTemplate: baseURLs.regular + "omdRemindMailMultiple/updateTemplate",
  sendTemplate: baseURLs.regular + "omdRemindMail/sendTemplate",
  sendTemplateMul: baseURLs.regular + "omdRemindMailMultiple/sendTemplate",
  getTempMail: baseURLs.regular + "omdRemindMailMultiple/getTempMail",
  generateMulMailRem: baseURLs.regular + "omdRemindMailMultiple/getMailTemplate",
  trackData: baseURLs.regular + "omdRemindMailMultiple/getTrackData",
  selectedDoc: baseURLs.regular + "insurance/selectedDocuments",
  updateComplaint: baseURLs.regular + "insurance/addMore/",
  editConsumerCourtDates: baseURLs.regular + "insurance/editDates/",
  userReport: baseURLs.agent + "dateWise",
  statusWise: baseURLs.agent + "statusWise",
  groupWise: baseURLs.agent + "groupWise",
  assignUser: baseURLs.agent + "assignUser",
  assignExprtList: baseURLs.agent + "assignExpert",
  getSingleUser: baseURLs.agent + "user/",
  sendMsgUser: baseURLs.regular + "contact/sendUpdate/",
  updateNewComForm: baseURLs.regular + "ombudsman/updateNewComplainForm",
  generateReqSendDoc: baseURLs.regular + "ombudsman/generateRequirementMail",
  sendReqSendDoc: baseURLs.regular + "ombudsman/sendRequirement",
  form6ASend: baseURLs.regular + "ombudsman/form6AsendCombined/",
  uploadDocForm6A: baseURLs.regular + "ombudsman/form6AFormUpload/",
  saveGenNewComForm: baseURLs.regular + "ombudsman/newComplaintForm/",
  sendNewComForm: baseURLs.regular + "ombudsman/newFormSend/",
  getnerateForm6A: baseURLs.regular + "ombudsman/form6AGenerateCombined/",
  sendForm6ALink: baseURLs.regular + "ombudsman/form6Auploadlinksend/",
  updateForm6AEmail: baseURLs.regular + "ombudsman/updateForm6AEmail",
  ombForm6Aupdate: baseURLs.regular + "ombudsman/form6AUpdate/",
  rewardTypeUpdate: baseURLs.regular + "rewardType/",
  optionBasedData: baseURLs.regular + "insurance/optionBasedData",
  generateInvoice: baseURLs.regular + "insurance/generateInvoice",
  markSettled: baseURLs.regular + "insurance/markSettled",
  allDocCombined: baseURLs.regular + "insurance/allDocDownloadCombined",
  saveMail: baseURLs.agent + "saveIncompleteMail",
  saveDrafftMail: baseURLs.agent + "customSaveEmail",
  saveDrafftMailSecond: baseURLs.regular + "insurance/customSaveEmail",
  saveIncompleteMail: baseURLs.agent + "saveIncompleteMail",
  deleteSaveEmail: baseURLs.agent + "deleteSaveEmail",
  updateDraftMail: baseURLs.agent + "draftMailUpdate",
  approveMail: baseURLs.regular + "insurance/approveExpertService",
  updateInvoiceDetails: baseURLs.regular + "insurance/updateInvoiceDetails",
  sendInvoice: baseURLs.regular + "insurance/sendInvoice",
  cancelInvoice: baseURLs.regular + "insurance/cancelInvoice",
  getDraftMailData: baseURLs.agent + "getIncompleteMail/",
  calcInvoiceDetail: baseURLs.regular + "insurance/generateInvoice",
  generateAnnexure: baseURLs.regular + "insurance/generateAnnexurePdf",
  remainingInvoice: baseURLs.regular + "insurance/remainingInvoice",
  editInvoice: baseURLs.regular + "insurance/editInvoice",
  generateInvoicePdf: baseURLs.regular + "insurance/generateInvoicePdf",
  sendForEscalation: baseURLs.regular + "draftMailAuto/sendForEscalation",
  pushToLogal: baseURLs.regular + "insurance/pushLegalContract",
  legalComm: baseURLs.regular + "legalQuery/getLegalQuery/",
  pushLegalQueryToCus: baseURLs.regular + "legalQuery/pushLegalQueryToCustomer",
  addLegalComm: baseURLs.regular + "legalQuery/addLegalCommunication",
  deleteLegalComm: baseURLs.regular + "legalQuery/deleteCommunication",
  legalExecutive: baseURLs.agent + "assignLegalExecutive",
  assignLegalExe: baseURLs.regular + "legalQuery/assignToLegalExecutive",
  assignLegalExeMultiple: baseURLs.regular + "legalQuery/multipleCaseAssignToAdvocate",
  getLegalSubExecutive: baseURLs.agent + "getLegalSubExecutiveList",
  legalUploadFile: baseURLs.regular + "insurance/uploadLegalDocument/",
  complaintUploadFile: baseURLs.regular + "insurance/uploadDocumentInComplaint/",
  legalDocSent: baseURLs.regular + "legalQuery/legalDraftSend",
  sendLegalDocToCustomer: baseURLs.regular + "legalQuery/sendLegalDocToCustomer",
  legalDocDelete: baseURLs.regular + "legalQuery/legalDraftDelete",
  assignToIntExe: baseURLs.regular + "insurance/assignInternalLegalExecutive",
  legalAutomationMail: baseURLs.regular + "insurance/movementMail",
  sendNotificationTrigger: baseURLs.regular + "insurance/sendNotification",
  searchEmailData: baseURLs.agent + "searchEmailData",
  sendToExpert: baseURLs.regular + "draftMailAuto/sendToExpert",
  sendForExpertApproved: baseURLs.regular + "draftMailAuto/sendForExpertApproved",
  resetMisselling: baseURLs.regular + "draftMailAuto/reset",
  getMissellingDet: baseURLs.regular + "newMissellingForm/getMissellingDetails",
  updateMisselling: baseURLs.regular + "newMissellingForm/addUpdateMissellingDetails",
  getPrevCom: baseURLs.regular + "newMissellingForm/getPrevComplaint",
  generateMailsForCom: baseURLs.regular + "newMissellingForm/generateMailsForCompForm",
  updateDraftMissell: baseURLs.regular + "newMissellingForm/updateDraftMissell",
  updateMissellingDraftGen: baseURLs.regular + "newMissellingForm/updateMissellDraft",
  mailAutoDraftMail: baseURLs.regular + "filtration/hi/getDraftMail",
  updateMailAutoDraftMail: baseURLs.regular + "filtration/hi/updateFiltrationMail",
  saveEscMail: baseURLs.regular + "insurance/saveEscalationDraft",
  pushToCustomer: baseURLs.regular + "insurance/pushEscalationDraftToCustomer",
  getByNamePolicy: baseURLs.regular + "leadFormNew/getByNamePolicy",
  getTemplateView: baseURLs.regular + "leadFormNew/getTemplateView",
  addUpdateMail: baseURLs.regular + "leadFormNew/addUpdateMail",
  generateDraftMail: baseURLs.regular + "insurance/customSaveEmail",
  insuranceCompany: baseURLs.regular + "insurance_company/getMultiple",
  expertComReport: baseURLs.regular + "insurance/downloadUserComplaintDate",
  legalNotice: baseURLs.regular + "insurance/legalNotice",
  generateLegalNotice: baseURLs.regular + "insurance/generateLegalNotice",
  getLegalNotice: baseURLs.regular + "insurance/getLegalNotice",
  editLegalNotice: baseURLs.regular + "insurance/editLegalNotice",
  sendLegalNotice: baseURLs.regular + "insurance/sendLegalNotice",
  generateLegalRecoveryNotice: baseURLs.regular + "insurance/generateRecoveryNoticeFromCompany",
  editCompanyNotice: baseURLs.regular + "insurance/editLegalRecoveryNotice",
  sendLegalRecoveryNotice: baseURLs.regular + "insurance/sendLegalRecoveryNotice",
  getLegalRecoveryNotice: baseURLs.regular + "insurance/getRecoveryNoticeFromCompany",
  checkmail: baseURLs?.regular + "draftMailAuto/checkmail",
  getEscalationDraft: baseURLs.regular + "insurance/getEscalationDraft",
  igmsOmbDoc: baseURLs.regular + "insurance/SingleCase/",
  fideQueryData: baseURLs.regular + "insurance/findQueryData",
  reminderMailSent: baseURLs.regular + "omdRemindMail/reminderMailSent",
  resolutionProofUpload: baseURLs.regular + "insurance/resolutionProof/",
  makeCallUsers: baseURLs.regular + "insurance/getCallList",
  complaintQuery: baseURLs.regular + "customerQueryDetails/getComplaintListQuery",
  addQuery: baseURLs.regular + "customerQueryDetails/addOrUpdate",
  editComplaint: baseURLs.regular + "insurance/editComplaintDetails/",
  createChild: baseURLs.regular + "insurance/copyComplaint/",
  deleteChild: baseURLs.regular + "insurance/deleteChild/",
  serviceCaseClose: baseURLs.regular + "insurance/serviceCaseClose",
  updateFinalPayment: baseURLs.regular + "insurance/updateFinalPaymentPdf",
  readGmail: baseURLs.regular + "gmail/read-user-emails",
  checkRefreshToken: baseURLs.regular + "gmail/checkRefreshToken/",
  readEmailData: baseURLs.regular + "gmail/getReadData/",
  deleteMailingComm: baseURLs.regular + "insurance/deleteMailingCommunication",
  checkContract: baseURLs.regular + "insurance/checkContract",
  vendorDetails: baseURLs.regular + "insurance/getPartnerData/",
  getAllIGMSUser: baseURLs.agent + "assignIGMS",
  waiveOffList: baseURLs.regular + "waiveoff",
  downloadContract: baseURLs.regular + "signdesk/checkstatusanddownloadcontract",
  deleteDocument: baseURLs.regular + "insurance/deleteSelectedDocument",
  updateMultiData: baseURLs.regular + "insurance/updateMultipleUserData",
  refundInitiate: baseURLs.regular + "insurance/refundInitiate",
  //---------------> Health Claim Reimbursement End Points
  addHCRIVRLead: baseURLs.regular + "hcr/createHcrLead",
  uploadFileHCR: baseURLs.regular + "hcr/createHcrLeadUsingCsv",
  getStatusBucket: baseURLs.regular + "hcr/getBucketList",
  getAllHCRAccounts: baseURLs.regular + "user/getAllAccountsOfUser/",
  submitFollowUp: baseURLs.regular + "hcr/followUpHistory/",
  uploadManualFileFunc: baseURLs.regular + "hcr/uploadManualFilingForm",
  uploadDocFileFunc: baseURLs.regular + "hcr/uploadDoc",
  hcrComment: baseURLs.regular + "hcr/commentHistory/",
  addCallStatusHis: baseURLs.regular + "hcr/callStatusHistory/",
  hcrAddDetails: baseURLs.regular + "hcr/addDetails/",
  changeStatus: baseURLs.regular + "/hcr/changeStatus/",
  downloadReportFunc: baseURLs.regular + "hcr/download/report",
  markPaid: baseURLs.regular + "hcr/markPaymentDone/",
  sendPolifyxLink: baseURLs.regular + "hcr/sendAppLink",
  sendMailCommunication: baseURLs.regular + "hcr/sendMailCommunication",
  sendPaymentLink: baseURLs.regular + "hcr/sendPaymentLink",
  getallhcrexecutive: baseURLs.regular + "hcr/getallhcrexecutive",
  editTaskStep1: baseURLs.regular + "filtration/uploadPolicyByHcrExecutive/",
  healthClaimId: baseURLs.regular + "filtration/getHealthClaimFilingData/",
  healthClaimForm: baseURLs.regular + "filtration/updateFormV2/",
  claimFileDocumentList: baseURLs.regular + "filtration/claimFileDocumentList/",
  claimFileLumpSumList: baseURLs.regular + "filtration/claimFileLumpSumList/",
  initiatehcrdiscount: baseURLs.regular + "hcr/initiatehcrdiscount",
  approveordeclinewaiveoff: baseURLs.regular + "hcr/initiatehcrwaiveoff",
  emailAccessDownloadReport: baseURLs.regular + "gmail/downloadGmailReports",
  gmailAccessReport: baseURLs.regular + "gmail/gmailAccessReport",
  briefingRecording: baseURLs.regular + "insurance/briefingRecording/",
  customerWithdrawal: baseURLs.regular + "insurance/customerWithdrawal",
  signContract: baseURLs.regular + "filtration/hi/sendContractLink",
  getAllCoupons: baseURLs.regular + "coupon/getAll",
  addNewCoupon: baseURLs.regular + "coupon/add",
  updateCoupon: baseURLs.regular + "coupon/update/",
};

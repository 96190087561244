import _ from "lodash";
import { toast } from "helpers/Utils";
import {
  CALL_LOGS_FOR_CUSTOMER,
  CALL_LOGS_FOR_CUSTOMER_FAILED,
  CALL_LOGS_FOR_CUSTOMER_SUCCESS,
  LEAD_ACCEPT_LEAD,
  LEAD_ACCEPT_LEAD_FAILED,
  LEAD_ACCEPT_LEAD_SUCCESS,
  LEAD_ADD_FOLLOW_UP,
  LEAD_ADD_FOLLOW_UP_FAILED,
  LEAD_ADD_FOLLOW_UP_SUCCESS,
  LEAD_ASSIGN_EXPERT,
  LEAD_ASSIGN_EXPERT_FAILED,
  LEAD_ASSIGN_EXPERT_SUCCESS,
  LEAD_ASSIGN_USER,
  LEAD_ASSIGN_USER_FAILED,
  LEAD_ASSIGN_USER_SAVE,
  LEAD_ASSIGN_USER_SAVE_FAILED,
  LEAD_ASSIGN_USER_SAVE_SUCCESS,
  LEAD_ASSIGN_USER_SUCCESS,
  LEAD_CANCEL_LEAD,
  LEAD_CANCEL_LEAD_FAILED,
  LEAD_CANCEL_LEAD_SUCCESS,
  LEAD_COMM_HISTORY_ADD_COMMENT,
  LEAD_COMM_HISTORY_ADD_COMMENT_FAILED,
  LEAD_COMM_HISTORY_ADD_COMMENT_SUCCESS,
  LEAD_COMM_HISTORY_UPDATE_COMMENT,
  LEAD_COMM_HISTORY_UPDATE_COMMENT_FAILED,
  LEAD_COMM_HISTORY_UPDATE_COMMENT_SUCCESS,
  LEAD_DATA_WITH_STATUS,
  LEAD_DATA_WITH_STATUS_FAILED,
  LEAD_DATA_WITH_STATUS_SUCCESS,
  LEAD_DOC_UPLOAD_FORM_CHANGE_SUCCESS,
  LEAD_DOC_UPLOAD_FORM_SUCCESS,
  LEAD_DOC_UPLOAD_FORM_FAILED,
  LEAD_DOWNLOAD_REPORT,
  LEAD_DOWNLOAD_REPORT_FAILED,
  LEAD_DOWNLOAD_REPORT_SUCCESS,
  LEAD_FETCH_BY_ID,
  LEAD_FETCH_BY_ID_FAILED,
  LEAD_FETCH_BY_ID_SUCCESS,
  LEAD_FILTRATION_DATA_FAILED,
  LEAD_FILTRATION_DATA_SUCCESS,
  LEAD_GET_MISSELLING,
  LEAD_GET_MISSELLING_FAILED,
  LEAD_GET_MISSELLING_SUCCESS,
  LEAD_GET_POLICY_TYPE,
  LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE,
  LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_FAILED,
  LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_SUCCESS,
  LEAD_GET_POLICY_TYPE_FAILED,
  LEAD_GET_POLICY_TYPE_SUCCESS,
  LEAD_INSURANCE_COMPANY,
  LEAD_INSURANCE_COMPANY_FAILED,
  LEAD_INSURANCE_COMPANY_SUCCESS,
  LEAD_REJECT_LEAD,
  LEAD_REJECT_LEAD_FAILED,
  LEAD_REJECT_LEAD_SUCCESSS,
  LEAD_SEND_MESSAGE_TO_USER,
  LEAD_SEND_MESSAGE_TO_USER_FAILED,
  LEAD_SEND_MESSAGE_TO_USER_SUCCESS,
  Lead_UPDATE_LEAD,
  Lead_UPDATE_LEAD_FAILED,
  Lead_UPDATE_LEAD_SUCCESS,
  LEAD_UPLOAD_DOCUMENT_FORM_SUCCESS,
  LEAD_USERS,
  LEAD_USERS_FAILED,
  LEAD_USERS_SUCCESS,
  SEARCH_BY_MAIL_AND_PHONE,
  SEARCH_BY_MAIL_AND_PHONE_FAILED,
  SEARCH_BY_MAIL_AND_PHONE_SUCCESS,
  LEAD_INTERNAL_DOC_UPLOAD_FORM_SUCCESS,
  LEAD_INTERNAL_DOC_UPLOAD_FORM_FAILED,
  LEAD_DOC_UPLOAD_SUCCESS,
  LEAD_DOC_UPLOAD_FAILED,
  LEAD_DOC_ZIP_SUCCESS,
  LEAD_DOC_ZIP_FAILED,
  LEAD_DOC_PASSWORD_SUCCESS,
  LEAD_DOC_PASSWORD_FAILED,
  LEAD_DOC_SET_LOADER_SUCCESS,
  LEAD_DOC_UPLOAD_FORM_COMBINED_SUCCESS,
  LEAD_DOC_UPLOAD_FORM_COMBINED_FAILED,
  LEAD_GET_SINGLE_DATA_SUCCESS,
  LEAD_INS_COMPANY_LIST_SUCCESS,
  LEAD_INS_COMPANY_LIST_FAILED,
  LEAD_GET_SINGLE_DATA_FAILED,
  LEAD_GET_USERDETAILS_SUCCESS,
  LEAD_GET_USERDETAILS_FAILED,
  LEAD_FLUSH_FILTRATION_SUCCESS,
  LEAD_VICTIM_DETAILS_SUCCESS,
  LEAD_VICTIM_DETAILS_FAILED,
  LEAD_SAVE_VICTIM_DETAILS_SUCCESS,
  LEAD_SAVE_VICTIM_DETAILS_FAILED,
  LEAD_SAVE_FRAUD_DETAILS_SUCCESS,
  LEAD_SAVE_FRAUD_DETAILS_FAILED,
  LEAD_SAVE_PROOF_DETAILS_SUCCESS,
  LEAD_SAVE_PROOF_DETAILS_FAILED,
  LEAD_SUBMIT_MISSELL_SUCCESS,
  LEAD_SUBMIT_MISSELL_FAILED,
  LEAD_GET_INSURANCE_COMPANIES,
  LEAD_GET_INSURANCE_COMPANIES_SUCCESS,
  LEAD_GET_INSURANCE_COMPANIES_FAILED,
  LEAD_ADD_IVR_LEAD,
  LEAD_ADD_IVR_LEAD_SUCCESS,
  LEAD_ADD_IVR_LEAD_FAILED,
  LEAD_SEND_WHATSAPP_MESSAGE,
  LEAD_SEND_WHATSAPP_MESSAGE_SUCCESS,
  LEAD_SEND_WHATSAPP_MESSAGE_FAILED,
  LEAD_MAKE_CALL,
  LEAD_MAKE_SERVETEL_CALL,
  LEAD_MAKE_CALL_SUCCESS,
  LEAD_MAKE_CALL_FAILED,
  LEAD_EXE_GET_SELECTED_DROPDOWN,
  LEAD_EXE_GET_SELECTED_DROPDOWN_SUCCESS,
  LEAD_EXE_GET_SELECTED_DROPDOWN_FAILED,
  LEAD_EXE_UPDATE,
  LEAD_EXE_UPDATE_SUCCESS,
  LEAD_EXE_UPDATE_FAILED,
  LEAD_EXE_SAVE_ADD_DETAIL_SUCCESS,
  LEAD_EXE_SAVE_ADD_DETAIL_FAILED,
  LEAD_EXE_REQUEST_SERVICE_RATE_SUCCESS,
  LEAD_EXE_REQUEST_SERVICE_RATE_FAILED,
  LEAD_YELLOW_TRANSCRIPT_GENERATE_SUCCESS,
  LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED,
  LEAD_YELLOW_TRANSCRIPT_SEND_SUCCESS,
  LEAD_YELLOW_TRANSCRIPT_SEND_FAILED,
  LEAD_MOVE_TO_POLIFYX_PENDING_SUCCESS,
  LEAD_MOVE_TO_POLIFYX_PENDING_FAILED,
  LEAD_MOVE_TO_PENDING_SUCCESS,
  LEAD_MOVE_TO_PENDING_FAILED,
  LEAD_UPDATE_NR_DETAILS_SUCCESS,
  LEAD_UPDATE_NR_DETAILS_FAILED,
  LEAD_EXE_GET_HI_FILTERATION_SUCCESS,
  LEAD_EXE_GET_HI_FILTERATION_FAILED,
  LEAD_HI_FILTRATION_SUCCESS,
  LEAD_HI_FILTRATION_FAILED,
  LEAD_HI_SINGLE_FILTER_FAILED,
  LEAD_HI_SINGLE_FILTER_SUCCESS,
  LEAD_SAVE_DETAILS_SUCCESS,
  LEAD_SAVE_DETAILS_FAILED,
  LEAD_SAVE_POL_HOLDER_DETAILS_SUCCESS,
  LEAD_SAVE_POL_HOLDER_DETAILS_FAILED,
  LEAD_SAVE_COMPLAINT_DETAILS_SUCCESS,
  LEAD_SAVE_COMPLAINT_DETAILS_FAILED,
  LEAD_UPDATE_FILT_PASSWORD_SUCCESS,
  LEAD_UPDATE_FILT_PASSWORD_FAILED,
  LEAD_SUBMIT_HEALTH_SUCCESS,
  LEAD_SUBMIT_HEALTH_FAILED,
  LEAD_ADD_COMMUNICATION_SUCCESS,
  LEAD_SAVE_COMPLAINT_DETAILS,
  LEAD_ADD_COMMUNICATION_FAILED,
  EXE_INTERNAL_DOC_UPLOAD_FAILED,
  EXE_INTERNAL_DOC_UPLOAD_SUCCESS,
  LEAD_ASSIGN_EXPERT_SAVE_SUCCESS,
  LEAD_ASSIGN_EXPERT_SAVE_FAILED,
  EXE_STATUS_LEAD_COUNT_SUCCESS,
  EXE_STATUS_LEAD_COUNT_FAILED,
  CALL_LOGS_FOR_CUSTOMER_CLEAR_SUCCESS,
  LEAD_SET_SEARCH_VALUE_SUCCESS,
  LEAD_SET_CURRENT_STATUS_SUCCESS,
  LEAD_SET_CURRENT_STATUS_FAILED,
  LEAD_DOC_VERIFICATION_PENDING_OPTIONS_SUCCESS,
  LEAD_DOC_VERIFICATION_PENDING_OPTIONS_FAILED,
  LEAD_DOC_UPDATE_DOC_LIST_SUCCESS,
  LEAD_DOC_UPDATE_DOC_LIST_FAILED,
  LEAD_DELETE_DOCUMENT,
  LEAD_DELETE_DOCUMENT_SUCCESS,
  LEAD_DELETE_DOCUMENT_FAILED,
  LEAD_ASSIGN_EXPERT_SAVE,
  LEAD_COUNT_BY_STATUS,
  LEAD_COUNT_BY_STATUS_SUCCESS,
  LEAD_COUNT_BY_STATUS_FAILED,
  LEAD_PARTNER_COMMUNICATION,
  LEAD_PARTNER_COMMUNICATION_SUCCESS,
  LEAD_PARTNER_COMMUNICATION_FAILED,
  GET_EXPERT_NOTIFICATION_LEAD,
  GET_EXPERT_NOTIFICATION_LEAD_SUCCESS,
  GET_EXPERT_NOTIFICATION_LEAD_FAILED,
  LEAD_AND_USER_EMAIL_CHANGE,
  LEAD_AND_USER_EMAIL_CHANGE_SUCCESS,
  LEAD_AND_USER_EMAIL_CHANGE_FAILED,
  LEAD_AND_USER_EMAIL_CHANGE_HISTORY,
  LEAD_AND_USER_EMAIL_CHANGE_HISTORY_FAILED,
  LEAD_AND_USER_EMAIL_CHANGE_HISTORY_SUCCESS,
  /*----------------------------------------*/
  LEAD_AND_USER_MOB_CHANGE,
  LEAD_AND_USER_MOB_CHANGE_SUCCESS,
  LEAD_AND_USER_MOB_CHANGE_FAILED,
  LEAD_AND_USER_MOB_CHANGE_HISTORY,
  LEAD_AND_USER_MOB_CHANGE_HISTORY_SUCCESS,
  LEAD_AND_USER_MOB_CHANGE_HISTORY_FAILED,
  /*----------------------------------------*/
  UPDATE_COMPLAINT_BY_FILTRATION,
  GET_CALLBACKLEADDATA_SUCCESS,
  GET_CALLBACKLEADDATA_FAILED,
  GET_CALLBACKLEAD_DATA,
  SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER,
  SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_SUCCESS,
  SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_FAILED,
  LEAD_COM_FOR_PARTNER,
  LEAD_COM_FOR_PARTNER_SUCCESS,
  LEAD_COM_FOR_PARTNER_FAILED,
  ADD_LEAD_PARTNER_COMMUNICATION,
  ADD_LEAD_PARTNER_COMMUNICATION_SUCCESS,
  ADD_LEAD_PARTNER_COMMUNICATION_FAILED,
  SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER,
  SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_SUCCESS,
  SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_FAILED,
  SEND_ENACH_REGISTRATION_LINK_SUCCESS,
  SEND_ENACH_REGISTRATION_LINK_FAILED,
  SEND_ENACH_REGISTRATION_LINK,
  UPDATE_DOCUMENT_LEAD_FLAG_SUCCESS,
  UPDATE_DOCUMENT_LEAD_FLAG_FAILED,
  UPDATE_DOCUMENT_LEAD_FLAG,
  DOWNLOAD_DOCUMENT_LEAD_FLAG,
  DOWNLOAD_DOCUMENT_LEAD_FLAG_SUCCESS,
  DOWNLOAD_DOCUMENT_LEAD_FLAG_FAILED,
  FAILED_COMPANY_NOTICE_REPLY,
  ADD_COMPANY_NOTICE_REPLY
} from "./Action";

const initialState = {
  assigUser: [],
  currentStatus: "",
  assignExpert: [],
  insuranceCompany: [],
  leadDataByStatus: [],
  leadUsers: {},
  message: "",
  leadReportData: "",
  missellingSelected: {},
  fetchedLead: {},
  filtrationData: {},
  callLogsCustomer: {},
  policyType: [],
  complaintType: [],
  loading: false,
  insuranceCom: [],
  exeSelectedStatus: [],
  exeHiFilterationData: {},
  statusLeadCount: {},
  searchLead: "",
  activeStatus: "",
  docVerPendingOption: [],
  leadDeleteDoc: false,
  partnerCommunicationArr: [],
  documentUploadForm: {
    checks: {
      policyBookCheck: false,
      claimBillCheck: false,
      deathCertificateCheck: false,
      surveyorReportCheck: false,
      settlementCheck: false,
      commCompanyCheck: false,
      callRecordCheck: false,
      postMortemReportCheck: false,
      drCertificateCheck: false,
      dischargeSummCheck: false,
      emailExcCheck: false,
      rejectionLetterCheck: false,
      dischargeDocCheck: false,
      previousPolicyCheck: false,
      rtpcrTestCheck: false,
      otherCheck: false,
      firCheck: false,
      policySchedulePageCheck: false
    },
    documents: {
      policyBookArray: [],
      claimBillArray: [],
      rejectionLetterArray: [],
      surveyorReportArray: [],
      otherArray: [],
      dischargeDocArray: [],
      dischargeSummArray: [],
      emailExcArray: [],
      settlementArray: [],
      commCompanyArray: [],
      callRecordArray: [],
      previousPolicyArray: [],
      drCertificateArray: [],
      rtpcrTestArray: [],
      deathCertificateArray: [],
      firArray: [],
      postMortemReportArray: [],
      policySchedulePageArray: []
    },
    docReason: {
      policyBookReason: "",
      policySchedulePageReason: "",
      rejectionLetterReason: "",
      claimBillReason: "",
      surveyorReportReason: "",
      emailExcReason: "",
      dischargeDocReason: "",
      dischargeSummReason: "",
      settlementReason: "",
      commCompanyReason: "",
      callRecordReason: "",
      previousPolicyReason: "",
      drCertificateReason: "",
      rtpcrTestReason: "",
      deathCertificateReason: "",
      firReason: "",
      postMortemReportReason: "",
      internalDocumentReason: ""
    },
    docZipName: "",
    docPassword: [],
    notificationKey: false,
    Docs: [],
    fileLoader: {
      PolicyDocHI: false,
      PolicyDoc: false,
      PolicyCopy: false,
      ProofOfRecord: false,
      DischargeSumm: false,
      DischargeSummLi: false,
      DischargeSummGi: false,
      RejectionLetter: false,
      RejectionLetterLi: false,
      ClaimFormLi: false,
      ClaimForm: false,
      FinalBill: false,
      SettlementLetterHi: false,
      SettlementLetter: false,
      SurveyorReport: false,
      CommunicationCompanyHi: false,
      CommunicationCompanyLi: false,
      CommunicationCompany: false,
      CallRecordingHi: false,
      CallRecordingLi: false,
      CallRecording: false,
      PreviousPolicyHi: false,
      DrCertificateHi: false,
      DrReportLi: false,
      DrReportGi: false,
      RT_PCRTestHi: false,
      DeathCertificateHi: false,
      DeathCertificate: false,
      DeathCertificateGi: false,
      FIRHi: false,
      FIR: false,
      FIRGi: false,
      PostMortemReportHi: false,
      PostMortemReport: false,
      PostMortemReportGi: false,
      OtherDocs: false
    }
  },
  filtrationDetail: {
    saveFiltrationLoading: false,
    apiLiData: {},
    apiHiData: {
      compArray: [],
      userDetails: {},
      filtrDetails: {}
    }
  },
  insCompanyByPolId: [],
  searchSingleLead: false,
  currentLeadData: {
    filtrationDone: false
  },
  onSearchBtnClick: false,
  leadCount: {},
  notificationLeads: [],
  loadingLeadReport: false,
  sendingDocLink: false,
  sendingDocLinkToPartner: false,
  documentPendingLeads: []
};

const initState = _.cloneDeep(initialState);

export default (state = initialState, action) => {
  const policyFiltrationDocs = action.data?.policyId?.policyDocName || [];
  let tempObj = {};
  const { doc, docReason, docPassword } = action.data || "";

  const documentUploadForm = _.cloneDeep(initState.documentUploadForm);
  const newDocReason = {};
  const newData = {};
  const tempObj1 = {};
  const tempObj2 = {};
  const tempObj3 = {};

  switch (action.type) {
    case LEAD_ASSIGN_USER:
      return { ...state };

    case LEAD_ASSIGN_USER_SUCCESS:
      toast("All User fetched !", "Success !", "success");
      return { ...state, assigUser: action.data };

    case LEAD_ASSIGN_USER_FAILED:
      toast("Failed to fetch Users !", "Something went wrong !", "error");
      return { ...state, message: action?.message };

    case LEAD_ASSIGN_EXPERT:
      return { ...state };

    case LEAD_ASSIGN_EXPERT_SUCCESS:
      return { ...state, assignExpert: action.data };

    case LEAD_ASSIGN_EXPERT_FAILED:
      toast("Failed to fetch Experts !", "Something went wrong !", "error");
      return { ...state, message: action?.message };
    case LEAD_ASSIGN_EXPERT_SAVE:
      return { ...state, loading: true };
    case LEAD_ASSIGN_EXPERT_SAVE_SUCCESS:
      toast("Assign to Expert Successfully !", "Success !", "success");
      return { ...state, loading: false };

    case LEAD_ASSIGN_EXPERT_SAVE_FAILED:
      toast("Failed to Assign to Expert !", "Please try again !", "error");
      return { ...state, loading: false };

    case LEAD_INSURANCE_COMPANY:
      return { ...state };

    case LEAD_INSURANCE_COMPANY_SUCCESS:
      return { ...state, insuranceCompany: action.data };

    case LEAD_INSURANCE_COMPANY_FAILED:
      return { ...state, message: action?.message };

    case LEAD_DATA_WITH_STATUS:
      return { ...state, loading: true };

    case LEAD_DATA_WITH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        leadDataByStatus: action.data,
        message: action?.message || "Successfully Done !",
        searchSingleLead: false,
        currentStatus: action.leadStatus
      };

    case LEAD_DATA_WITH_STATUS_FAILED:
      toast("Failed to fetch lead data !", "Something went wrong !", "error");
      return {
        ...state,
        loading: false,
        message: action?.message,
        searchSingleLead: false
      };

    case LEAD_USERS:
      return { ...state };

    case LEAD_USERS_SUCCESS:
      return { ...state, leadUsers: action.data };

    case LEAD_USERS_FAILED:
      return { ...state, message: action?.message };

    case SEARCH_BY_MAIL_AND_PHONE:
      return { ...state, onSearchBtnClick: true, loading: true };

    case SEARCH_BY_MAIL_AND_PHONE_SUCCESS:
      toast("Lead fetched !", "Success !", "success");
      return {
        ...state,
        leadDataByStatus: {
          list: action?.data?.pageOfItems,
          totalRecords: action?.data?.pager?.totalItems
        },
        message: action?.message || "Successfully Done !",
        searchSingleLead: true,
        loading: false,
        onSearchBtnClick: false
      };

    case SEARCH_BY_MAIL_AND_PHONE_FAILED:
      toast("Failed to fetch lead !", "Something went wrong !", "error");
      return { ...state, message: action?.message, searchSingleLead: true, loading: false, onSearchBtnClick: false };

    case LEAD_DOWNLOAD_REPORT:
      return { ...state, loadingLeadReport: true };

    case LEAD_DOWNLOAD_REPORT_SUCCESS:
      toast("Report Downloaded Successfully !", "Success !", "success");
      return {
        ...state,
        loadingLeadReport: false,
        leadReportData: action.data,
        message: action?.message || "Successfully Done !"
      };

    case LEAD_DOWNLOAD_REPORT_FAILED:
      toast("Failed to download report !", "Something went wrong !", "error");
      return { ...state, loadingLeadReport: false, message: action?.message };

    case LEAD_GET_MISSELLING:
      return { ...state };

    case LEAD_GET_MISSELLING_SUCCESS:
      return {
        ...state,
        missellingSelected: action.data,
        message: action?.message || "Successfully Done !"
      };

    case LEAD_GET_MISSELLING_FAILED:
      return { ...state, message: action?.message };

    case LEAD_ASSIGN_USER_SAVE:
      return { ...state };

    case LEAD_ASSIGN_USER_SAVE_SUCCESS:
      toast("Assignment Successful", "Success", "success");
      return { ...state, message: "Assignment Successful !" };

    case LEAD_ASSIGN_USER_SAVE_FAILED:
      return { ...state, message: "Failed to Assign" };

    case LEAD_ACCEPT_LEAD:
      return { ...state };

    case LEAD_ACCEPT_LEAD_SUCCESS:
      toast("Lead Accepted Successfully !", "Success !", "success");
      return { ...state, message: "Lead Accepted Successful !" };

    case LEAD_ACCEPT_LEAD_FAILED:
      toast("Failed To Accept Lead !", "Failed !", "error");
      return { ...state, message: action.message };

    case LEAD_REJECT_LEAD:
      return { ...state };

    case LEAD_REJECT_LEAD_SUCCESSS:
      return { ...state, message: "Lead Rejected Successful !" };

    case LEAD_REJECT_LEAD_FAILED:
      toast("Failed !", action?.message, "error");
      return { ...state, message: action?.message };

    case LEAD_COMM_HISTORY_UPDATE_COMMENT:
      return { ...state };

    case LEAD_COMM_HISTORY_UPDATE_COMMENT_SUCCESS:
      return { ...state, message: action?.message };

    case LEAD_COMM_HISTORY_UPDATE_COMMENT_FAILED:
      return { ...state, message: action?.message };

    case LEAD_COMM_HISTORY_ADD_COMMENT:
      return { ...state };

    case LEAD_COMM_HISTORY_ADD_COMMENT_SUCCESS:
      return { ...state, message: action?.message };

    case LEAD_COMM_HISTORY_ADD_COMMENT_FAILED:
      return { ...state, message: action?.message };

    case LEAD_COM_FOR_PARTNER:
      return { ...state };

    case LEAD_COM_FOR_PARTNER_SUCCESS:
      return { ...state, message: action?.message };

    case LEAD_COM_FOR_PARTNER_FAILED:
      return { ...state, message: action?.message };

    case LEAD_FETCH_BY_ID:
      return { ...state, fetchedLead: {} };

    case LEAD_FETCH_BY_ID_SUCCESS:
      return {
        ...state,
        fetchedLead: action.data,
        loading: false,
        message: action?.message || "Successfully Done !"
      };

    case LEAD_FETCH_BY_ID_FAILED:
      return { ...state, message: action?.message, loading: false };

    case LEAD_GET_SINGLE_DATA_SUCCESS:
      return { ...state, currentLeadData: { ...action.data } };

    case LEAD_GET_SINGLE_DATA_FAILED:
      toast("Failed to get single lead data", "", "error");
      return { ...state };

    case LEAD_FILTRATION_DATA_SUCCESS:
      return { ...state, filtrationData: { ...action.data }, documentUploadForm: { ...state.documentUploadForm, Docs: [...state.documentUploadForm.Docs, ...policyFiltrationDocs] } };

    case LEAD_FILTRATION_DATA_FAILED:
      toast("Failed to get filtration data !", "", "error");
      return { ...state };

    case LEAD_INS_COMPANY_LIST_SUCCESS:
      if (action.data) {
        return { ...state, insCompanyByPolId: [...action.data] };
      }
      return { ...state };

    case LEAD_INS_COMPANY_LIST_FAILED:
      toast("Failed to get insurance company list", "", "error");
      return { ...state };

    // Hi filtration form
    case LEAD_GET_USERDETAILS_SUCCESS:
      return { ...state, filtrationDetail: { ...state.filtrationDetail, apiHiData: { ...state.filtrationDetail.apiHiData, userDetails: { ...action.data } } } };

    case LEAD_GET_USERDETAILS_FAILED:
      toast(`${action?.message || "Failed to get userdetails for lead"}`, "", "error");
      return { ...state };

    case LEAD_HI_FILTRATION_SUCCESS:
      return { ...state, filtrationDetail: { ...state.filtrationDetail, apiHiData: { ...state.filtrationDetail.apiHiData, compArray: [...action.data] } } };

    case LEAD_HI_FILTRATION_FAILED:
      toast("Failed to fetch hi filtration details", "", "error");
      return { ...state };

    case LEAD_HI_SINGLE_FILTER_SUCCESS:
      return { ...state, filtrationDetail: { ...state.filtrationDetail, apiHiData: { ...state.filtrationDetail.apiHiData, filtrDetails: { ...action.data } } } };

    case LEAD_HI_SINGLE_FILTER_FAILED:
      toast(`${action?.message || "Failed to get hi filtration complaint details"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_DETAILS_SUCCESS:
      toast(`${action.message}`, "", "success");
      return { ...state, currentLeadData: { ...action.data } };

    case LEAD_SAVE_DETAILS_FAILED:
      toast(`${action?.message || "Failed to save lead details"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_POL_HOLDER_DETAILS_SUCCESS:
      toast(`${action.message}`, "", "success");
      // return { ...state, filtrationDetail: { ...state.filtrationDetail, apiHiData: {...action.data } } };
      return { ...state };

    case LEAD_SAVE_POL_HOLDER_DETAILS_FAILED:
      toast(`${action?.message || "Failed to save policy holder details"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_COMPLAINT_DETAILS_SUCCESS:
      toast(`${action.message}`, "", "success");
      if (_.isEmpty(action.data?.complaint)) {
        tempObj = { ...state.filtrationDetail.apiHiData };
      } else {
        tempObj = {
          ...state.filtrationDetail.apiHiData,
          filtrDetails: {
            ...state.filtrationDetail.apiHiData.filtrDetails,
            ...action.data?.complaint
          }
        };
      }
      return { ...state, filtrationDetail: { ...state.filtrationDetail, apiHiData: tempObj } };

    case LEAD_SAVE_COMPLAINT_DETAILS_FAILED:
      toast(`${action?.message || "Failed to save complaint details"}`, "", "error");
      return { ...state };

    case LEAD_UPDATE_FILT_PASSWORD_SUCCESS:
      return { ...state };

    case LEAD_UPDATE_FILT_PASSWORD_FAILED:
      toast(`${action?.message || "Failed to save filtration password"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_COMPLAINT_DETAILS:
      return { ...state, filtrationDetail: { ...state.filtrationDetail, saveFiltrationLoading: true } };

    case LEAD_ADD_COMMUNICATION_SUCCESS:
      if (!action.hide) {
        toast(`${action.message}`, "", "success");
      }
      return { ...state, filtrationDetail: { ...state.filtrationDetail, saveFiltrationLoading: false } };

    case LEAD_ADD_COMMUNICATION_FAILED:
      toast(`${action?.message || "Failed to save filtration password"}`, "", "error");
      return { ...state, filtrationDetail: { ...state.filtrationDetail, saveFiltrationLoading: false } };

    case LEAD_SUBMIT_HEALTH_SUCCESS:
      toast(`${action.message}`, "", "success");
      return { ...state };

    case LEAD_SUBMIT_HEALTH_FAILED:
      toast(`${action?.message || "Failed to save health filtration details"}`, "", "error");
      return { ...state };

    //end

    // Li filtration form
    case LEAD_VICTIM_DETAILS_SUCCESS:
      return { ...state, filtrationDetail: { ...state.filtrationDetail, apiLiData: { ...action.data } } };

    case LEAD_VICTIM_DETAILS_FAILED:
      toast(`${action?.message || "Failed to get victim data"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_VICTIM_DETAILS_SUCCESS:
      toast(`${action?.message}`, "", "success");
      return { ...state };

    case LEAD_SAVE_VICTIM_DETAILS_FAILED:
      toast(`${action?.message || "Failed to save victim details"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_FRAUD_DETAILS_SUCCESS:
      toast(`${action?.message}`, "", "success");
      return { ...state };

    case LEAD_SAVE_FRAUD_DETAILS_FAILED:
      toast(`${action?.message || "Failed to save fraud details"}`, "", "error");
      return { ...state };

    case LEAD_SAVE_PROOF_DETAILS_SUCCESS:
      toast(`${action?.message}`, "", "success");
      return { ...state };

    case LEAD_SAVE_PROOF_DETAILS_FAILED:
      toast(`${action?.message || "Failed to save proof details"}`, "", "error");
      return { ...state };
    //end

    case LEAD_SUBMIT_MISSELL_SUCCESS:
      toast(`${action?.message}`, "", "success");
      return { ...state, currentLeadData: { ...action.data } };

    case LEAD_SUBMIT_MISSELL_FAILED:
      toast(`${action?.message || "Failed to save misselling details"}`, "", "error");
      return { ...state };

    case LEAD_FLUSH_FILTRATION_SUCCESS:
      return { ...state, filtrationDetail: { apiLiData: {}, apiHiData: {} } };

    case CALL_LOGS_FOR_CUSTOMER:
      return { ...state };

    case CALL_LOGS_FOR_CUSTOMER_SUCCESS:
      toast(action.message, "", "success");
      return { ...state, callLogsCustomer: action.data };

    case CALL_LOGS_FOR_CUSTOMER_CLEAR_SUCCESS:
      return { ...state, callLogsCustomer: {} };

    case CALL_LOGS_FOR_CUSTOMER_FAILED:
      return { ...state, message: action?.message };

    case LEAD_CANCEL_LEAD:
      return { ...state };

    case LEAD_CANCEL_LEAD_SUCCESS:
      toast("Lead Cancelled Successfully !", "", "success");
      return { ...state };

    case LEAD_CANCEL_LEAD_FAILED:
      return { ...state, message: action?.message };

    case LEAD_GET_POLICY_TYPE:
      return { ...state };

    case LEAD_GET_POLICY_TYPE_SUCCESS:
      return { ...state, policyType: action.data };

    case LEAD_GET_POLICY_TYPE_FAILED:
      return { ...state, message: action?.message };

    case LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE:
      return { ...state };

    case LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_SUCCESS:
      return { ...state, complaintType: action.data };

    case LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_FAILED:
      return { ...state, message: action?.message };

    case Lead_UPDATE_LEAD:
      return { ...state };

    case Lead_UPDATE_LEAD_SUCCESS:
      return { ...state, message: "Lead Updated Successfully !" };

    case Lead_UPDATE_LEAD_FAILED:
      return { ...state, message: action?.message };

    case ADD_COMPANY_NOTICE_REPLY:
      return { ...state, message: "Company Reply Added Successfully !" };

    case FAILED_COMPANY_NOTICE_REPLY:
      return { ...state, message: action?.message };

    case LEAD_ADD_FOLLOW_UP:
      return { ...state };

    case LEAD_ADD_FOLLOW_UP_SUCCESS:
      return { ...state, message: "Follow Up added Successfully !" };

    case LEAD_ADD_FOLLOW_UP_FAILED:
      return { ...state, message: action?.message };

    case LEAD_SEND_MESSAGE_TO_USER:
      return { ...state };

    case LEAD_SEND_MESSAGE_TO_USER_SUCCESS:
      toast("Success !", "Message Sent Successfully !", "success");
      return { ...state };

    case LEAD_SEND_MESSAGE_TO_USER_FAILED:
      toast("Failed !", "Failed to Send Message !", "error");
      return { ...state };

    case LEAD_UPLOAD_DOCUMENT_FORM_SUCCESS:
      for (let i = 0; i < doc.length; i++) {
        if (doc[i]) {
          {
            let singleDoc = doc[i].split("_");
            if (singleDoc[0] == "ClaimBill" || singleDoc[0] == "ClaimForm" || singleDoc[0] == "FinalBill" || singleDoc[0] == "ClaimFormLi" || singleDoc[0] == "Final-Bills") {
              documentUploadForm.checks.claimBillCheck = true;
              documentUploadForm.documents.claimBillArray.push(doc[i]);
            } else if (singleDoc[0] == "PolicyBook" || singleDoc[0] == "PolicyBookHI" || singleDoc[0] == "PolicySh" || singleDoc[0] == "PolicyCopy" || singleDoc[0] == "PolicyDocHI" || singleDoc[0] == "PolicyDoc") {
              documentUploadForm.checks.policyBookCheck = true;
              documentUploadForm.documents.policyBookArray.push(doc[i]);
            } else if (singleDoc[0] == "RejectionLetter" || singleDoc[0] == "RejectionLatter" || singleDoc[0] == "RejectionLetterLi" || singleDoc[0] == "Rejection-Letter") {
              documentUploadForm.checks.rejectionLetterCheck = true;
              documentUploadForm.documents.rejectionLetterArray.push(doc[i]);
            } else if (singleDoc[0] == "SurveyorReport") {
              documentUploadForm.documents.surveyorReportCheck = true;
              documentUploadForm.documents.surveyorReportArray.push(doc[i]);
            } else if (singleDoc[0] == "generalotherdocs" || singleDoc[0] == "healthotherdocs" || singleDoc[0] == "LifeIns") {
              documentUploadForm.checks.otherCheck = true;
              documentUploadForm.documents.otherArray.push(doc[i]);
            } else if (singleDoc[0] == "DischargeDoc" || singleDoc[0] == "Indoor-Hospital") {
              documentUploadForm.checks.dischargeDocCheck = true;
              documentUploadForm.documents.dischargeDocArray.push(doc[i]);
            } else if (singleDoc[0] == "DischargeSumm" || singleDoc[0] == "DischargeSummLi" || singleDoc[0] == "DischargeSummGi" || singleDoc[0] == "Discharge-Summary") {
              documentUploadForm.checks.dischargeSummCheck = true;
              documentUploadForm.documents.dischargeSummArray.push(doc[i]);
            } else if (singleDoc[0] == "EmailExc" || singleDoc[0] == "ProofOfRecord" || singleDoc[0] == "Other-Document") {
              documentUploadForm.checks.emailExcCheck = true;
              documentUploadForm.documents.emailExcArray.push(doc[i]);
            } else if (singleDoc[0] == "SettlementLetter" || singleDoc[0] == "SettlementLetterHi") {
              documentUploadForm.checks.settlementCheck = true;
              documentUploadForm.documents.settlementArray.push(doc[i]);
            } else if (singleDoc[0] == "CommunicationCompany" || singleDoc[0] == "CommunicationCompanyHi" || singleDoc[0] == "CommunicationCompanyLi" || singleDoc[0] == "Mail-Communication") {
              documentUploadForm.checks.commCompanyCheck = true;
              documentUploadForm.documents.commCompanyArray.push(doc[i]);
            } else if (singleDoc[0] == "CallRecording" || singleDoc[0] == "CallRecordingHi" || singleDoc[0] == "CallRecordingLi" || singleDoc[0] == "Video-Audio-Proof") {
              documentUploadForm.checks.callRecordCheck = true;
              documentUploadForm.documents.callRecordArray.push(doc[i]);
            } else if (singleDoc[0] == "PreviousPolicyHi" || singleDoc[0] == "Previous-Policy") {
              documentUploadForm.checks.previousPolicyCheck = true;
              documentUploadForm.documents.previousPolicyArray.push(doc[i]);
            } else if (singleDoc[0] == "DrCertificateHi" || singleDoc[0] == "DrReportLi" || singleDoc[0] == "DrReportGi" || singleDoc[0] == "Doctor-Certificate") {
              documentUploadForm.checks.drCertificateCheck = true;
              documentUploadForm.documents.drCertificateArray.push(doc[i]);
            } else if (singleDoc[0] == "RT") {
              documentUploadForm.documents.rtpcrTestCheck = true;
              documentUploadForm.documents.rtpcrTestArray.push(doc[i]);
            } else if (singleDoc[0] == "DeathCertificate" || singleDoc[0] == "DeathCertificateHi" || singleDoc[0] == "DeathCertificateGi") {
              documentUploadForm.checks.deathCertificateCheck = true;
              documentUploadForm.documents.deathCertificateArray.push(doc[i]);
            } else if (singleDoc[0] == "FIR" || singleDoc[0] == "FIRGi" || singleDoc[0] == "FIRHi") {
              documentUploadForm.checks.firCheck = true;
              documentUploadForm.documents.firArray.push(doc[i]);
            } else if (singleDoc[0] == "PostMortemReport" || singleDoc[0] == "PostMortemReportGi" || singleDoc[0] == "PostMortemReportHi") {
              documentUploadForm.checks.postMortemReportCheck = true;
              documentUploadForm.documents.postMortemReportArray.push(doc[i]);
            } else if (singleDoc[0] == "PolicySchedulePageLI" || singleDoc[0] == "PolicySchedulePageGI" || singleDoc[0] == "PolicySchedulePageHI") {
              documentUploadForm.checks.policySchedulePageCheck = true;
              documentUploadForm.documents.policySchedulePageArray.push(doc[i]);
            }
          }
        }
      }
      return {
        ...state,
        documentUploadForm: {
          ...documentUploadForm,
          docReason,
          docPassword,
          Docs: doc
        },
        filtrationData: {}
      };

    case LEAD_DOC_UPLOAD_FORM_CHANGE_SUCCESS:
      if (action.data.docReason) {
        newDocReason[action.data.docReason.name] = action.data.docReason.value;
      } else {
        newData[action.data.name] = action.data.value;
      }
      return {
        ...state,
        documentUploadForm: {
          ...state.documentUploadForm,
          docReason: { ...state.documentUploadForm.docReason, ...newDocReason },
          ...newData
        }
      };

    case LEAD_INTERNAL_DOC_UPLOAD_FORM_SUCCESS:
    case LEAD_DOC_UPLOAD_FORM_SUCCESS:
      return { ...state };

    case LEAD_INTERNAL_DOC_UPLOAD_FORM_FAILED:
    case LEAD_DOC_UPLOAD_FORM_FAILED:
      toast("Failed to submit reason", "Something went wrong !", "error");
      return { ...state };

    case LEAD_DOC_SET_LOADER_SUCCESS:
      if (action.data.name) {
        tempObj1[action.data.name] = true;
      }
      return {
        ...state,
        documentUploadForm: {
          ...state.documentUploadForm,
          fileLoader: { ...state.documentUploadForm.fileLoader, ...tempObj1 }
        }
      };

    case LEAD_DOC_UPLOAD_SUCCESS:
      tempObj2["OtherDocs"] = false;
      if (action.data.name) {
        tempObj2[action.data.name] = false;
      }
      return {
        ...state,
        documentUploadForm: {
          ...state.documentUploadForm,
          fileLoader: { ...state.documentUploadForm.fileLoader, ...tempObj2 }
        }
      };

    case LEAD_DOC_UPLOAD_FAILED:
      tempObj3["OtherDocs"] = false;
      if (action.data.name) {
        tempObj3[action.data.name] = false;
      }
      toast("Failed to upload document", "Something went wrong !", "error");
      return {
        ...state,
        documentUploadForm: {
          ...state.documentUploadForm,
          fileLoader: { ...state.documentUploadForm.fileLoader, ...tempObj3 }
        }
      };

    case LEAD_DOC_UPLOAD_FORM_COMBINED_SUCCESS:
      toast("Document / Reason submitted successfully !", "", "success");
      return { ...state };

    case LEAD_DOC_UPLOAD_FORM_COMBINED_FAILED:
      toast("Document / Reason NOT submitted", "Please try again", "error");
      return { ...state };

    case LEAD_DOC_ZIP_SUCCESS:
      toast("Zip generated !", `${action?.message}`, "success");
      return {
        ...state,
        documentUploadForm: {
          ...state.documentUploadForm,
          docZipName: action.data?.docZipName
        }
      };

    case LEAD_DOC_ZIP_FAILED:
      toast("Zip Failed to generate !", `${action?.message}`, "error");
      return { ...state };

    case LEAD_DOC_PASSWORD_SUCCESS:
      return {
        ...state,
        documentUploadForm: {
          ...state.documentUploadForm,
          docPassword: action.data
        }
      };

    case LEAD_DOC_PASSWORD_FAILED:
      toast("Failed to add password", `${action?.message}`, "error");
      return { ...state };

    case LEAD_GET_INSURANCE_COMPANIES:
      return { ...state };

    case LEAD_GET_INSURANCE_COMPANIES_SUCCESS:
      return { ...state, insuranceCom: action.data };

    case LEAD_GET_INSURANCE_COMPANIES_FAILED:
      toast("Failed to get companies", `${action.message}`, "error");
      return { ...state };

    case LEAD_ADD_IVR_LEAD:
      return { ...state };

    case LEAD_ADD_IVR_LEAD_SUCCESS:
      if (action.message === "Lead Already Added!" || action.message?.includes("You already have a request")) {
        toast("Lead Already Exist !", `Please try with diffrent credentials !`, "error");
      } else {
        toast("Success !", `${action.message}`, "success");
        window.history.back();
      }
      return { ...state };

    case LEAD_ADD_IVR_LEAD_FAILED:
      toast("Failed to add Lead !", `${action.message}`, "error");
      return { ...state };

    case LEAD_SEND_WHATSAPP_MESSAGE:
      return { ...state };

    case LEAD_SEND_WHATSAPP_MESSAGE_SUCCESS:
      toast("Success !", `${action.message}`, "success");
      return { ...state };

    case LEAD_SEND_WHATSAPP_MESSAGE_FAILED:
      toast("Failed to send message !", `${action.message}`, "error");
      return { ...state };

    case LEAD_MAKE_CALL:
      return { ...state };

    case LEAD_MAKE_SERVETEL_CALL:
      return { ...state };

    case LEAD_MAKE_CALL_SUCCESS:
      toast("Success !", `${action.message}`, "success");
      return { ...state };

    case LEAD_MAKE_CALL_FAILED:
      toast("Failed to make call !", `${action.message}`, "error");
      return { ...state };

    case LEAD_EXE_GET_SELECTED_DROPDOWN:
      return { ...state };

    case LEAD_EXE_GET_SELECTED_DROPDOWN_SUCCESS:
      return { ...state, exeSelectedStatus: action.data };

    case LEAD_EXE_GET_SELECTED_DROPDOWN_FAILED:
      return { ...state };

    case LEAD_EXE_UPDATE:
      return { ...state };

    case LEAD_EXE_UPDATE_SUCCESS:
      if (!action.hide) {
        toast("Lead Updated Successfully !", `${action.message}`, "success");
      }
      return { ...state };

    case LEAD_EXE_UPDATE_FAILED:
      toast("", `${action.message}`, "error");
      return { ...state };

    case LEAD_EXE_SAVE_ADD_DETAIL_SUCCESS:
      toast("Details added Successfully !", `${action.message}`, "success");
      return { ...state };

    case LEAD_EXE_SAVE_ADD_DETAIL_FAILED:
      toast("Failed to add Details !", `${action.message}`, "error");
      return { ...state };

    case LEAD_EXE_REQUEST_SERVICE_RATE_SUCCESS:
      toast("Service rate added Successfully !", `${action.message}`, "success");
      return { ...state };

    case LEAD_EXE_REQUEST_SERVICE_RATE_FAILED:
      toast("Failed to add Service rate !", `${action.message}`, "error");
      return { ...state };

    case LEAD_YELLOW_TRANSCRIPT_GENERATE_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state };

    case LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED:
      toast("Failed !", action.message, "error");
      return { ...state };

    case LEAD_YELLOW_TRANSCRIPT_SEND_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state };

    case LEAD_YELLOW_TRANSCRIPT_SEND_FAILED:
      toast("Failed !", action.message, "error");
      return { ...state };

    case LEAD_MOVE_TO_POLIFYX_PENDING_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state };

    case LEAD_MOVE_TO_POLIFYX_PENDING_FAILED:
      toast("Failed !", action.message, "error");
      return { ...state };

    case LEAD_MOVE_TO_PENDING_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state };

    case LEAD_MOVE_TO_PENDING_FAILED:
      toast("Failed !", action.message, "error");
      return { ...state };

    case LEAD_UPDATE_NR_DETAILS_SUCCESS:
      return { ...state };

    case LEAD_UPDATE_NR_DETAILS_FAILED:
      toast("Failed !", action.message, "error");
      return { ...state };

    case LEAD_EXE_GET_HI_FILTERATION_SUCCESS:
      return { ...state, exeHiFilterationData: action.data };

    case LEAD_EXE_GET_HI_FILTERATION_FAILED:
      toast("Failed !", action.message, "error");
      return { ...state };

    case EXE_INTERNAL_DOC_UPLOAD_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state };

    case EXE_INTERNAL_DOC_UPLOAD_FAILED:
      return { ...state };

    case EXE_STATUS_LEAD_COUNT_SUCCESS:
      return { ...state, statusLeadCount: action.data };

    case EXE_STATUS_LEAD_COUNT_FAILED:
      return { ...state };

    case LEAD_SET_SEARCH_VALUE_SUCCESS:
      return { ...state, searchLead: action.data };

    case LEAD_SET_CURRENT_STATUS_SUCCESS:
      return { ...state, activeStatus: action.data };

    case LEAD_SET_CURRENT_STATUS_FAILED:
      return { ...state };

    case LEAD_DOC_VERIFICATION_PENDING_OPTIONS_SUCCESS:
      return { ...state, docVerPendingOption: action.data };

    case LEAD_DOC_VERIFICATION_PENDING_OPTIONS_FAILED:
      toast("Failed !", action?.message, "error");
      return { ...state };

    case LEAD_DOC_UPDATE_DOC_LIST_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state };

    case LEAD_DOC_UPDATE_DOC_LIST_FAILED:
      toast("Failed !", action?.message, "error");
      return { ...state };

    case LEAD_DELETE_DOCUMENT:
      return { ...state, leadDeleteDoc: true };

    case LEAD_DELETE_DOCUMENT_SUCCESS:
      toast("Success !", action.message, "success");
      return { ...state, leadDeleteDoc: false };

    case LEAD_DELETE_DOCUMENT_FAILED:
      toast("Failed to delete !", action?.message, "error");
      return { ...state };

    case LEAD_COUNT_BY_STATUS:
      return { ...state };

    case LEAD_COUNT_BY_STATUS_SUCCESS:
      return { ...state, leadCount: action.data };

    case LEAD_COUNT_BY_STATUS_FAILED:
      return { ...state };

    case LEAD_PARTNER_COMMUNICATION:
      return { ...state };

    case LEAD_PARTNER_COMMUNICATION_SUCCESS:
      return { ...state, partnerCommunicationArr: action.data };

    case LEAD_PARTNER_COMMUNICATION_FAILED:
      return { ...state };

    case GET_EXPERT_NOTIFICATION_LEAD:
      return { ...state };

    case GET_EXPERT_NOTIFICATION_LEAD_SUCCESS:
      return { ...state, notificationLeads: action.data, loading: false };

    case GET_EXPERT_NOTIFICATION_LEAD_FAILED:
      return { ...state, loading: false };

    case LEAD_AND_USER_EMAIL_CHANGE:
      return { ...state };

    case LEAD_AND_USER_EMAIL_CHANGE_SUCCESS:
      toast("Success", "Email Changed Successfully !", "success");
      return { ...state, leadEmailChange: action.data, loading: false };

    case LEAD_AND_USER_EMAIL_CHANGE_FAILED:
      if (action.error.response.data.message === "Email ID already exists") {
        toast("Error", "Email Already Exist!!!", "warning");
      } else {
        toast("Error", "Failed to change email!!!", "error");
      }
      return { ...state };

    case LEAD_AND_USER_EMAIL_CHANGE_HISTORY:
      return { ...state };

    case LEAD_AND_USER_EMAIL_CHANGE_HISTORY_SUCCESS:
      return { ...state, leadEmailHistory: action.data, loading: false };

    case LEAD_AND_USER_EMAIL_CHANGE_HISTORY_FAILED:
      return { ...state };

    /*------------Mobile Change--------------------------- */
    case LEAD_AND_USER_MOB_CHANGE:
      return { ...state };
    case LEAD_AND_USER_MOB_CHANGE_SUCCESS:
      toast("Success", "Mobile Number Changed Successfully !", "success");
      return { ...state, leadPhoneChange: action.data, loading: false };
    case LEAD_AND_USER_MOB_CHANGE_FAILED:
      if (action.error.response.data.message === "Mobile number already exists") {
        toast("Error", "Mobile Number is Already Exist!!!", "warning");
      } else {
        toast("Error", "Failed to Change Mobile Number!!!", "error");
      }
      return { ...state };
    case LEAD_AND_USER_MOB_CHANGE_HISTORY:
      return { ...state };
    case LEAD_AND_USER_MOB_CHANGE_HISTORY_SUCCESS:
      return { ...state, leadPhoneHistory: action.data, loading: false };
    case LEAD_AND_USER_MOB_CHANGE_HISTORY_FAILED:
      return { ...state };
    /*------------Mobile Change--------------------------- */
    case UPDATE_COMPLAINT_BY_FILTRATION:
      return { ...state };

    case GET_CALLBACKLEAD_DATA:
      return { ...state };
    case GET_CALLBACKLEADDATA_SUCCESS:
      return { ...state, leadCallbackData: action.data, loading: false };
    case GET_CALLBACKLEADDATA_FAILED:
      return { ...state };

    case SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER:
      return { ...state, sendingDocLink: true };

    case SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_SUCCESS:
      toast("", "Document Upload Link sent to customer !", "success");
      return { ...state, sendingDocLink: false };

    case SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_FAILED:
      return { ...state, sendingDocLink: false };

    case SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER:
      return { ...state, sendingDocLinkToPartner: true };

    case SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_SUCCESS:
      toast("", "Document Upload Link sent to partner !", "success");
      return { ...state, sendingDocLinkToPartner: false };

    case SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_FAILED:
      return { ...state, sendingDocLinkToPartner: false };

    case ADD_LEAD_PARTNER_COMMUNICATION:
      return { ...state };

    case ADD_LEAD_PARTNER_COMMUNICATION_SUCCESS:
      return { ...state, partnerCommunication: false };

    case ADD_LEAD_PARTNER_COMMUNICATION_FAILED:
      return { ...state };

    case SEND_ENACH_REGISTRATION_LINK:
      return { ...state };

    case SEND_ENACH_REGISTRATION_LINK_SUCCESS:
      if (action.data.success == false) toast("Error", action.data.message || "Something went wrong", "error");
      else toast("Success", "Link sent successfully", "success");
      return { ...state, enachData: action.data };
    case SEND_ENACH_REGISTRATION_LINK_FAILED:
      toast("Error", "Something went wrong", "error");
      return { ...state };

    case UPDATE_DOCUMENT_LEAD_FLAG:
      return { ...state };

    case UPDATE_DOCUMENT_LEAD_FLAG_SUCCESS:
      toast("Flag Updated!", "", "success");
      return { ...state };

    case UPDATE_DOCUMENT_LEAD_FLAG_FAILED:
      toast("", "Failed to update flag!", "error");
      return { ...state };

    case DOWNLOAD_DOCUMENT_LEAD_FLAG:
      return { ...state };

    case DOWNLOAD_DOCUMENT_LEAD_FLAG_SUCCESS:
      if (action.data?.length) {
        toast(`${action.data?.length} Documents found!`, "", "success");
      } else {
        toast("", "No data found!", "error");
      }
      return { ...state, documentPendingLeads: action.data };

    case DOWNLOAD_DOCUMENT_LEAD_FLAG_FAILED:
      toast("", "Failed to update flag!", "error");
      return { ...state };

    default:
      return { ...state };
  }
};

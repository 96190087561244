import axios from "axios";
import { agentApiPath, currentUser, partnerServicePath, servicePath } from "constants/defaultValues";
import { getCurrentUser, KNOWLARITY_CONFIGS, log, getStartDateAndEndDate, toast } from "helpers/Utils";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { bearerRequest, knowlarityPostApi, partnerRequest, request, servetelAPI } from "services/requests.services";
import { DOWNLOAD_DOCUMENT_LEAD_FLAG, DOWNLOAD_DOCUMENT_LEAD_FLAG_FAILED, ADD_LEAD_PARTNER_COMMUNICATION_FAILED, DOWNLOAD_DOCUMENT_LEAD_FLAG_SUCCESS, GET_CALLBACKLEADDATA_FAILED, GET_CALLBACKLEADDATA_SUCCESS, GET_EXPERT_NOTIFICATION_LEAD, GET_EXPERT_NOTIFICATION_LEAD_FAILED, GET_EXPERT_NOTIFICATION_LEAD_SUCCESS, LEAD_AND_USER_EMAIL_CHANGE_FAILED, LEAD_AND_USER_EMAIL_CHANGE_HISTORY_FAILED, LEAD_AND_USER_EMAIL_CHANGE_HISTORY_SUCCESS, LEAD_AND_USER_EMAIL_CHANGE_SUCCESS, SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER, SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_FAILED, SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_SUCCESS, SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER, SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_FAILED, SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_SUCCESS, SEND_ENACH_REGISTRATION_LINK_FAILED, SEND_ENACH_REGISTRATION_LINK_SUCCESS, UPDATE_DOCUMENT_LEAD_FLAG, UPDATE_DOCUMENT_LEAD_FLAG_FAILED, UPDATE_DOCUMENT_LEAD_FLAG_SUCCESS, ADD_LEAD_PARTNER_COMMUNICATION_SUCCESS, LEAD_AND_USER_MOB_CHANGE_FAILED, LEAD_AND_USER_MOB_CHANGE_SUCCESS, LEAD_AND_USER_MOB_CHANGE_HISTORY_FAILED, LEAD_AND_USER_MOB_CHANGE_HISTORY_SUCCESS } from "./Action";

function* getAssignUsers() {
  try {
    const data = yield bearerRequest("GET", `${agentApiPath}/assignUser`);

    yield put({ type: "LEAD_ASSIGN_USER_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    yield put({
      type: "LEAD_ASSIGN_USER_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getAssignExperts() {
  try {
    const data = yield bearerRequest("GET", `${agentApiPath}/assignExpert`);

    yield put({ type: "LEAD_ASSIGN_EXPERT_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    yield put({
      type: "LEAD_ASSIGN_EXPERT_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getInsuranceCompany() {
  try {
    const data = yield bearerRequest("GET", `${servicePath}/insurance_company`);

    yield put({ type: "LEAD_INSURANCE_COMPANY_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    yield put({
      type: "LEAD_INSURANCE_COMPANY_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getLeadDataByStatus(action) {
  try {
    const { status = "PENDING", pageIndex = 0, pageSize = 50, keyword = "", selectedSortOrder } = action.state;
    log(currentUser, currentUser.data?.userType);
    let data;
    if (!(currentUser.data?.userType === "admin" || currentUser.data?.userOperations === "HCR_EXECUTIVE")) {
      data = yield request("POST", `${servicePath}/lead/assignedStatus?page=${pageIndex + 1}`, { status: status, user_id: currentUser.data?.user_id });
      data = { data: { ...data.data, data: data?.data, totalRecords: data.data?.pager?.totalItems, list: data?.data?.pageOfItems } };
    } else if (currentUser.data?.userOperations === "HCR_EXECUTIVE") {
      data = yield request("GET", `${servicePath}/lead/hcrLeads?status=${status}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}${selectedSortOrder ? `&selectedSortOrder=${selectedSortOrder}` : ""}`);
    } else {
      data = yield request("GET", `${servicePath}/lead/?status=${status}&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}${selectedSortOrder ? `&selectedSortOrder=${selectedSortOrder}` : ""}`);
    }

    yield put({ type: "LEAD_DATA_WITH_STATUS_SUCCESS", data: currentUser.data?.userType === "admin" ? data.data : { list: data.data.list, totalRecords: data?.data?.totalRecords }, message: "All Data List !!", leadStatus: status, keyword: keyword });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_DATA_WITH_STATUS_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getLeadUsers() {
  try {
    const data = yield bearerRequest("GET", `${agentApiPath}/users`);

    yield put({ type: "LEAD_USERS_SUCCESS", data: data, message: data.message });
  } catch (error) {
    yield put({
      type: "LEAD_USERS_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getLeadDataByEmailPhone(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/searchByID`, { ...action.state });

    yield put({ type: "SEARCH_BY_MAIL_AND_PHONE_SUCCESS", data: data?.data, message: data.message });
  } catch (error) {
    yield put({
      type: "SEARCH_BY_MAIL_AND_PHONE_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* downloadLeadReport(action) {
  try {
    let user = getCurrentUser();
    const { startDateTimestampIST, endDateTimestampIST } = getStartDateAndEndDate(action.fromDate, action.tillDate);
    const source = action.leadSource ? action.leadSource : "";
    const data = yield axios({
      method: "GET",
      url: `${servicePath}/analytics/csv/lead?fromDate=${startDateTimestampIST}&tillDate=${endDateTimestampIST}&src=${source}`,
      headers: {
        Authorization: `${user?.data?.token || user?.token}`
      }
    });

    yield put({ type: "LEAD_DOWNLOAD_REPORT_SUCCESS", data: data?.data, message: data.message });
  } catch (error) {
    console.log(error);
    yield put({
      type: "LEAD_DOWNLOAD_REPORT_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getMissellingUser(action) {
  try {
    const data = yield request("POST", `${servicePath}/filtration/misselling/get`, { ...action.state });

    yield put({ type: "LEAD_GET_MISSELLING_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    yield put({
      type: "LEAD_GET_MISSELLING_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* saveAssignToUser(action) {
  try {
    log(action);
    const data = yield request("POST", `${servicePath}/lead/assign`, { ...action.state });

    yield put({ type: "LEAD_ASSIGN_USER_SAVE_SUCCESS", message: data.message });
  } catch (error) {
    yield put({
      type: "LEAD_ASSIGN_USER_SAVE_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* saveAssignToExpert(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/assignExpert`, { ...action.state });

    yield put({ type: "LEAD_ASSIGN_EXPERT_SAVE_SUCCESS", message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_ASSIGN_EXPERT_SAVE_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* acceptLead(action) {
  try {
    const { _id, ...other } = action.state;
    const data = yield request("PUT", `${servicePath}/lead/${_id}`, { ...other });
    if (data.success) {
      yield put({ type: "LEAD_ACCEPT_LEAD_SUCCESS", message: data.message });
    } else {
      yield put({ type: "LEAD_ACCEPT_LEAD_FAILED", message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_ACCEPT_LEAD_FAILED",
      message: "Failed to update data !"
    });
  }
}

function* rejectLead(action) {
  try {
    log(action);
    const { _id } = action.state;
    const data = yield request("PUT", `${servicePath}/lead/${_id}`, { ...action.state });

    yield put({ type: "LEAD_REJECT_LEAD_SUCCESS", message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_REJECT_LEAD_FAILED",
      message: error.response?.data?.message
    });
  }
}

function* updateLeadComment(action) {
  try {
    log(action);
    yield request("POST", `${servicePath}/lead/updateCommunication`, { ...action.state });

    yield put({
      type: "LEAD_COMM_HISTORY_UPDATE_COMMENT_SUCCESS",
      message: "Comment Updated !"
    });

    yield getLeadDataById({ state: { id: action.state?.lead_id } });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_COMM_HISTORY_UPDATE_COMMENT_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getLeadDataById(action) {
  try {
    const { id } = action.state;
    const data = yield request("GET", `${servicePath}/lead/${id}`);

    yield put({
      type: "LEAD_FETCH_BY_ID_SUCCESS",
      data: data.data[0],
      message: data.message
    });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_FETCH_BY_ID_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getSingleLead(action) {
  try {
    const { id } = action.state;

    if (id) {
      const data = yield request("GET", `${servicePath}/lead/getSingleLead/${id}`);

      yield put({ type: "LEAD_GET_SINGLE_DATA_SUCCESS", data: data.data, message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_GET_SINGLE_DATA_FAILED" });
  }
}

function* getInsCompanyList(action) {
  try {
    const { polId } = action.state;

    if (polId) {
      const data = yield request("GET", `${servicePath}/insurance_company/getByPolicyId?policyTypeId=${polId}`);

      yield put({ type: "LEAD_INS_COMPANY_LIST_SUCCESS", data: data.data, message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_INS_COMPANY_LIST_FAILED" });
  }
}

function* leadAddComment(action) {
  log(action);
  try {
    // const { id } = action.state;
    yield request("POST", `${servicePath}/lead/addComm`, [
      {
        com_by: action.state?.com_by,
        com_dis: action.state?.com_dis,
        id: action.state?.id,
        userType: action.state?.userType,
        externalComm: action.state?.externalComm
      }
    ]);

    yield put({
      type: "LEAD_COMM_HISTORY_ADD_COMMENT_SUCCESS",
      message: "Comment Added Successfully !"
    });

    yield getLeadDataById({ state: { id: action.state?.id } });
    if (action.state.prevStat) {
      if (action.state?.prevStat?.keyword) {
        yield getLeadDataByEmailPhone({ state: { keyword: action.state?.prevStat?.keyword } });
      } else {
        yield getLeadDataByStatus({ state: action.state?.prevStat });
      }
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_COMM_HISTORY_ADD_COMMENT_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getLeadFiltrationData(action) {
  try {
    const { filter } = action.state;
    const leadId = filter._id;

    if (leadId) {
      let data;
      if (filter.missellingFilterationId) {
        data = yield request("POST", `${servicePath}/filtration/misselling/get`, { leadId });
      } else if (filter.deathFilterationId) {
        data = yield request("POST", `${servicePath}/filtration/deathClaim/get`, { leadId });
      } else if (filter.healthFilterationId) {
        data = yield request("POST", `${servicePath}/filtration/hi/get`, { leadId });
      }
      yield put({ type: "LEAD_FILTRATION_DATA_SUCCESS", data: data?.data, message: data?.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_FILTRATION_DATA_FAILED" });
  }
}

function* getUserDetailsByLead(action) {
  try {
    const { leadId, userId } = action.state;
    log("LOGS", leadId, userId);
    if (leadId && userId) {
      const data = yield request("POST", `${servicePath}/userDetails/getUserByLeadUser`, { ...action.state });

      yield put({ type: "LEAD_GET_USERDETAILS_SUCCESS", data: data.data, message: data.message });
    } else if (!userId) {
      // throw new Error("Please fill up add details Form to create user");
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_GET_USERDETAILS_FAILED", message: error });
  }
}

function* getVictimData(action) {
  try {
    const { leadId, userId } = action.state;
    if (leadId || userId) {
      const data = yield request("POST", `${servicePath}/userDetails/getVictimDetails`, { ...action.state });

      yield put({ type: "LEAD_VICTIM_DETAILS_SUCCESS", data: data.data, message: data.message });
    } else if (!userId) {
      // throw new Error("Please fill up add details Form to create user");
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_VICTIM_DETAILS_FAILED", message: error });
  }
}

function* getHiFiltration(action) {
  const { leadId } = action.state;
  if (leadId) {
    try {
      const data = yield request("POST", `${servicePath}/filtration/hi/getAdminData`, { ...action.state });

      yield put({ type: "LEAD_HI_FILTRATION_SUCCESS", data: data.data, message: data.message });
    } catch (error) {
      log(error);
      yield put({ type: "LEAD_HI_FILTRATION_FAILED", message: error });
    }
  }
}

function* getSingleFilter(action) {
  try {
    const { id } = action.state;
    if (id) {
      const data = yield request("GET", `${servicePath}/filtration/hi/getSingleFilter/${id}`);

      yield put({ type: "LEAD_HI_SINGLE_FILTER_SUCCESS", data: data.data, message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_HI_SINGLE_FILTER_FAILED", message: error });
  }
}

function* saveLeadDetails(action) {
  try {
    const { leadId } = action.state;

    if (leadId) {
      const data = yield request("POST", `${servicePath}/lead/updateDetails`, { ...action.state });

      yield put({ type: "LEAD_HI_FILTRATION", state: { leadId } });
      yield put({ type: "LEAD_GET_SINGLE_DATA", state: { id: leadId } });
      yield put({ type: "LEAD_SAVE_DETAILS_SUCCESS", data: data.data, message: data.message });
    } else {
      throw new Error("Lead Id missing");
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SAVE_DETAILS_FAILED", message: error });
  }
}

function* savePolHolderDetails(action) {
  try {
    const { leadId, userId } = action.state;

    if (leadId && userId) {
      const data = yield request("POST", `${servicePath}/userDetails/createUpdateUser`, { ...action.state });

      yield put({ type: "LEAD_GET_SINGLE_DATA", state: { id: leadId } });
      yield put({ type: "LEAD_GET_USERDETAILS", state: { leadId, userId } });
      yield put({ type: "LEAD_SAVE_POL_HOLDER_DETAILS_SUCCESS", data: data.data, message: data.message });
    } else if (!userId) {
      throw new Error("Please fill up add details Form to create user");
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SAVE_POL_HOLDER_DETAILS_FAILED", message: error });
  }
}

function* saveComplaintDetails(action) {
  try {
    const { saveComplaint, addCommunication, leadId } = action.state;
    const data = yield request("POST", `${servicePath}/filtration/hi/addUpdateData`, { ...saveComplaint });

    const data2 = yield request("POST", `${servicePath}/lead/addComm`, [
      {
        ...addCommunication
      }
    ]);

    yield put({ type: "LEAD_ADD_COMMUNICATION_SUCCESS", data: data2.data, message: data2.message });
    yield put({ type: "LEAD_HI_FILTRATION", state: { leadId } });
    yield put({ type: "LEAD_SAVE_COMPLAINT_DETAILS_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SAVE_COMPLAINT_DETAILS_FAILED", message: error });
  }
}

function* addCommunication(action) {
  try {
    const { leadId, hide } = action.state;
    if (leadId) {
      const data = yield request("POST", `${servicePath}/lead/addComm`, [
        {
          id: leadId,
          com_by: action.state?.com_by,
          com_dis: action.state?.com_dis,
          userType: action.state?.userType,
          notification_key: action.state?.notification_key
        }
      ]);

      yield put({ type: "LEAD_ADD_COMMUNICATION_SUCCESS", data: data.data, message: data.message, hide });
    } else {
      throw new Error("Lead Id missing !");
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_ADD_COMMUNICATION_FAILED", message: "Lead Communication added" });
  }
}

function* updateFiltrationPassword(action) {
  try {
    const { userId } = action.state;
    if (userId) {
      const data = yield request("POST", `${servicePath}/lead/userPasswordUpdate`, { ...action.state });
      yield put({ type: "LEAD_UPDATE_FILT_PASSWORD_SUCCESS", data: data.data, message: data.message });
    } else {
      throw new Error("Please fill up add details Form to create user");
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_UPDATE_FILT_PASSWORD_FAILED", message: "lead update failed" });
  }
}

function* leadAddCommentParnter(action) {
  log(action);
  try {
    const { id, payload } = action.state;
    yield request("POST", `${servicePath}/lead/comment/add/${id}`, payload);
    yield put({
      type: "LEAD_COM_FOR_PARTNER_SUCCESS",
      message: "Comment Added Successfully !"
    });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_COM_FOR_PARTNER_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* finalHealthSubmit(action) {
  try {
    const { submitHealth, addCommunication, status } = action.state;
    if (submitHealth?.leadId) {
      const data = yield request("POST", `${servicePath}/filtration/hi/${submitHealth?.route}`, { ...submitHealth });

      if (data.status !== 200) {
        throw new Error(data.message);
      }

      yield put({ type: "LEAD_ADD_COMMUNICATION", state: { ...addCommunication, com_dis: data?.data?.filterProblemStatement, hide: true } });
      yield put({ type: "LEAD_SUBMIT_HEALTH_SUCCESS", data: data.data, message: data.message });
      yield put({ type: "LEAD_DATA_WITH_STATUS", state: { status } });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SUBMIT_HEALTH_FAILED", message: error });
  }
}

function* saveVictimData(action) {
  try {
    const { leadId, userId } = action.state;

    if (leadId && userId) {
      const data = yield request("POST", `${servicePath}/userDetails/createUpdateVictim`, { ...action.state });

      yield put({ type: "LEAD_VICTIM_DETAILS", state: { leadId, userId } });
      yield put({ type: "LEAD_GET_MISSELLING", state: { leadId } });
      yield put({ type: "LEAD_SAVE_VICTIM_DETAILS_SUCCESS", message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SAVE_VICTIM_DETAILS_FAILED" });
  }
}

function* saveFraudData(action) {
  try {
    const { leadId, userId } = action.state;

    if (leadId && userId) {
      const data = yield request("POST", `${servicePath}/userDetails/updateFraudDetails`, { ...action.state });

      yield put({ type: "LEAD_VICTIM_DETAILS", state: { leadId, userId } });
      yield put({ type: "LEAD_GET_MISSELLING", state: { leadId } });
      yield put({ type: "LEAD_SAVE_FRAUD_DETAILS_SUCCESS", message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SAVE_FRAUD_DETAILS_FAILED" });
  }
}

function* saveProofData(action) {
  try {
    const { leadId, userId } = action.state;

    if (leadId && userId) {
      const data = yield request("POST", `${servicePath}/userDetails/updateProofDetails`, { ...action.state });

      yield put({ type: "LEAD_VICTIM_DETAILS", state: { leadId, userId } });
      yield put({ type: "LEAD_GET_MISSELLING", state: { leadId } });
      yield put({ type: "LEAD_SAVE_PROOF_DETAILS_SUCCESS", message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SAVE_PROOF_DETAILS_FAILED" });
  }
}

function* finalMissellSubmit(action) {
  try {
    const { leadId, userId, missellId, route, status } = action.state;

    if (leadId && userId && missellId) {
      const data = yield request("POST", `${servicePath}/filtration/ms/${route}`, { ...action.state });

      if (data.status !== 200) {
        throw new Error(data.message);
      }

      yield put({ type: "LEAD_SUBMIT_MISSELL_SUCCESS", message: data.message, data: data.data });
      yield put({ type: "LEAD_DATA_WITH_STATUS", state: { status } });
    }
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_SUBMIT_MISSELL_FAILED", message: error });
  }
}

function* flushFiltration() {
  yield put({ type: "LEAD_FLUSH_FILTRATION_SUCCESS" });
}

function* getCallLogsCustomer(action) {
  try {
    const { customer_number } = action.state;

    const data = yield request("GET", `${servicePath}/callrecording/getCallRecording?phoneNumber=${customer_number}`);

    yield put({ type: "CALL_LOGS_FOR_CUSTOMER_SUCCESS", data: data.data?.recordingList, message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "CALL_LOGS_FOR_CUSTOMER_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getServetelLogsCustomer(action) {
  try {
    const { startTime, endTime, customer_number, page } = action.state;

    const data = yield servetelAPI("GET", `v1/call/records?from_date=${startTime} 00:00:00&to_date=${endTime} 23:59:59&callerid=${customer_number}&page=${page}`);

    yield put({ type: "CALL_LOGS_FOR_CUSTOMER_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "CALL_LOGS_FOR_CUSTOMER_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* clearCallLogsCustomer() {
  yield put({ type: "CALL_LOGS_FOR_CUSTOMER_CLEAR_SUCCESS" });
}

function* sendMessageToUser(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/sendSmsData`, { ...action.state });

    yield put({ type: "LEAD_SEND_MESSAGE_TO_USER_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_SEND_MESSAGE_TO_USER_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* cancelLead(action) {
  try {
    const data = yield request("PUT", `${servicePath}/lead/${action.state?.data?._id}`, { ...action.state.data });

    yield put({ type: "LEAD_CANCEL_LEAD_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_CANCEL_LEAD_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getPolicyType() {
  try {
    const data = yield request("GET", `${servicePath}/policy_type`);

    yield put({ type: "LEAD_GET_POLICY_TYPE_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_GET_POLICY_TYPE_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* getComplaintType(action) {
  try {
    const data = yield request("GET", `${servicePath}/complaint_type/?policyTypeId=${action.state.policyType}`);

    yield put({
      type: "LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_SUCCESS",
      data: data.data,
      message: data.message
    });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* updateLead(action) {
  log(action);
  try {
    const data = yield request("PUT", `${servicePath}/lead/${action.state?._id}`, { ...action.state });
    yield put({ type: "Lead_UPDATE_LEAD_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({
      type: "Lead_UPDATE_LEAD_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* addFollowUp(action) {
  try {
    let followUpObj = action.state;
    log(followUpObj.follow_date && followUpObj.com_date && followUpObj.id);

    if (followUpObj.follow_date && followUpObj.com_date && followUpObj.id) {
      const data = yield request("PUT", `${servicePath}/lead/update/${action.state?.id}`, {
        communication: [
          {
            id: followUpObj.id,
            com_date: followUpObj.com_date,
            com_dis: followUpObj.followUpDesc && followUpObj.com_dis == "Other" ? followUpObj.followUpDesc : followUpObj.com_dis,
            com_by: followUpObj.com_by
          }
        ],
        follow_date: followUpObj.follow_date,
        id: followUpObj.id,
        status: followUpObj.status
      });
      yield put({ type: "Lead_UPDATE_LEAD_SUCCESS", data: data.data, message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "Lead_UPDATE_LEAD_FAILED",
      message: "Failed to get data !"
    });
  }
}

function* addCompanyNoticeReply(action) {
  try {
    let followUpObj = action.state;
    log(followUpObj.company_notice_reply_date && followUpObj.company_notice_reply_type && followUpObj.id);

    if (followUpObj.company_notice_reply_date && followUpObj.company_notice_reply_type && followUpObj.id) {
      const data = yield request("POST", `${servicePath}/insurance/addCompanyReply/${action.state?.id}`, {
        company_notice_reply_date: followUpObj.company_notice_reply_date,
        company_notice_reply_type: followUpObj.company_notice_reply_type
      });
      yield put({ type: "ADD_COMPANY_NOTICE_REPLY", data: data.data, message: data.message });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "FAILED_COMPANY_NOTICE_REPLY",
      message: "Failed to Save data !"
    });
  }
}

function* setUploadDocForm(action) {
  yield put({ type: "LEAD_UPLOAD_DOCUMENT_FORM_SUCCESS", data: action.state });
}

function* docUploadFormChange(action) {
  yield put({ type: "LEAD_DOC_UPLOAD_FORM_CHANGE_SUCCESS", data: action.state });
}

function* setDocLoader(action) {
  yield put({ type: "LEAD_DOC_SET_LOADER_SUCCESS", data: action.state });
}

function* docUpload(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/docUpload`, { ...action.state, src: "AdminPanel" });
    yield put({ type: "LEAD_DOC_UPLOAD_FORM_SUCCESS", data: data.data, message: data.msg });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_DOC_UPLOAD_FORM_FAILED" });
  }
}

function* internalDocUpload(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/internalDocUpload`, { ...action.state, src: "AdminPanel" });
    yield put({ type: "LEAD_INTERNAL_DOC_UPLOAD_FORM_SUCCESS", data: data.data, message: data.msg });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_INTERNAL_DOC_UPLOAD_FORM_FAILED" });
  }
}

function* docUploadCombined(action) {
  try {
    yield request("POST", `${servicePath}/lead/docUpload`, { ...action.state, src: "AdminPanel" });
    yield request("POST", `${servicePath}/lead/internalDocUpload`, { ...action.state, src: "AdminPanel" });
    yield put({ type: "LEAD_DOC_UPLOAD_FORM_COMBINED_SUCCESS" });
    yield put({ type: "LEAD_DATA_WITH_STATUS", state: {} });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_DOC_UPLOAD_FORM_COMBINED_FAILED" });
    yield put({ type: "LEAD_DATA_WITH_STATUS", state: {} });
  }
}

function* caseDocUpload(action) {
  try {
    const { name, value, userId, leadId } = action.state;
    const formData = new FormData();
    formData.append(name, value);

    yield request("POST", `${servicePath}/lead/caseDoc/${userId}/${leadId}`, formData);
    yield put({ type: "LEAD_DOC_UPLOAD_FORM", state: action.state });
    yield put({ type: "LEAD_INTERNAL_DOC_UPLOAD_FORM", state: action.state });
    yield put({ type: "LEAD_DOC_UPLOAD_SUCCESS", data: action.state, isOtherDoc: action?.isOtherDoc });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_DOC_UPLOAD_FAILED", message: error.msg, data: { name: action.state.name }, isOtherDoc: action?.isOtherDoc });
  }
}

function* exeInternalFileUpload(action) {
  const { value, userId, leadId } = action.state;
  const formData = new FormData();
  formData.append("InternalDoc_", value);

  const data = yield request("POST", `${servicePath}/lead/caseDoc/${userId}/${leadId}`, formData);

  if (data?.Status == 500) {
    toast(data?.msg, "", "error");
    return;
  }
  yield put({ type: "EXE_INTERNAL_DOC_UPLOAD_SUCCESS", message: "Doc Uploaded !" });
}

function* allDocDownload(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/allDocDownload`, { ...action.state });

    if (!data.data) {
      throw new Error(`${data.msg}`);
    }

    yield put({ type: "LEAD_DOC_ZIP_SUCCESS", data: data.data, message: data.msg });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_DOC_ZIP_FAILED", message: error });
  }
}

function* docPassword(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/docPassword`, { ...action.state });

    yield put({ type: "LEAD_DOC_PASSWORD_SUCCESS", data: data.data, message: data.message });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_DOC_PASSWORD_FAILED", message: error });
  }
}

function* getInsuComs(action) {
  try {
    const data = yield request("GET", `${servicePath}/insurance_company/getByPolicyId?policyTypeId=${action.policyType}`);

    yield put({
      type: "LEAD_GET_INSURANCE_COMPANIES_SUCCESS",
      data: data.data
    });
  } catch (error) {
    yield put({
      type: "LEAD_GET_INSURANCE_COMPANIES_FAILED",
      message: "Failed to fetch Companies !!"
    });
  }
}

function* addIVRLead(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/add`, action.state);

    yield put({
      type: "LEAD_ADD_IVR_LEAD_SUCCESS",
      message: data.message
    });
  } catch (error) {
    yield put({
      type: "LEAD_ADD_IVR_LEAD_FAILED",
      message: "Failed to add Lead !!"
    });
  }
}

function* sendMessageToWhatsapp(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/yellowMessage`, action.state);
    if (data.status === 200) {
      yield put({
        type: "LEAD_SEND_WHATSAPP_MESSAGE_SUCCESS",
        message: data.msg
      });
    } else {
      yield put({
        type: "LEAD_SEND_WHATSAPP_MESSAGE_FAILED",
        message: data.msg
      });
    }
  } catch (error) {
    yield put({
      type: "LEAD_SEND_WHATSAPP_MESSAGE_FAILED",
      message: "Failed to send message !!"
    });
  }
}

function* leadMakeCall(action) {
  try {
    const data = yield knowlarityPostApi("POST", "account/call/makecall", {
      ...action.state,
      k_number: KNOWLARITY_CONFIGS.superReceptionistNumber
    });
    log(data);
    yield put({ type: "LEAD_MAKE_CALL_SUCCESS", data: data.data, message: data.data?.success?.message });
  } catch (error) {
    yield put({
      type: "LEAD_MAKE_CALL_FAILED",
      message: error.message
    });
  }
}

function* leadMakeServetelCall(action) {
  console.log(action);
  try {
    const data = yield servetelAPI("POST", "v1/click_to_call", {
      ...action.state
    });
    log(data);
    yield put({ type: "LEAD_MAKE_CALL_SUCCESS", data: data.data, message: data.data?.message });
  } catch (error) {
    yield put({
      type: "LEAD_MAKE_CALL_FAILED",
      message: error.message
    });
  }
}

function* exeWrong(action) {
  try {
    const data = yield request("GET", `${servicePath}/active/${action.status}`);
    yield put({ type: "LEAD_EXE_GET_SELECTED_DROPDOWN_SUCCESS", data: data.data, message: data.success?.message });
  } catch (error) {
    yield put({
      type: "LEAD_EXE_GET_SELECTED_DROPDOWN_FAILED",
      message: error.message
    });
  }
}

function* updateLeadForSecondNR(action) {
  try {
    const { status } = action.state;
    yield request("PUT", `${servicePath}/lead/${status}/update/${action.state.id}`, action.state);
  } catch (error) {
    log(error, action);
  }
}

function* exeLeadUpdate(action) {
  try {
    const data = yield request("PUT", `${servicePath}/lead/update/${action.state.id}`, action.state);
    yield put({ type: "LEAD_EXE_UPDATE_SUCCESS", message: data?.message, hide: action.state?.hide });
  } catch (error) {
    log(error, action);
    yield put({
      type: "LEAD_EXE_UPDATE_FAILED",
      message: error.response?.data?.message
    });
  }
}

function* exeAddDetails(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/addDetails`, action.state);
    yield put({ type: "LEAD_EXE_SAVE_ADD_DETAIL_SUCCESS", message: data?.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_EXE_SAVE_ADD_DETAIL_FAILED",
      message: error.message
    });
  }
}

function* exeRequestServiceRate(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/initiateServiceRateChangeRequest`, action.state);
    yield put({ type: "LEAD_EXE_SAVE_ADD_DETAIL_SUCCESS", message: data?.message });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_EXE_SAVE_ADD_DETAIL_FAILED",
      message: error.message
    });
  }
}

function* exeGenerateBotTrans(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/yellowTranscriptId`, action.state);
    if (data.status === 200) {
      yield put({ type: "LEAD_YELLOW_TRANSCRIPT_GENERATE_SUCCESS", message: data?.message });
    } else {
      yield put({
        type: "LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED",
        message: data.message
      });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED",
      message: error.message
    });
  }
}

function* exeSendYellowBotReport(action) {
  try {
    const data = yield request("POST", `${servicePath}/insurance/yellowTranscriptData`, action.state);
    if (data.status === 200) {
      yield put({ type: "LEAD_YELLOW_TRANSCRIPT_GENERATE_SUCCESS", message: "Report Downloaded Successfully !" });
    } else {
      yield put({
        type: "LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED",
        message: data.msg
      });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED",
      message: error.message
    });
  }
}

function* moveToPolifyxPending(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/polifyxPending`, action.state);
    if (data.status === 200) {
      yield put({ type: "LEAD_MOVE_TO_POLIFYX_PENDING_SUCCESS", message: data.message });
    } else {
      yield put({
        type: "LEAD_MOVE_TO_POLIFYX_PENDING_FAILED",
        message: data.msg ? data.msg : data.message
      });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_MOVE_TO_POLIFYX_PENDING_FAILED",
      message: error.message
    });
  }
}

function* moveToPending(action) {
  try {
    const data = yield request("POST", `${servicePath}/lead/moveToPending`, action.state);
    if (data.status === 200) {
      yield put({ type: "LEAD_MOVE_TO_PENDING_SUCCESS", message: data.message });
    } else {
      yield put({
        type: "LEAD_MOVE_TO_PENDING_FAILED",
        message: data.message
      });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_MOVE_TO_PENDING_FAILED",
      message: error.message
    });
  }
}

function* updateNRDetails(action) {
  try {
    const data = yield request("PUT", `${servicePath}/lead/updateNrDetails/${action.state?.id}`, action.state);
    if (data.success) {
      yield put({ type: "LEAD_UPDATE_NR_DETAILS_SUCCESS", message: data.message });
      // yield put({type:"LEAD_EXE_UPDATE", state:action.state})
    } else {
      yield put({
        type: "LEAD_UPDATE_NR_DETAILS_FAILED",
        message: data.message
      });
    }
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_UPDATE_NR_DETAILS_FAILED",
      message: error.message
    });
  }
}

function* getHiFiterationExe(action) {
  try {
    const data = yield request("POST", `${servicePath}/${action.state.url}`, { leadId: action.state.leadId });
    yield put({ type: "LEAD_EXE_GET_HI_FILTERATION_SUCCESS", data: data.data });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_EXE_GET_HI_FILTERATION_FAILED",
      message: error.message
    });
  }
}

function* userLeadCountFunc(action) {
  try {
    const data = yield request("GET", `${servicePath}/lead/getStatusLeadCount/count?user_id=${action.state?.user_id}`);
    yield put({ type: "EXE_STATUS_LEAD_COUNT_SUCCESS", data: data.data[0] });
  } catch (error) {
    log(error);
    yield put({
      type: "EXE_STATUS_LEAD_COUNT_FAILED",
      message: error.message
    });
  }
}

function* searchLeadFunc(action) {
  yield put({ type: "LEAD_SET_SEARCH_VALUE_SUCCESS", data: action.state?.value });
}

function* setActiveStatus(action) {
  yield put({ type: "LEAD_SET_CURRENT_STATUS_SUCCESS", data: action.status });
}

function* getDocVerPendingDoc(action) {
  try {
    const data = yield request("POST", `${servicePath}/app/document/list/admin`, action.state);
    let temp = data?.data?.map((res) => {
      return { label: res.name, value: res };
    });
    yield put({ type: "LEAD_DOC_VERIFICATION_PENDING_OPTIONS_SUCCESS", data: temp });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_DOC_VERIFICATION_PENDING_OPTIONS_FAILED",
      message: error.message
    });
  }
}

function* updateDocList(action) {
  try {
    const { ...other } = action.state;
    const data = yield request("POST", `${servicePath}/lead/update/documentlist`, other);

    yield put({ type: "LEAD_DOC_UPDATE_DOC_LIST_SUCCESS", data: data });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_DOC_UPDATE_DOC_LIST_FAILED",
      message: error.message
    });
  }
}

function* leadDeleteDoc(action) {
  try {
    yield request("POST", `${servicePath}/lead/removedoc`, action.state);

    yield put({ type: "LEAD_DELETE_DOCUMENT_SUCCESS" });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_DELETE_DOCUMENT_FAILED",
      message: error.message
    });
  }
}

function* leadCountByStatus() {
  try {
    //   let payload = {
    //     "user_id": action.state?.user_id || "",
    //     "KeyRefresh": true,
    //     "dailyAll": "Total"
    // }
    // const data = yield request(
    //   "POST",
    //   `${servicePath}/lead/countstatuswise`,
    //   payload
    // );
    // http://localhost:3999/lead/statusLeadCount/count?user_id=INSA_SATYAM1668752791403
    yield put({ type: "LEAD_COUNT_BY_STATUS_SUCCESS", data: {} });
  } catch (error) {
    log(error);
    yield put({
      type: "LEAD_COUNT_BY_STATUS_FAILED",
      message: error.message
    });
  }
}

function* fetchPartnerComm(action) {
  try {
    const { data } = yield partnerRequest("GET", `${partnerServicePath}/lead/comment/get?leadId=${action.state?.id}`);
    yield put({ type: "LEAD_PARTNER_COMMUNICATION_SUCCESS", data: data.data || [] });
  } catch (error) {
    log(error);
    yield put({ type: "LEAD_PARTNER_COMMUNICATION_FAILED", message: "Failed to get data !" });
  }
}

function* getNotificationLeads(action) {
  try {
    const data = yield request("POST", `${servicePath}/notification`, { ...action.state });
    yield put({
      type: GET_EXPERT_NOTIFICATION_LEAD_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: GET_EXPERT_NOTIFICATION_LEAD_FAILED,
      message: "Failed to get notificatios data !"
    });
  }
}

function* leadAndUserEmailChange(action) {
  const payload = action.state;
  try {
    const data = yield request("PATCH", `${servicePath}/lead/changeleadanduseremail`, payload);
    if (data?.message === "Email ID already exists") {
      toast("Warning", "Email Already Exists", "warning");
      return;
    }
    yield put({
      type: LEAD_AND_USER_EMAIL_CHANGE_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: LEAD_AND_USER_EMAIL_CHANGE_FAILED,
      message: "Failed to change email !",
      error
    });
  }
}

function* leadAndUserEmailHistory(action) {
  try {
    const data = yield request("GET", `${servicePath}/lead/getemailhistory?leadId=${action.state}`);
    yield put({
      type: LEAD_AND_USER_EMAIL_CHANGE_HISTORY_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: LEAD_AND_USER_EMAIL_CHANGE_HISTORY_FAILED,
      message: "Failed to fetch email history!"
    });
  }
}

{
  /*------------- MobileChange---------------------*/
}
function* leadAndUserMobileChange(action) {
  const payload = action.state;

  try {
    const data = yield request("PATCH", `${servicePath}/lead/changeLeadAndUserMobile`, payload);

    if (data?.message === "Mobile number already exists") {
      toast("Warning", "Mobile Number is Already Exists", "warning");
      return;
    }
    if (data?.message === "Admin pin does not match") {
      toast("Warning", "Admin pin does not match", "warning");
      return;
    }
    if (data?.message === "Please provide valid mobile number") {
      toast("Warning", "Please provide valid mobile number", "warning");
      return;
    }
    yield put({
      type: LEAD_AND_USER_MOB_CHANGE_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: LEAD_AND_USER_MOB_CHANGE_FAILED,
      message: "Falied to change mobile number!",
      error
    });
  }
}

function* leadAndUserMobileHistory(action) {
  try {
    const data = yield request("GET", `${servicePath}/lead/getphonehistory?leadId=${action.state}`);

    yield put({
      type: LEAD_AND_USER_MOB_CHANGE_HISTORY_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: LEAD_AND_USER_MOB_CHANGE_HISTORY_FAILED,
      message: "Falied to fetch mobile number history!"
    });
  }
}
{
  /*------------- MobileChange---------------------*/
}
function* updateComplaintByFiltration(action) {
  try {
    let payload = action.payload;
    yield request("POST", `${servicePath}/insurance/updateComplaintByFiltration`, payload);
  } catch (error) {
    console.log(error);
  }
}

function* getLeadCallbackData(action) {
  const { pageIndex, pageSize, id } = action.state;
  try {
    const data = yield request("POST", `${servicePath}/lead/callBackLeadData/?pageIndex=${pageIndex}&pageSize=${pageSize}${id ? `&id=${id}` : ""}`);
    yield put({
      type: GET_CALLBACKLEADDATA_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: GET_CALLBACKLEADDATA_FAILED,
      message: "Failed to fetch callback Data!"
    });
  }
}

function* sendDocLinkToUserAsync(action) {
  try {
    const { id } = action.data;
    yield request("GET", `${servicePath}/lead/senddocumentcommonurl/${id}`);
    yield put({
      type: SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_SUCCESS,
      message: "Comman Document Upload Link Sent !"
    });
  } catch (error) {
    yield put({
      type: SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_FAILED,
      message: "Failed to send link to user !"
    });
  }
}

function* leadPartnerCommunication(action) {
  try {
    const { id, payload } = action.state;
    const data = yield request("POST", `${servicePath}/lead/comment/add/${id}`, payload);
    yield put({
      type: ADD_LEAD_PARTNER_COMMUNICATION_SUCCESS,
      data: data.data
    });
  } catch (error) {
    yield put({
      type: ADD_LEAD_PARTNER_COMMUNICATION_FAILED,
      message: "Failed to send communication"
    });
  }
}

function* sendDocLinkToPartner(action) {
  try {
    const { id } = action.data;
    yield request("GET", `${servicePath}/lead/senddocumentcommonurl/partner/${id}`);
    yield put({
      type: SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_SUCCESS,
      message: "Comman Document Upload Link Sent !"
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_FAILED,
      message: "Failed to send link to user !"
    });
  }
}

function* sendEnachLink(action) {
  try {
    const data = yield request("POST", `${servicePath}/emandate/enachRegistrationLink`, action.state);
    yield put({
      type: SEND_ENACH_REGISTRATION_LINK_SUCCESS,
      data: data
    });
  } catch (error) {
    yield put({
      type: SEND_ENACH_REGISTRATION_LINK_FAILED,
      message: "Failed to send e-nach link"
    });
  }
}

function* updateDocumentLeadFlagAsync(action) {
  const { leadId, ...payload } = action.payload;
  try {
    yield request("POST", `${servicePath}/lead/documentPendingFlag/${leadId}`, payload);
    yield put({
      type: UPDATE_DOCUMENT_LEAD_FLAG_SUCCESS,
      message: "Flag Updated!"
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: UPDATE_DOCUMENT_LEAD_FLAG_FAILED,
      message: "Failed to update flag"
    });
  }
}

function* downloadDocumentLeadFlagAsync(action) {
  try {
    const data = yield request("GET", `${servicePath}/lead/getDocumentPendingLeads/${action.state}`);
    yield put({
      type: DOWNLOAD_DOCUMENT_LEAD_FLAG_SUCCESS,
      data: data?.data
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: DOWNLOAD_DOCUMENT_LEAD_FLAG_FAILED,
      message: "Failed to update flag"
    });
  }
}

function* sendDocLinkToUser() {
  return yield takeLatest(SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER, sendDocLinkToUserAsync);
}

function* sendDocUploadLinkToPartner() {
  return yield takeLatest(SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER, sendDocLinkToPartner);
}

function* updateDocumentLeadFlag() {
  return yield takeLatest(UPDATE_DOCUMENT_LEAD_FLAG, updateDocumentLeadFlagAsync);
}

function* downloadDocumentLeadFlag() {
  return yield takeLatest(DOWNLOAD_DOCUMENT_LEAD_FLAG, downloadDocumentLeadFlagAsync);
}

function* removeDocumentFlag(action) {
  if (!action?.state?.id) {
    toast("Something went wrong !", " ", "error");
    return;
  }
  try {
    const data = yield request("GET", `${servicePath}/lead/removeDocumentPendingFlag/${action.state?.id}`);
    if (data?.success) {
      toast("Flag removed !", "", "success");
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* leadSaga() {
  yield takeEvery("LEAD_ASSIGN_USER", getAssignUsers);
  yield takeEvery("LEAD_ASSIGN_EXPERT", getAssignExperts);
  yield takeEvery("LEAD_INSURANCE_COMPANY", getInsuranceCompany);
  yield takeEvery("LEAD_DATA_WITH_STATUS", getLeadDataByStatus);
  yield takeEvery("LEAD_USERS", getLeadUsers);
  yield takeEvery("SEARCH_BY_MAIL_AND_PHONE", getLeadDataByEmailPhone);
  yield takeEvery("LEAD_DOWNLOAD_REPORT", downloadLeadReport);
  yield takeEvery("LEAD_GET_MISSELLING", getMissellingUser);
  yield takeEvery("LEAD_ASSIGN_USER_SAVE", saveAssignToUser);
  yield takeEvery("LEAD_ASSIGN_EXPERT_SAVE", saveAssignToExpert);
  yield takeEvery("LEAD_ACCEPT_LEAD", acceptLead);
  yield takeEvery("LEAD_REJECT_LEAD", rejectLead);
  yield takeEvery("LEAD_COMM_HISTORY_UPDATE_COMMENT", updateLeadComment);
  yield takeEvery("LEAD_FETCH_BY_ID", getLeadDataById);
  yield takeEvery("LEAD_GET_SINGLE_DATA", getSingleLead);
  yield takeEvery("LEAD_COMM_HISTORY_ADD_COMMENT", leadAddComment);
  yield takeEvery("LEAD_FILTRATION_DATA", getLeadFiltrationData);
  yield takeEvery("CALL_LOGS_FOR_CUSTOMER", getCallLogsCustomer);
  yield takeEvery("SERVETEL_LOGS_FOR_CUSTOMER", getServetelLogsCustomer);
  yield takeEvery("CALL_LOGS_FOR_CUSTOMER_CLEAR", clearCallLogsCustomer);
  yield takeEvery("LEAD_SEND_MESSAGE_TO_USER", sendMessageToUser);
  yield takeEvery("LEAD_CANCEL_LEAD", cancelLead);
  yield takeEvery("LEAD_GET_POLICY_TYPE", getPolicyType);
  yield takeEvery("LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE", getComplaintType);
  yield takeEvery("Lead_UPDATE_LEAD", updateLead);
  yield takeEvery("LEAD_ADD_FOLLOW_UP", addFollowUp);
  yield takeEvery("LEAD_UPLOAD_DOCUMENT_FORM", setUploadDocForm);
  yield takeEvery("LEAD_DOC_UPLOAD_FORM_CHANGE", docUploadFormChange);
  yield takeEvery("LEAD_DOC_UPLOAD_FORM", docUpload);
  yield takeEvery("LEAD_INTERNAL_DOC_UPLOAD_FORM", internalDocUpload);
  yield takeEvery("LEAD_DOC_UPLOAD_FORM_COMBINED", docUploadCombined);
  yield takeEvery("LEAD_DOC_UPLOAD", caseDocUpload);
  yield takeEvery("LEAD_DOC_SET_LOADER", setDocLoader);
  yield takeEvery("LEAD_DOC_ZIP", allDocDownload);
  yield takeEvery("LEAD_DOC_PASSWORD", docPassword);
  yield takeEvery("LEAD_INS_COMPANY_LIST", getInsCompanyList);
  yield takeEvery("LEAD_GET_USERDETAILS", getUserDetailsByLead);
  yield takeEvery("LEAD_HI_FILTRATION", getHiFiltration);
  yield takeEvery("LEAD_HI_SINGLE_FILTER", getSingleFilter);
  yield takeEvery("LEAD_SAVE_DETAILS", saveLeadDetails);
  yield takeEvery("LEAD_SAVE_POL_HOLDER_DETAILS", savePolHolderDetails);
  yield takeEvery("LEAD_SAVE_COMPLAINT_DETAILS", saveComplaintDetails);
  yield takeEvery("LEAD_ADD_COMMUNICATION", addCommunication);
  yield takeEvery("LEAD_UPDATE_FILT_PASSWORD", updateFiltrationPassword);
  yield takeEvery("LEAD_SUBMIT_HEALTH", finalHealthSubmit);
  yield takeEvery("LEAD_FLUSH_FILTRATION", flushFiltration);
  yield takeEvery("LEAD_VICTIM_DETAILS", getVictimData);
  yield takeEvery("LEAD_SAVE_VICTIM_DETAILS", saveVictimData);
  yield takeEvery("LEAD_SAVE_FRAUD_DETAILS", saveFraudData);
  yield takeEvery("LEAD_SAVE_PROOF_DETAILS", saveProofData);
  yield takeEvery("LEAD_SUBMIT_MISSELL", finalMissellSubmit);
  yield takeEvery("LEAD_GET_INSURANCE_COMPANIES", getInsuComs);
  yield takeEvery("LEAD_ADD_IVR_LEAD", addIVRLead);
  yield takeEvery("LEAD_SEND_WHATSAPP_MESSAGE", sendMessageToWhatsapp);
  yield takeEvery("LEAD_MAKE_CALL", leadMakeCall);
  yield takeEvery("LEAD_MAKE_SERVETEL_CALL", leadMakeServetelCall);
  yield takeEvery("LEAD_EXE_GET_SELECTED_DROPDOWN", exeWrong);
  yield takeEvery("LEAD_EXE_UPDATE", exeLeadUpdate);
  yield takeEvery("LEAD_EXE_SAVE_ADD_DETAIL", exeAddDetails);
  yield takeEvery("LEAD_EXE_REQUEST_SERVICE_RATE", exeRequestServiceRate);
  yield takeEvery("LEAD_YELLOW_TRANSCRIPT_GENERATE", exeGenerateBotTrans);
  yield takeEvery("LEAD_YELLOW_TRANSCRIPT_SEND", exeSendYellowBotReport);
  yield takeEvery("LEAD_MOVE_TO_POLIFYX_PENDING", moveToPolifyxPending);
  yield takeEvery("LEAD_MOVE_TO_PENDING", moveToPending);
  yield takeEvery("LEAD_UPDATE_NR_DETAILS", updateNRDetails);
  yield takeEvery("LEAD_EXE_GET_HI_FILTERATION", getHiFiterationExe);
  yield takeEvery("EXE_INTERNAL_DOC_UPLOAD", exeInternalFileUpload);
  yield takeEvery("EXE_STATUS_LEAD_COUNT", userLeadCountFunc);
  yield takeEvery("LEAD_SET_SEARCH_VALUE", searchLeadFunc);
  yield takeEvery("LEAD_SET_CURRENT_STATUS", setActiveStatus);
  yield takeEvery("LEAD_DOC_VERIFICATION_PENDING_OPTIONS", getDocVerPendingDoc);
  yield takeEvery("LEAD_DOC_UPDATE_DOC_LIST", updateDocList);
  yield takeEvery("LEAD_DELETE_DOCUMENT", leadDeleteDoc);
  yield takeEvery("LEAD_EXE_UPDATE_NR_SECOND", updateLeadForSecondNR);
  yield takeEvery("LEAD_COUNT_BY_STATUS", leadCountByStatus);
  yield takeEvery("LEAD_PARTNER_COMMUNICATION", fetchPartnerComm);
  yield takeEvery(GET_EXPERT_NOTIFICATION_LEAD, getNotificationLeads);
  yield takeEvery("LEAD_AND_USER_EMAIL_CHANGE", leadAndUserEmailChange);
  yield takeEvery("LEAD_AND_USER_EMAIL_CHANGE_HISTORY", leadAndUserEmailHistory);
  /*-----------------------------------Mobile Number Change-----------------------------------------*/
  yield takeEvery("LEAD_AND_USER_MOB_CHANGE", leadAndUserMobileChange);
  yield takeEvery("LEAD_AND_USER_MOB_CHANGE_HISTORY", leadAndUserMobileHistory);
  /*----------------------------------------------------------------------------*/
  yield takeLatest("UPDATE_COMPLAINT_BY_FILTRATION", updateComplaintByFiltration);
  yield takeEvery("GET_CALLBACKLEAD_DATA", getLeadCallbackData);
  yield takeEvery("LEAD_COM_FOR_PARTNER", leadAddCommentParnter);
  yield takeEvery("ADD_LEAD_PARTNER_COMMUNICATION", leadPartnerCommunication);
  yield takeLatest("SEND_ENACH_REGISTRATION_LINK", sendEnachLink);
  yield takeLatest("REMOVE_DOCUMENT_PENDING_MARK", removeDocumentFlag);
  yield takeEvery("ADD_COMPANY_NOTICE_REPLY", addCompanyNoticeReply);

  yield all([call(sendDocLinkToUser), call(sendDocUploadLinkToPartner), call(updateDocumentLeadFlag), call(downloadDocumentLeadFlag)]);
}

import { toast } from "helpers/Utils";

import { DOCUMENT_UPLOAD_VERIFY_USER, DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS, DOCUMENT_UPLOAD_VERIFY_USER_FAILED, DOCUMENT_HCR_UPLOAD_VERIFY_USER, DOCUMENT_HCR_UPLOAD_VERIFY_USER_SUCCESS, DOCUMENT_HCR_UPLOAD_VERIFY_USER_FAILED, DOCUMENT_UPLOAD_VERIFY_OTP, DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS, DOCUMENT_UPLOAD_VERIFY_OTP_FAILED, DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE, DOCUMENT_UPLOAD_GET_DOCUMENT_LIST, DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_SUCCESS, DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_FAILED, DOCUMENT_UPLOAD_UPLOAD_FILE, DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS, DOCUMENT_UPLOAD_UPLOAD_FILE_FAILED, DOCUMENT_UPLOAD_DELETE_SINGLE_DOC, DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_SUCCESS, DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_FAILED, DOCUMENT_UPLOAD_GET_SINGLE_LEAD, DOCUMENT_UPLOAD_GET_SINGLE_LEAD_SUCCESS, DOCUMENT_UPLOAD_GET_SINGLE_LEAD_FAILED, DOCUMENT_UPLOAD_SUBMIT_ACTION, DOCUMENT_UPLOAD_SUBMIT_ACTION_SUCCESS, DOCUMENT_UPLOAD_SUBMIT_ACTION_FAILED, DOCUMENT_UPLOAD_UPLOAD_FILE_LOADER, DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_SUCCESS, DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_FAILED } from "./Action";

const initValues = {
  loading: true,
  loadingForCommonDocUpload: false,
  leadData: {},
  hcrLeadData: {},
  validUser: "",
  otpVerified: false,
  docList: [],
  phoneNumber: "",
  doc: [],
  deletedDocuemntList: [],
  documents: {
    PolicyDoc: [],
    ClaimForm: [],
    RejectionLetter: [],
    surveyorReportArray: [],
    otherDocs: [],
    dischargeDocArray: [],
    DischargeSumm: [],
    EmailExc: [],
    settlementArray: [],
    CommunicationCompany: [],
    callRecord: [],
    PreviousPolicyHi: [],
    DrReportLi: [],
    rtpcrTestArray: [],
    DeathCertificate: [],
    FIR: [],
    PostMortemReport: [],
    policySchedulePageArray: [],
    DrCertificateHi: []
  },
  deletedDocLength: 0,
  docLength: 0,
  checkDeletedDocLength: true,
  checkDocLength: true,
  finalSubmit: false,
  docUploadArr: [],
  docDeleteArr: [],
  isVerifyed: false,
  pageExist: false
};

export default (state = initValues, action) => {
  switch (action.type) {
    case DOCUMENT_UPLOAD_VERIFY_USER:
      return { ...state, loading: true };

    case DOCUMENT_UPLOAD_VERIFY_USER_SUCCESS:
      return { ...state, loading: false, validUser: action.valid, leadData: action.leadData };

    case DOCUMENT_UPLOAD_VERIFY_USER_FAILED:
      return { ...state, loading: false, validUser: action.valid };

    case DOCUMENT_HCR_UPLOAD_VERIFY_USER:
      return { ...state, loading: true };

    case DOCUMENT_HCR_UPLOAD_VERIFY_USER_SUCCESS:
      return { ...state, loading: false, validUser: action.valid, hcrLeadData: action.hcrLeadData };

    case DOCUMENT_HCR_UPLOAD_VERIFY_USER_FAILED:
      return { ...state, loading: false, validUser: action.valid, hcrLeadData: null };

    case DOCUMENT_UPLOAD_VERIFY_OTP:
      return { ...state, loadingForCommonDocUpload: true, loading: true };

    case DOCUMENT_UPLOAD_VERIFY_OTP_SUCCESS:
      // toast("OTP Verified !", "", "success");
      return { ...state, otpVerified: true, loadingForCommonDocUpload: false, loading: false, isVerifyed: action.isVerifyed, leadData: action.leadData, pageExist: false };

    case DOCUMENT_UPLOAD_VERIFY_OTP_FAILED:
      // toast("", "Invalid Phone Number !", "error");
      return { ...state, otpVerified: false, loadingForCommonDocUpload: false, loading: false, isVerifyed: false, pageExist: true };

    case DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_SUCCESS:
      // toast("OTP Verified !", "", "success");
      return { ...state, otpVerified: true, loading: false, isVerifyed: action.isVerifyed, leadData: action.leadData };

    case DOCUMENT_UPLOAD_VERIFY_USER_BY_PHONE_FAILED:
      toast("", "Invalid !", "error");
      return { ...state, otpVerified: false, loading: false, isVerifyed: false };

    case DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE: {
      let { phoneNumber } = action.state;
      return { ...state, phoneNumber };
    }

    case "DOCUMENT_UPLOAD_HANDLE_OTP_CHANGE_MANUAL": {
      let { otpValue } = action.state;
      return { ...state, otpValue };
    }

    case DOCUMENT_UPLOAD_GET_DOCUMENT_LIST:
      return { ...state };

    case DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_SUCCESS:
      return { ...state, docList: action.data, message: action.message };

    case DOCUMENT_UPLOAD_GET_DOCUMENT_LIST_FAILED:
      return { ...state, message: action.message };

    case DOCUMENT_UPLOAD_UPLOAD_FILE_LOADER:
      return { ...state, loading: true };

    case DOCUMENT_UPLOAD_UPLOAD_FILE:
      return {
        ...state,
        loading: true,
        docUploadArr: [...(state?.docUploadArr || []), action?.state?.name]
      };

    case DOCUMENT_UPLOAD_UPLOAD_FILE_SUCCESS: {
      if (action.data.isUpload) {
        toast("", "Document Uploaded Sucessfully !", "success");
      }
      let doc = action.data?.docUploadByLink;
      let documents = {
        PolicyDoc: [],
        ClaimForm: [],
        RejectionLetter: [],
        surveyorReportArray: [],
        otherDocs: [],
        dischargeDocArray: [],
        DischargeSumm: [],
        EmailExc: [],
        settlementArray: [],
        CommunicationCompany: [],
        callRecord: [],
        PreviousPolicyHi: [],
        DrReportLi: [],
        rtpcrTestArray: [],
        DeathCertificate: [],
        FIR: [],
        PostMortemReport: [],
        policySchedulePageArray: [],
        DrCertificateHi: [],
        ClaimBill: [],
        FinalBill: [],
        ClaimFormLi: [],
        "Final-Bills": [],
        PolicyBook: [],
        PolicyBookHI: [],
        PolicySh: [],
        PolicyCopy: [],
        PolicyDocHI: [],
        RejectionLetterLi: [],
        "Rejection-Letter": [],
        SurveyorReport: [],
        generalotherdocs: [],
        healthotherdocs: [],
        LifeIns: [],
        DischargeDoc: [],
        "Indoor-Hospital": [],
        DischargeSummLi: [],
        DischargeSummGi: [],
        "Discharge-Summary": [],
        ProofOfRecord: [],
        "Other-Document": [],
        SettlementLetter: [],
        SettlementLetterHi: [],
        CommunicationCompanyHi: [],
        CommunicationCompanyLi: [],
        "Mail-Communication": [],
        CallRecording: [],
        CallRecordingHi: [],
        CallRecordingLi: [],
        "Video-Audio-Proof": [],
        "Previous-Policy": [],
        DrReportGi: [],
        "Doctor-Certificate": [],
        DeathCertificateHi: [],
        DeathCertificateGi: [],
        FIRGi: [],
        FIRHi: [],
        PostMortemReportGi: [],
        PostMortemReportHi: [],
        PolicySchedulePageLI: [],
        PolicySchedulePageGI: [],
        PolicySchedulePageHI: [],
        surveyorReport: [],
        PolicyDocHi: []
      };
      for (let i = 0; i < doc.length; i++) {
        if (doc[i]) {
          {
            let singleDoc = doc[i].split("_");
            if (singleDoc[0] == "ClaimBill" || singleDoc[0] == "ClaimForm" || singleDoc[0] == "FinalBill" || singleDoc[0] == "ClaimFormLi" || singleDoc[0] == "Final-Bills") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "PolicyBook" || singleDoc[0] == "PolicyBookHI" || singleDoc[0] == "PolicySh" || singleDoc[0] == "PolicyCopy" || singleDoc[0] == "PolicyDocHI" || singleDoc[0] == "PolicyDoc") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "PolicyDocHi") {
              documents?.PolicyDocHi?.push(doc[i]);
            } else if (singleDoc[0] == "RejectionLetter" || singleDoc[0] == "RejectionLatter" || singleDoc[0] == "RejectionLetterLi" || singleDoc[0] == "Rejection-Letter") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "SurveyorReport" || singleDoc[0] == "surveyorReport") {
              documents.surveyorReport.push(doc[i]);
            } else if (singleDoc[0] == "generalotherdocs" || singleDoc[0] == "healthotherdocs" || singleDoc[0] == "LifeIns" || singleDoc[0] == "otherDocs") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "DischargeDoc" || singleDoc[0] == "Indoor-Hospital") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "DischargeSumm" || singleDoc[0] == "DischargeSummLi" || singleDoc[0] == "DischargeSummGi" || singleDoc[0] == "Discharge-Summary") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "EmailExc" || singleDoc[0] == "ProofOfRecord" || singleDoc[0] == "Other-Document") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "SettlementLetter" || singleDoc[0] == "SettlementLetterHi") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "CommunicationCompany" || singleDoc[0] == "CommunicationCompanyHi" || singleDoc[0] == "CommunicationCompanyLi" || singleDoc[0] == "Mail-Communication") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "CallRecording" || singleDoc[0] == "CallRecordingHi" || singleDoc[0] == "CallRecordingLi" || singleDoc[0] == "Video-Audio-Proof") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "callRecord") {
              documents.callRecord.push(doc[i]);
            } else if (singleDoc[0] == "PreviousPolicyHi" || singleDoc[0] == "Previous-Policy") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "DrCertificateHi" || singleDoc[0] == "DrReportLi" || singleDoc[0] == "DrReportGi" || singleDoc[0] == "Doctor-Certificate") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "RT") {
              documents.rtpcrTestCheck = true;
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "DeathCertificate" || singleDoc[0] == "DeathCertificateHi" || singleDoc[0] == "DeathCertificateGi") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "FIR" || singleDoc[0] == "FIRGi" || singleDoc[0] == "FIRHi") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "PostMortemReport" || singleDoc[0] == "PostMortemReportGi" || singleDoc[0] == "PostMortemReportHi") {
              documents[singleDoc[0]]?.push(doc[i]);
            } else if (singleDoc[0] == "PolicySchedulePageLI" || singleDoc[0] == "PolicySchedulePageGI" || singleDoc[0] == "PolicySchedulePageHI") {
              documents[singleDoc[0]]?.push(doc[i]);
            }
          }
        }
      }
      let { deletedDocLength, docLength, checkDeletedDocLength, checkDocLength } = state || {};
      if (checkDocLength) {
        docLength = action.data?.doc?.length;
      }
      if (checkDeletedDocLength) {
        deletedDocLength = action.data.deletedDocuemntList?.length;
      }
      return { ...state, doc: action.data?.doc, checkDeletedDocLength: false, checkDocLength: false, loading: false, documents: documents, deletedDocuemntList: action.data.deletedDocuemntList, docLength, deletedDocLength };
    }

    case DOCUMENT_UPLOAD_UPLOAD_FILE_FAILED:
      toast("", "Failed to Upload Document !", "error");
      return { ...state, loading: false };

    case DOCUMENT_UPLOAD_DELETE_SINGLE_DOC:
      return { ...state, loading: true, docDeleteArr: [...state.docDeleteArr, action.state?.docName] };

    case DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_SUCCESS:
      return { ...state, loading: false, deletedDocuemntList: action.data?.deletedDocuemntList };

    case DOCUMENT_UPLOAD_DELETE_SINGLE_DOC_FAILED:
      return { ...state, loading: false };

    case DOCUMENT_UPLOAD_GET_SINGLE_LEAD:
      return { ...state, loading: true };

    case DOCUMENT_UPLOAD_GET_SINGLE_LEAD_SUCCESS:
      return { ...state, loading: false, leadData: action.data };

    case DOCUMENT_UPLOAD_GET_SINGLE_LEAD_FAILED:
      return { ...state, loading: false };

    case DOCUMENT_UPLOAD_SUBMIT_ACTION:
      return { ...state, loading: true };

    case DOCUMENT_UPLOAD_SUBMIT_ACTION_SUCCESS:
      window.localStorage.removeItem("document_upload_user");
      window.location.href = "/document/thank-you";
      return { ...state, loading: false, finalSubmit: true };

    case DOCUMENT_UPLOAD_SUBMIT_ACTION_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

import { toast } from "helpers/Utils";
import { CREATE_CRON_CONFIG, CREATE_CRON_CONFIG_FAILED, CREATE_CRON_CONFIG_SUCCESS, CRON_JOB_GET_DATA_LIST_START, CRON_JOB_GET_DATA_LIST_START_FAILED, CRON_JOB_GET_DATA_LIST_START_SUCCESS, CRON_JOB_UPDATE_ACTION_START, CRON_JOB_UPDATE_ACTION_START_FAILED, CRON_JOB_UPDATE_ACTION_START_SUCCESS, HANDLE_CRON_EXECUTION, HANDLE_CRON_EXECUTION_FAILED, HANDLE_CRON_EXECUTION_SUCCESS, SEARCH_CRON, SEARCH_CRON_FAILED, SEARCH_CRON_SUCCESS } from "./Actions";

const initValues = {
  loading: false,
  listData: [],
  executionLoading: false,
  toggleButtonLoading: false,
  searchLoading: false,
  createLoading: false
};

export default (state = initValues, action) => {
  let sortedList = (action.data?.data || []).sort((a, b) => b.isActive - a.isActive);
  switch (action.type) {
    case CRON_JOB_GET_DATA_LIST_START:
      return { ...state, loading: true };

    case CRON_JOB_GET_DATA_LIST_START_SUCCESS:
      return { ...state, loading: false, listData: sortedList || [], totalCount: action.data?.totalCount };

    case CRON_JOB_GET_DATA_LIST_START_FAILED:
      return { ...state, loading: false };

    case CRON_JOB_UPDATE_ACTION_START:
      return { ...state, toggleButtonLoading: true };

    case CRON_JOB_UPDATE_ACTION_START_SUCCESS:
      toast("", "Description Updated !", "success");
      return { ...state, toggleButtonLoading: false };

    case CRON_JOB_UPDATE_ACTION_START_FAILED:
      toast("", "Failed to Update !", "error");
      return { ...state, toggleButtonLoading: false };

    case HANDLE_CRON_EXECUTION:
      return { ...state, executionLoading: true };

    case HANDLE_CRON_EXECUTION_SUCCESS:
      toast("", "Cron Job Executed !", "success");
      return { ...state, executionLoading: false, message: "Cron Job Executed !" };

    case HANDLE_CRON_EXECUTION_FAILED:
      toast("", "Failed to Execute Cron !", "error");
      return { ...state, executionLoading: false, message: "Cron Job Execution failed !" };

    case SEARCH_CRON:
      return { ...state, searchLoading: true };

    case SEARCH_CRON_SUCCESS:
      return { ...state, listData: action.data, searchLoading: false };

    case SEARCH_CRON_FAILED:
      return { ...state, searchLoading: false };

    case CREATE_CRON_CONFIG:
      return { ...state, createLoading: true };

    case CREATE_CRON_CONFIG_SUCCESS:
      console.log(action.data?.data);
      if (action.data?.status === 200) {
        toast("", action?.data?.msg, "success");
      } else {
        toast("", "Failed to save data !", "error");
      }
      return { ...state, createLoading: false };

    case CREATE_CRON_CONFIG_FAILED:
      toast("", "Failed to save data !", "error");
      return { ...state, createLoading: false };

    default:
      return { ...state };
  }
};

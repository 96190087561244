import { toast } from "helpers/Utils";
import { ADD_COMPANY_DETAILS, ADD_COMPANY_DETAILS_FAILURE, ADD_COMPANY_DETAILS_SUCCESS, ADD_NEW_TPA_COMPANY, ADD_NEW_TPA_COMPANY_FAILED, ADD_NEW_TPA_COMPANY_SUCCESS, GET_COMPANY_LIST, GET_COMPANY_LIST_FAILURE, GET_COMPANY_LIST_SUCCESS, GET_SINGLE_COMPANY, GET_SINGLE_COMPANY_FAILURE, GET_SINGLE_COMPANY_SUCCESS, GET_SINGLE_TPA_COMPANY, GET_SINGLE_TPA_COMPANY_FAILED, GET_SINGLE_TPA_COMPANY_SUCCESS, GET_TPA_COMPANY_LIST, GET_TPA_COMPANY_LIST_FAILED, GET_TPA_COMPANY_LIST_SUCCESS, UPDATE_COMPANY_DETAILS, UPDATE_COMPANY_DETAILS_FAILURE, UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_TPA_COMPANY, UPDATE_TPA_COMPANY_FAILED, UPDATE_TPA_COMPANY_SUCCESS } from "./Action";

let initialState = {
  companyList: [],
  singleCompany: {},
  singleCompanyId: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_LIST:
      return { ...state, loading: true };

    case GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.data,
        loading: false
      };
    case GET_COMPANY_LIST_FAILURE:
      toast("Failed fetch list !", "", "error");
      return {
        ...state,
        companyList: [],
        loading: false
      };

    case GET_SINGLE_COMPANY:
      return { ...state, loading: true };

    case GET_SINGLE_COMPANY_SUCCESS:
      toast("Company list fetched successfully !", "", "success");
      return { ...state, singleCompany: action.data, loading: false };

    case GET_SINGLE_COMPANY_FAILURE:
      toast("Failed to get company data !", "", "error");
      return { ...state, loading: false, singleCompany: {} };

    case UPDATE_COMPANY_DETAILS:
      return { ...state, loading: true };

    case UPDATE_COMPANY_DETAILS_SUCCESS:
      toast("Company data updated successfully !", "", "success");
      return {
        ...state,
        loading: false
      };

    case UPDATE_COMPANY_DETAILS_FAILURE:
      toast("Failed to update data !", "", "error");
      return { ...state, loading: false };

    case ADD_COMPANY_DETAILS:
      return { ...state, loading: true };

    case ADD_COMPANY_DETAILS_SUCCESS:
      toast("Company data added successfully !", "", "success");
      return {
        ...state,
        loading: false
      };

    case ADD_COMPANY_DETAILS_FAILURE:
      toast("Failed to add data !", "", "error");
      return { ...state, loading: false };

    case GET_TPA_COMPANY_LIST:
      return { ...state, loading: true };

    case GET_TPA_COMPANY_LIST_SUCCESS:
      return { ...state, companyList: action.data, loading: false };

    case GET_TPA_COMPANY_LIST_FAILED:
      return { ...state, loading: false, companyList: [] };

    case GET_SINGLE_TPA_COMPANY:
      return { ...state, loading: true };

    case GET_SINGLE_TPA_COMPANY_SUCCESS:
      return { ...state, TPA_Single: action.data, loading: false };

    case GET_SINGLE_TPA_COMPANY_FAILED:
      return { ...state, loading: false };

    case ADD_NEW_TPA_COMPANY:
      return { ...state, loading: true };

    case ADD_NEW_TPA_COMPANY_SUCCESS:
      return { ...state, addedTpaCompany: action.data, loading: false };

    case ADD_NEW_TPA_COMPANY_FAILED:
      return { ...state, loading: false };

    case UPDATE_TPA_COMPANY:
      return { ...state, loading: true };

    case UPDATE_TPA_COMPANY_SUCCESS:
      return { ...state, updatedTpaCompany: action.data, loading: false };

    case UPDATE_TPA_COMPANY_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
}

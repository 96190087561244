import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

function configureStore(initialState) {
  const store = createStore(reducers, initialState, compose(applyMiddleware(...middlewares)));

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(reducers);
    });
  }

  return store;
}

const store = configureStore();
export default store;

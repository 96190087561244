import { all, call, put, takeLatest } from "redux-saga/effects";
import { request } from "services/requests.services";
import { CRON_JOB_GET_DATA_LIST_START_FAILED, CRON_JOB_GET_DATA_LIST_START, CRON_JOB_GET_DATA_LIST_START_SUCCESS, CRON_JOB_UPDATE_ACTION_START, CRON_JOB_UPDATE_ACTION_START_FAILED, CRON_JOB_UPDATE_ACTION_START_SUCCESS, HANDLE_CRON_EXECUTION, HANDLE_CRON_EXECUTION_SUCCESS, HANDLE_CRON_EXECUTION_FAILED, SEARCH_CRON, SEARCH_CRON_SUCCESS, SEARCH_CRON_FAILED, CREATE_CRON_CONFIG, CREATE_CRON_CONFIG_SUCCESS, CREATE_CRON_CONFIG_FAILED } from "./Actions";
import { cronsPath } from "constants/defaultValues";

function* getListData(action) {
  try {
    const data = yield request("GET", `${cronsPath}/cronConfig/findAll?page=${action.page}&pageSize=${action.size}`);
    yield put({
      type: CRON_JOB_GET_DATA_LIST_START_SUCCESS,
      data: data
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: CRON_JOB_GET_DATA_LIST_START_FAILED,
      message: "Failed to get data !"
    });
  }
}

function* updateCron(action) {
  try {
    yield request("POST", `${cronsPath}/cronConfig/update`, action.state);
    yield put({ type: CRON_JOB_UPDATE_ACTION_START_SUCCESS, message: "Status Changed of cron !" });
    const { page, size } = action || {};
    yield put({ type: CRON_JOB_GET_DATA_LIST_START, page, size });
  } catch (error) {
    yield put({ type: CRON_JOB_UPDATE_ACTION_START_FAILED, message: "Failed to update cron !" });
  }
}

function* execteCronAsync(action) {
  try {
    const { fileName } = action.state;
    yield request("POST", `${cronsPath}/cronConfig/executeCron`, { fileName });
    yield put({ type: HANDLE_CRON_EXECUTION_SUCCESS, message: "Status Changed of cron !" });
  } catch (error) {
    yield put({ type: HANDLE_CRON_EXECUTION_FAILED, message: "Failed to update cron !" });
  }
}

function* searchCronAsync(action) {
  try {
    const data = yield request("GET", `${cronsPath}/cronConfig/search?search=${action.state?.search}`);
    yield put({ type: SEARCH_CRON_SUCCESS, data: data?.data });
  } catch (error) {
    yield put({ type: SEARCH_CRON_FAILED, message: "Failed to update cron !" });
  }
}

function* createConfigAsync(action) {
  try {
    const data = yield request("POST", `${cronsPath}/CronConfig/create`, action.state);
    yield put({ type: CREATE_CRON_CONFIG_SUCCESS, data: data });
  } catch (error) {
    console.log(error);
    yield put({ type: CREATE_CRON_CONFIG_FAILED, message: "Failed to create Data !" });
  }
}

function* getData() {
  return yield takeLatest(CRON_JOB_GET_DATA_LIST_START, getListData);
}

function* toggleButton() {
  return yield takeLatest(CRON_JOB_UPDATE_ACTION_START, updateCron);
}

function* executeCron() {
  return yield takeLatest(HANDLE_CRON_EXECUTION, execteCronAsync);
}

function* searchCron() {
  return yield takeLatest(SEARCH_CRON, searchCronAsync);
}

function* createConfig() {
  return yield takeLatest(CREATE_CRON_CONFIG, createConfigAsync);
}

export default function* CronConfig() {
  yield all([call(getData), call(toggleButton), call(executeCron), call(searchCron), call(createConfig)]);
}

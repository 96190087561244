import { toast } from "helpers/Utils";
import { CLOSE_COMPLAINT_QURIES, CLOSE_COMPLAINT_QURIES_FAILED, CLOSE_COMPLAINT_QURIES_SUCCESS, GET_COMPLAINT_QURIES, GET_COMPLAINT_QURIES_FAILED, GET_COMPLAINT_QURIES_SUCCESS, GET_CUSTOMER_QUERT_DATA, GET_CUSTOMER_QUERT_DATA_FAILED, GET_CUSTOMER_QUERT_DATA_SUCCESS, GET_OPEN_QUERT_DATA, GET_OPEN_QUERT_DATA_FAILED, GET_OPEN_QUERT_DATA_SUCCESS, UPDATE_COMPLAINT_QURIES, UPDATE_COMPLAINT_QURIES_FAILED, UPDATE_COMPLAINT_QURIES_SUCCESS } from "./Action";

const initialState = {
  dataList: [],
  count: [],
  loading: false,
  comQuery: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_QUERT_DATA:
      return { ...state, loading: true };

    case GET_CUSTOMER_QUERT_DATA_SUCCESS:
      if (action?.data?.pageOfItems?.length) {
        toast("Data Fetched !", "", "success");
      }
      if (!action.data?.pageOfItems?.length) {
        toast("No Record Found !", "", "error");
      }
      return { ...state, dataList: action.data, loading: false };

    case GET_CUSTOMER_QUERT_DATA_FAILED:
      return { ...state, loading: false };

    case GET_OPEN_QUERT_DATA:
      return { ...state, loading: true };

    case GET_OPEN_QUERT_DATA_SUCCESS:
      return { ...state, count: action.data, loading: false };

    case GET_OPEN_QUERT_DATA_FAILED:
      return { ...state, loading: false };

    case GET_COMPLAINT_QURIES:
      return { ...state };

    case GET_COMPLAINT_QURIES_SUCCESS:
      toast("Form get Successfully !", "", "success");
      return { ...state, comQuery: action.data };

    case GET_COMPLAINT_QURIES_FAILED:
      return { ...state, loading: false };

    case UPDATE_COMPLAINT_QURIES:
      return { ...state };

    case UPDATE_COMPLAINT_QURIES_SUCCESS:
      toast("Communication added successfully !", "", "success");
      return { ...state, loading: false };

    case UPDATE_COMPLAINT_QURIES_FAILED:
      return { ...state, loading: false };

    case CLOSE_COMPLAINT_QURIES:
      return { ...state };

    case CLOSE_COMPLAINT_QURIES_SUCCESS:
      toast("Query Closed !", "", "success");
      return { ...state, loading: false };

    case CLOSE_COMPLAINT_QURIES_FAILED:
      toast(action.message, "", "error");
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};

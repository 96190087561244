import { toast } from "helpers/Utils";
import { ADD_COMPLAINT_TYPE, ADD_COMPLAINT_TYPE_FAILURE, ADD_COMPLAINT_TYPE_SUCCESS, GET_COMPLAINT_TYPE_LIST, GET_COMPLAINT_TYPE_LIST_FAILURE, GET_COMPLAINT_TYPE_LIST_SUCCESS, UPDATE_COMPLAINT_TYPE, UPDATE_COMPLAINT_TYPE_FAILURE, UPDATE_COMPLAINT_TYPE_SUCCESS } from "./Action";

let initialState = {
  complaintTypeList: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPLAINT_TYPE_LIST:
      return { ...state, loading: true };

    case GET_COMPLAINT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        complaintTypeList: action.data,
        loading: false
      };
    case GET_COMPLAINT_TYPE_LIST_FAILURE:
      toast("Failed fetch list !", "", "error");
      return {
        ...state,
        companyList: [],
        loading: false
      };

    case UPDATE_COMPLAINT_TYPE:
      return { ...state, loading: true };

    case UPDATE_COMPLAINT_TYPE_SUCCESS:
      toast("Complaint type updated successfully !", "", "success");
      return {
        ...state,
        loading: false
      };

    case UPDATE_COMPLAINT_TYPE_FAILURE:
      toast("Failed to update !", "", "error");
      return { ...state, loading: false };

    case ADD_COMPLAINT_TYPE:
      return { ...state, loading: true };

    case ADD_COMPLAINT_TYPE_SUCCESS:
      toast("Complaint Type added successfully !", "", "success");
      return {
        ...state,
        loading: false
      };

    case ADD_COMPLAINT_TYPE_FAILURE:
      toast("Failed to add !", "", "error");
      return { ...state, loading: false };

    default:
      return state;
  }
}
